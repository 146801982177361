import { lazy } from 'react'
import { CustomRouteObject } from './types'

// ----------- Pages -----------
const RequestTopUpsList = lazy(
  () => import(/* webpackChunkName: 'request-top-up-List' */ '~/pages/RequestTopUpsList')
)
const RequestTopUpDetails = lazy(
  () => import(/* webpackChunkName: 'request-top-up-details' */ '~/pages/RequestTopUpDetails')
)

// ----------- Routes -----------
export const REQUEST_TOP_UPS_LIST_ROUTE = '/approvals'
export const REQUEST_TOP_UP_DETAILS_ROUTE = '/approval-details/:id'
export const REQUEST_TOP_UPS_TAB_ROUTE = '/approvals/:tab'

export const requestTopUpRoutes: CustomRouteObject[] = [
  {
    path: [REQUEST_TOP_UPS_LIST_ROUTE, REQUEST_TOP_UPS_TAB_ROUTE],
    private: true,
    element: <RequestTopUpsList />,
    guards: [['read', 'Approvals']],
  },
  {
    path: [REQUEST_TOP_UP_DETAILS_ROUTE],
    private: true,
    element: <RequestTopUpDetails />,
    guards: [['read', 'Approval Details']],
  },
]
