import { makeVar } from '@apollo/client'

import { getItemFromLS, getItemFromSS, setItemFromLS, setItemFromSS } from '../cacheUtils'

export const AUTH_TOKEN_LS_KEY = 'authToken'
export const AUTH_REFRESH_TOKEN_LS_KEY = 'authRefreshToken'

/** ----------------- VAR ----------------- */
export const authTokenVar = getItemFromSS(AUTH_TOKEN_LS_KEY)
export const authRefreshTokenVar = getItemFromLS(AUTH_REFRESH_TOKEN_LS_KEY)

export const updateAuthTokenVar = (token?: string, refreshToken?: string) => {
  setItemFromSS(AUTH_TOKEN_LS_KEY, token)
  // authTokenVar(token)

  setItemFromLS(AUTH_REFRESH_TOKEN_LS_KEY, refreshToken)
  // authRefreshTokenVar(refreshToken)
}
