import { Role, useUserIdentifierQuery } from '~/generated/graphql'
import { useIsAuthenticated } from './auth/useIsAuthenticated'

type UseCurrentUser = () => {
  currentUser?: any
  role?: Role
  isPremium: boolean
}

export const useCurrentUser: UseCurrentUser = () => {
  const { isAuthenticated } = useIsAuthenticated()

  const { data, refetch } = useUserIdentifierQuery({
    canonizeResults: true,
    // fetchPolicy: 'cache-first',
    skip: !isAuthenticated,
  })

  return {
    currentUser: data?.me,
    role: data?.me?.role,
    isPremium: false,
  }
}
