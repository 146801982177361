import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: any;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: any;
};

export type AcceptInviteInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type AssginUserCustomerInput = {
  id: Scalars['ObjectID'];
  sale: Scalars['ObjectID'];
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
  user: User;
};

export type CreateCustomerWalletInput = {
  customerId: Scalars['ObjectID'];
  grantedCredits: Scalars['BigInt'];
  name: Scalars['String'];
  paidCredits: Scalars['BigInt'];
};

export type CreateInviteInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role?: InputMaybe<Role>;
};

export type CreateInvoiceInput = {
  customerId: Scalars['ID'];
  issuingDate?: InputMaybe<Scalars['DateTime']>;
  subscriptions?: InputMaybe<Array<Scalars['ID']>>;
  totalAmountCents: Scalars['Float'];
  transactionId: Scalars['ID'];
};

export type CreatePlanInput = {
  addons?: InputMaybe<Array<Scalars['ID']>>;
  amountCents: Scalars['Float'];
  category: ProductCategory;
  code: Scalars['String'];
  condition?: InputMaybe<PlanConditionInput>;
  defaultSystem: Scalars['Boolean'];
  description: Scalars['String'];
  interval: PlanInterval;
  name: Scalars['String'];
  payInAdvance: Scalars['Boolean'];
  trialPeriod: Scalars['Float'];
};

export type CreateSubscriptionInput = {
  customer: Scalars['ObjectID'];
  endAt: Scalars['DateTime'];
  isTrial: Scalars['Boolean'];
  plan: Scalars['ObjectID'];
  startAt: Scalars['DateTime'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  isVerified: Scalars['Boolean'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<Role>;
};

export type CustomCustomerConnection = {
  __typename?: 'CustomCustomerConnection';
  metadata: PageInfo;
  results?: Maybe<Array<CustomCustomerResult>>;
};

export type CustomCustomerResult = {
  __typename?: 'CustomCustomerResult';
  _id?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  subscriptions?: Maybe<Array<Subscription>>;
};

export type Customer = {
  __typename?: 'Customer';
  _id?: Maybe<Scalars['ID']>;
  activeSubscriptionCount?: Maybe<Scalars['Float']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryCode']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccountTemp: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  maxAccount?: Maybe<Scalars['Float']>;
  numberProject?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sale?: Maybe<User>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  metadata: PageInfo;
  results?: Maybe<Array<Customer>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CustomerUnionConnection = CustomCustomerConnection | CustomerConnection;

export type Feature = {
  __typename?: 'Feature';
  _id?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  key: FeatureKey;
  label: Scalars['String'];
  operators: Array<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum FeatureKey {
  Analytics = 'ANALYTICS',
  Botchat = 'BOTCHAT',
  Department = 'DEPARTMENT',
  FunctionDefault = 'FUNCTION_DEFAULT',
  KnowledgeBase = 'KNOWLEDGE_BASE',
  LogoConfiguration = 'LOGO_CONFIGURATION',
  Member = 'MEMBER',
  OperatingHours = 'OPERATING_HOURS',
  Project = 'PROJECT',
  Quickchat = 'QUICKCHAT',
  Storage = 'STORAGE',
  SupportDefault = 'SUPPORT_DEFAULT',
  Ticket = 'TICKET',
  TransferData = 'TRANSFER_DATA'
}

export type Fee = {
  __typename?: 'Fee';
  _id?: Maybe<Scalars['ID']>;
  amountCents: Scalars['String'];
  chargeFrom: Scalars['DateTime'];
  chargeTo: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invoice: Invoice;
  name?: Maybe<Scalars['String']>;
  plan: Plan;
  subscription: Subscription;
  totalAmountCents: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum FilterCustomer {
  FreePlan = 'FreePlan',
  NewCustomer = 'NewCustomer'
}

export enum FilterInvoice {
  AlmostExpired = 'AlmostExpired',
  Expired = 'Expired'
}

export type Invoice = {
  __typename?: 'Invoice';
  _id?: Maybe<Scalars['ID']>;
  autoRenew?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  endAt?: Maybe<Scalars['DateTime']>;
  fees?: Maybe<Array<Fee>>;
  id: Scalars['ID'];
  invoiceType: InvoiceType;
  issuingDate: Scalars['DateTime'];
  note?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  paymentAt?: Maybe<Scalars['DateTime']>;
  paymentMethod?: Maybe<InvoicePaymentMethod>;
  paymentStatus: InvoicePaymentStatus;
  status: InvoiceStatus;
  subscription?: Maybe<Subscription>;
  totalAmountCents: Scalars['String'];
  transaction?: Maybe<WalletTransaction>;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  metadata: PageInfo;
  results?: Maybe<Array<Invoice>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum InvoicePaymentMethod {
  Onetime = 'ONETIME',
  Postpaid = 'POSTPAID',
  Prepaid = 'PREPAID'
}

export enum InvoicePaymentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum InvoiceStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Finalized = 'FINALIZED',
  Pending = 'PENDING'
}

export enum InvoiceType {
  Credit = 'CREDIT',
  Subscription = 'SUBSCRIPTION'
}

export enum LagoApiError {
  TokenExpired = 'Token_Expired',
  Unauthorized = 'Unauthorized',
  CouponIsNotReusable = 'coupon_is_not_reusable',
  CurrenciesDoesNotMatch = 'currencies_does_not_match',
  DoNotDeletePlan = 'do_not_delete_plan',
  DoesNotMatchItemAmounts = 'does_not_match_item_amounts',
  EmailAlreadyUsed = 'email_already_used',
  ExistingCode = 'existing_code',
  ExpiredJwtToken = 'expired_jwt_token',
  Forbidden = 'forbidden',
  IncorrectLoginOrPassword = 'incorrect_login_or_password',
  InternalError = 'internal_error',
  InviteAlreadyExists = 'invite_already_exists',
  InviteNotFound = 'invite_not_found',
  InvoiceNotFound = 'invoice_not_found',
  InvoiceStatusUpdateFailed = 'invoice_status_update_failed',
  NotFound = 'not_found',
  PaymentProcessorIsCurrentlyHandlingPayment = 'payment_processor_is_currently_handling_payment',
  PlanNotFound = 'plan_not_found',
  PlanOverlapping = 'plan_overlapping',
  TokenEncodingError = 'token_encoding_error',
  UnprocessableEntity = 'unprocessable_entity',
  UrlIsInvalid = 'url_is_invalid',
  UserAlreadyExists = 'user_already_exists',
  ValueAlreadyExist = 'value_already_exist',
  ValueIsInvalid = 'value_is_invalid',
  ValueIsOutOfRange = 'value_is_out_of_range'
}

export type LoginUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: Auth;
  assignUserToCustomer: Customer;
  createCustomerWallet: Wallet;
  createInvite: User;
  createInvoice: Invoice;
  createPlan: Plan;
  createSubscription: Subscription;
  deletePlan: Plan;
  deleteUser: Scalars['Boolean'];
  finalizeInvoice: Invoice;
  loginUser: Auth;
  pickCustomer: Scalars['Boolean'];
  refreshDraftInvoice: Invoice;
  refreshToken: Auth;
  register: User;
  registerUser: Auth;
  resendInvite: Scalars['Boolean'];
  resetPassword: Auth;
  terminateCustomerWallet: Wallet;
  terminateSubscription: Subscription;
  topupWalletTransaction: Array<WalletTransaction>;
  updateCustomer: Customer;
  updateCustomerWallet: Scalars['Boolean'];
  updateInvoicePaymentStatus: Invoice;
  updateInvoiceStatus: Invoice;
  updatePlan: Plan;
  updateProfile: User;
  updateSubscription: Subscription;
  updateTopUp: Scalars['String'];
  updateUser: Scalars['Boolean'];
};


export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


export type MutationAssignUserToCustomerArgs = {
  input: AssginUserCustomerInput;
};


export type MutationCreateCustomerWalletArgs = {
  input: CreateCustomerWalletInput;
};


export type MutationCreateInviteArgs = {
  input: CreateInviteInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationDeletePlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationFinalizeInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationPickCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationRefreshDraftInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['JWT'];
};


export type MutationRegisterArgs = {
  input: CreateUserInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationResendInviteArgs = {
  userId: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationTerminateCustomerWalletArgs = {
  id: Scalars['ID'];
};


export type MutationTerminateSubscriptionArgs = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
};


export type MutationTopupWalletTransactionArgs = {
  input: TopUpWalletInput;
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['ID'];
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerWalletArgs = {
  id: Scalars['String'];
  input: UpdateCustomerWalletInput;
};


export type MutationUpdateInvoicePaymentStatusArgs = {
  input: UpdatePaymentInput;
};


export type MutationUpdateInvoiceStatusArgs = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  status: InvoiceStatus;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['ID'];
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTopUpArgs = {
  id: Scalars['ID'];
  input: UpdateTopUpInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input: CreateUserInput;
};

export type Order = {
  direction: OrderDirection;
  field: Scalars['String'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Plan = {
  __typename?: 'Plan';
  _id?: Maybe<Scalars['ID']>;
  activeSubscriptionsCount: Scalars['Int'];
  addons: Array<Plan>;
  amountCents: Scalars['Float'];
  category: ProductCategory;
  code: Scalars['String'];
  condition?: Maybe<PlanCondition>;
  createdAt: Scalars['DateTime'];
  defaultSystem?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  interval: PlanInterval;
  isEdit?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  payInAdvance: Scalars['Boolean'];
  trialPeriod: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type PlanCondition = {
  __typename?: 'PlanCondition';
  key: FeatureKey;
  maxAccount?: Maybe<Scalars['Float']>;
  maxUploadFile?: Maybe<Scalars['Float']>;
  maxUploadFileSystem?: Maybe<Scalars['Float']>;
  maxUploadKnowledgeBase?: Maybe<Scalars['Float']>;
  numberProject?: Maybe<Scalars['Float']>;
  operator: Scalars['String'];
  value: Scalars['String'];
};

export type PlanConditionInput = {
  key: FeatureKey;
  maxAccount?: InputMaybe<Scalars['Float']>;
  maxUploadFile?: InputMaybe<Scalars['Float']>;
  maxUploadFileSystem?: InputMaybe<Scalars['Float']>;
  maxUploadKnowledgeBase?: InputMaybe<Scalars['Float']>;
  numberProject?: InputMaybe<Scalars['Float']>;
  operator: Scalars['String'];
  value: Scalars['String'];
};

export type PlanConnection = {
  __typename?: 'PlanConnection';
  metadata: PageInfo;
  results?: Maybe<Array<Plan>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum PlanInterval {
  Hourly = 'HOURLY',
  Immediate = 'IMMEDIATE',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum ProductCategory {
  Addons = 'ADDONS',
  Base = 'BASE'
}

export type Query = {
  __typename?: 'Query';
  customer: Customer;
  customerStatistics: CustomerUnionConnection;
  customerSupports: CustomerConnection;
  customers: CustomerConnection;
  features: Array<Feature>;
  getInvoice: Invoice;
  getListInvoiceByType: InvoiceConnection;
  getRequestTopUp: WalletTransaction;
  invite: User;
  invoice: Invoice;
  invoices: InvoiceConnection;
  isEditPlan: Scalars['Boolean'];
  me: User;
  plan: Plan;
  planCode: Plan;
  plans: PlanConnection;
  requestTopUps: WalletTransactionConnection;
  sellerStatistics: SellerStatisticsConnection;
  sellerStatisticsByUser: SellerStatisticsByUserConnection;
  subscriptionStatistics: SubscriptionStatisticsConnection;
  subscriptions: SubscriptionConnection;
  user: User;
  users: UserConnection;
  wallet: Wallet;
  walletTransactions: WalletTransactionConnection;
  wallets: WalletConnection;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerStatisticsArgs = {
  currentTime?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type: FilterCustomer;
};


export type QueryCustomerSupportsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFeaturesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGetInvoiceArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetListInvoiceByTypeArgs = {
  currentTime?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type: FilterInvoice;
};


export type QueryGetRequestTopUpArgs = {
  id: Scalars['String'];
};


export type QueryInviteArgs = {
  token: Scalars['String'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoicesArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  invoiceType?: InputMaybe<InvoiceType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatus>>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<InvoiceStatus>;
};


export type QueryIsEditPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPlanCodeArgs = {
  code: Scalars['String'];
};


export type QueryPlansArgs = {
  category: ProductCategory;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryRequestTopUpsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<WalletTransactionStatusEnum>;
};


export type QuerySellerStatisticsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QuerySellerStatisticsByUserArgs = {
  currentTime?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QuerySubscriptionStatisticsArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  type: ProductCategory;
};


export type QuerySubscriptionsArgs = {
  category: ProductCategory;
  customerId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryWalletArgs = {
  id: Scalars['String'];
};


export type QueryWalletTransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  walletId: Scalars['ID'];
};


export type QueryWalletsArgs = {
  customerId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export enum Role {
  Admin = 'Admin',
  Customer = 'Customer',
  Mod = 'Mod',
  User = 'User'
}

export type SellerStatisticsByUserConnection = {
  __typename?: 'SellerStatisticsByUserConnection';
  amounts?: Maybe<Array<Scalars['Float']>>;
  subscriptions?: Maybe<Array<Scalars['Float']>>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalCustomer?: Maybe<Scalars['Float']>;
  totalSubscription?: Maybe<Scalars['Float']>;
};

export type SellerStatisticsConnection = {
  __typename?: 'SellerStatisticsConnection';
  metadata: PageInfo;
  results?: Maybe<Array<SellerStatisticsResult>>;
};

export type SellerStatisticsResult = {
  __typename?: 'SellerStatisticsResult';
  _id?: Maybe<Scalars['ID']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalSubscription?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _id?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isTrial: Scalars['Boolean'];
  nextPlan?: Maybe<Plan>;
  plan: Plan;
  seller?: Maybe<User>;
  startAt: Scalars['DateTime'];
  status: SubscriptionStatusEnum;
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  metadata: PageInfo;
  results?: Maybe<Array<Subscription>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriptionStatisticsConnection = {
  __typename?: 'SubscriptionStatisticsConnection';
  subscriptions?: Maybe<Array<SubscriptionsResult>>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalSubscription?: Maybe<Scalars['Float']>;
};

export enum SubscriptionStatusEnum {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Terminated = 'TERMINATED'
}

export type SubscriptionsResult = {
  __typename?: 'SubscriptionsResult';
  _id?: Maybe<Scalars['ID']>;
  plan?: Maybe<Plan>;
  totalSubscription?: Maybe<Scalars['Float']>;
};

export type TopUpWalletInput = {
  grantedCredits: Scalars['BigInt'];
  paidCredits: Scalars['BigInt'];
  walletId: Scalars['ObjectID'];
};

export type UpdateCustomerInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['CountryCode']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  numberProject?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  sale: Scalars['ObjectID'];
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerWalletInput = {
  balance: Scalars['BigInt'];
  consumedCredits: Scalars['BigInt'];
  creditsBalance: Scalars['BigInt'];
  lastBalanceSyncAt: Scalars['DateTime'];
  lastConsumedCreditAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type UpdatePaymentInput = {
  id: Scalars['ID'];
  paymentStatus: InvoicePaymentStatus;
};

export type UpdatePlanInput = {
  addons?: InputMaybe<Array<Scalars['ID']>>;
  amountCents: Scalars['Float'];
  code: Scalars['String'];
  condition?: InputMaybe<PlanConditionInput>;
  defaultSystem: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['ObjectID'];
  interval: PlanInterval;
  name: Scalars['String'];
  payInAdvance: Scalars['Boolean'];
  trialPeriod: Scalars['Float'];
};

export type UpdateSubscriptionInput = {
  endAt: Scalars['DateTime'];
  plan: Scalars['ObjectID'];
  startAt: Scalars['DateTime'];
};

export type UpdateTopUpInput = {
  note?: InputMaybe<Scalars['String']>;
  status: WalletTransactionStatusEnum;
};

export type UpdateUserInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  facebook: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  skype: Scalars['String'];
  telegram: Scalars['String'];
  viber: Scalars['String'];
  zalo: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  emailVerifyToken?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  facebook?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Role;
  skype?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  viber?: Maybe<Scalars['String']>;
  zalo?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  metadata: PageInfo;
  results?: Maybe<Array<User>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  _id?: Maybe<Scalars['ID']>;
  balance: Scalars['String'];
  consumedCredits: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creditsBalance: Scalars['String'];
  customer: Customer;
  id: Scalars['ID'];
  lastBalanceSyncAt: Scalars['DateTime'];
  lastConsumedCreditAt: Scalars['DateTime'];
  name: Scalars['String'];
  status: WalletStatusEnum;
  terminatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type WalletConnection = {
  __typename?: 'WalletConnection';
  metadata: PageInfo;
  results?: Maybe<Array<Wallet>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum WalletStatusEnum {
  Active = 'Active',
  Terminated = 'Terminated'
}

export type WalletTransaction = {
  __typename?: 'WalletTransaction';
  _id?: Maybe<Scalars['ID']>;
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  seller?: Maybe<User>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status: WalletTransactionStatusEnum;
  transactionType: WalletTransactionTypeEnum;
  updatedAt: Scalars['DateTime'];
  verifier?: Maybe<User>;
  wallet: Wallet;
};

export type WalletTransactionConnection = {
  __typename?: 'WalletTransactionConnection';
  metadata: PageInfo;
  results?: Maybe<Array<WalletTransaction>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum WalletTransactionStatusEnum {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Settled = 'Settled'
}

export enum WalletTransactionTypeEnum {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  FreeCredit = 'freeCredit'
}

export type UserIdentifierQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIdentifierQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, phone?: string | null, facebook?: string | null, zalo?: string | null, telegram?: string | null, skype?: string | null, viber?: string | null, role: Role, isVerified: boolean } };

export type FeatureDetailFragment = { __typename?: 'Feature', id: string, label: string, key: FeatureKey, type: string, operators: Array<string> };

export type FeaturesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type FeaturesQuery = { __typename?: 'Query', features: Array<{ __typename?: 'Feature', id: string, label: string, key: FeatureKey, type: string, operators: Array<string> }> };

export type DeleteAddOnMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAddOnMutation = { __typename?: 'Mutation', deletePlan: { __typename?: 'Plan', id: string } };

export type InvoiceForInvoiceListFragment = { __typename?: 'InvoiceConnection', results?: Array<{ __typename?: 'Invoice', id: string, status: InvoiceStatus, paymentStatus: InvoicePaymentStatus, number: string, issuingDate: any, totalAmountCents: string, invoiceType: InvoiceType, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } }> | null, metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number } };

export type GetCustomerInvoicesQueryVariables = Exact<{
  customerId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InvoiceStatus>;
  invoiceType?: InputMaybe<InvoiceType>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type GetCustomerInvoicesQuery = { __typename?: 'Query', invoices: { __typename?: 'InvoiceConnection', results?: Array<{ __typename?: 'Invoice', id: string, status: InvoiceStatus, paymentStatus: InvoicePaymentStatus, number: string, issuingDate: any, totalAmountCents: string, invoiceType: InvoiceType, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } }> | null, metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number } } };

export type CustomerItemFragment = { __typename?: 'Customer', id: string, email: string, createdAt: any, updatedAt: any, firstName?: string | null, lastName?: string | null, activeSubscriptionCount?: number | null, phone?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null, sale?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null };

export type TerminateCustomerSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
}>;


export type TerminateCustomerSubscriptionMutation = { __typename?: 'Mutation', terminateSubscription: { __typename?: 'Subscription', id: string } };

export type GetInvoiceQueryVariables = Exact<{
  invoiceId?: InputMaybe<Scalars['ID']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
}>;


export type GetInvoiceQuery = { __typename?: 'Query', getInvoice: { __typename?: 'Invoice', id: string, number: string, status: InvoiceStatus, fees?: Array<{ __typename?: 'Fee', plan: { __typename?: 'Plan', name: string } }> | null } };

export type SellerStatisticsQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type SellerStatisticsQuery = { __typename?: 'Query', sellerStatistics: { __typename?: 'SellerStatisticsConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number }, results?: Array<{ __typename?: 'SellerStatisticsResult', _id?: string | null, totalSubscription?: number | null, totalAmount?: number | null, user?: { __typename?: 'User', _id?: string | null, email: string, firstName: string, lastName: string, enabled?: boolean | null, createdAt: any } | null }> | null } };

export type InvoiceForUpdateInvoicePaymentStatusFragment = { __typename?: 'Invoice', id: string, paymentStatus: InvoicePaymentStatus, status: InvoiceStatus, invoiceType: InvoiceType, paymentAt?: any | null };

export type UpdateInvoicePaymentStatusMutationVariables = Exact<{
  input: UpdatePaymentInput;
}>;


export type UpdateInvoicePaymentStatusMutation = { __typename?: 'Mutation', updateInvoicePaymentStatus: { __typename?: 'Invoice', id: string, paymentStatus: InvoicePaymentStatus, status: InvoiceStatus, invoiceType: InvoiceType, paymentAt?: any | null } };

export type UpdateInvoiceStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: InvoiceStatus;
  note?: InputMaybe<Scalars['String']>;
}>;


export type UpdateInvoiceStatusMutation = { __typename?: 'Mutation', updateInvoiceStatus: { __typename?: 'Invoice', id: string, status: InvoiceStatus, note?: string | null } };

export type FinalizeInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FinalizeInvoiceMutation = { __typename?: 'Mutation', finalizeInvoice: { __typename?: 'Invoice', id: string } };

export type InvoiceForInvoiceInfosFragment = { __typename?: 'Invoice', number: string, issuingDate: any, paymentStatus: InvoicePaymentStatus, invoiceType: InvoiceType, paymentMethod?: InvoicePaymentMethod | null, note?: string | null, paymentAt?: any | null, endAt?: any | null, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null }, subscription?: { __typename?: 'Subscription', seller?: { __typename?: 'User', firstName: string, lastName: string } | null } | null };

export type InvoiceForDetailsTableFragment = { __typename?: 'Invoice', invoiceType: InvoiceType, totalAmountCents: string, fees?: Array<{ __typename?: 'Fee', id: string, amountCents: string, plan: { __typename?: 'Plan', id: string, name: string } }> | null };

export type InvoiceForDetailsTableFooterFragment = { __typename?: 'Invoice', totalAmountCents: string };

export type InvoiceListItemFragment = { __typename?: 'Invoice', id: string, status: InvoiceStatus, paymentStatus: InvoicePaymentStatus, number: string, issuingDate: any, totalAmountCents: string, invoiceType: InvoiceType, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } };

export type DeletePlanDialogFragment = { __typename?: 'Plan', id: string, name: string, activeSubscriptionsCount: number };

export type DeletePlanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlanMutation = { __typename?: 'Mutation', deletePlan: { __typename?: 'Plan', id: string } };

export type PlanItemFragment = { __typename?: 'Plan', id: string, name: string, code: string, amountCents: number, interval: PlanInterval, activeSubscriptionsCount: number, createdAt: any, defaultSystem?: boolean | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string } } };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, phone?: string | null, facebook?: string | null, zalo?: string | null, telegram?: string | null, skype?: string | null, viber?: string | null, role: Role, isVerified: boolean } };

export type RequestTopUpListItemFragment = { __typename?: 'WalletTransaction', _id?: string | null, amount: string, status: WalletTransactionStatusEnum, transactionType: WalletTransactionTypeEnum, createdAt: any, note?: string | null, wallet: { __typename?: 'Wallet', balance: string, customer: { __typename?: 'Customer', firstName?: string | null, lastName?: string | null, email: string } } };

export type CustomerSupportListItemFragment = { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, activeSubscriptionCount?: number | null, createdAt: any };

export type CustomerSupportsQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type CustomerSupportsQuery = { __typename?: 'Query', customerSupports: { __typename?: 'CustomerConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number }, results?: Array<{ __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, activeSubscriptionCount?: number | null, createdAt: any }> | null } };

export type SellerStatisticsByUserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  currentTime: Scalars['String'];
}>;


export type SellerStatisticsByUserQuery = { __typename?: 'Query', sellerStatisticsByUser: { __typename?: 'SellerStatisticsByUserConnection', totalAmount?: number | null, totalSubscription?: number | null, subscriptions?: Array<number> | null, amounts?: Array<number> | null } };

export type CreateInviteMutationVariables = Exact<{
  input: CreateInviteInput;
}>;


export type CreateInviteMutation = { __typename?: 'Mutation', createInvite: { __typename?: 'User', id: string, emailVerifyToken?: string | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type MembershipItemFragment = { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, role: Role, isVerified: boolean };

export type ResendInviteMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ResendInviteMutation = { __typename?: 'Mutation', resendInvite: boolean };

export type CreateCustomerWalletMutationVariables = Exact<{
  input: CreateCustomerWalletInput;
}>;


export type CreateCustomerWalletMutation = { __typename?: 'Mutation', createCustomerWallet: { __typename?: 'Wallet', id: string } };

export type GetCustomerWalletListQueryVariables = Exact<{
  customerId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetCustomerWalletListQuery = { __typename?: 'Query', wallets: { __typename?: 'WalletConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number }, results?: Array<{ __typename?: 'Wallet', id: string, name: string, balance: string, consumedCredits: string, creditsBalance: string, createdAt: any, lastBalanceSyncAt: any, lastConsumedCreditAt: any, status: WalletStatusEnum, terminatedAt?: any | null }> | null } };

export type TerminateCustomerWalletMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TerminateCustomerWalletMutation = { __typename?: 'Mutation', terminateCustomerWallet: { __typename?: 'Wallet', id: string, status: WalletStatusEnum, name: string, balance: string, consumedCredits: string, creditsBalance: string, createdAt: any, lastBalanceSyncAt: any, lastConsumedCreditAt: any, terminatedAt?: any | null } };

export type CreateCustomerWalletTransactionMutationVariables = Exact<{
  input: TopUpWalletInput;
}>;


export type CreateCustomerWalletTransactionMutation = { __typename?: 'Mutation', topupWalletTransaction: Array<{ __typename?: 'WalletTransaction', id: string }> };

export type WalletForTopupFragment = { __typename?: 'Wallet', id: string };

export type WalletAccordionFragment = { __typename?: 'Wallet', id: string, name: string, balance: string, consumedCredits: string, creditsBalance: string, createdAt: any, lastBalanceSyncAt: any, lastConsumedCreditAt: any, status: WalletStatusEnum, terminatedAt?: any | null };

export type GetWalletTransactionsQueryVariables = Exact<{
  walletId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetWalletTransactionsQuery = { __typename?: 'Query', walletTransactions: { __typename?: 'WalletTransactionConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'WalletTransaction', id: string, status: WalletTransactionStatusEnum, transactionType: WalletTransactionTypeEnum, amount: string, settledAt?: any | null, createdAt: any, updatedAt: any }> | null } };

export type WalletInfosForTransactionsFragment = { __typename?: 'Wallet', id: string, status: WalletStatusEnum };

export type CurrentUserFragment = { __typename?: 'User', id: string };

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['JWT'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'Auth', accessToken: any, user: { __typename?: 'User', email: string, firstName: string, lastName: string } } };

export type GetPlanAddonsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  category: ProductCategory;
}>;


export type GetPlanAddonsQuery = { __typename?: 'Query', plans: { __typename?: 'PlanConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'Plan', id: string, name: string, code: string, amountCents: number, interval: PlanInterval, activeSubscriptionsCount: number, createdAt: any, defaultSystem?: boolean | null }> | null } };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'Subscription', id: string, createdAt: any, updatedAt: any, isTrial: boolean } };

export type GetSinglePlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSinglePlanQuery = { __typename?: 'Query', plan: { __typename?: 'Plan', id: string, name: string, code: string, description: string, interval: PlanInterval, payInAdvance: boolean, amountCents: number, trialPeriod: number, createdAt: any, activeSubscriptionsCount: number, defaultSystem?: boolean | null, isEdit?: boolean | null, addons: Array<{ __typename?: 'Plan', id: string, name: string, code: string, amountCents: number }> } };

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput;
}>;


export type CreatePlanMutation = { __typename?: 'Mutation', createPlan: { __typename?: 'Plan', id: string } };

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;


export type UpdatePlanMutation = { __typename?: 'Mutation', updatePlan: { __typename?: 'Plan', id: string, name: string, code: string, amountCents: number, interval: PlanInterval, activeSubscriptionsCount: number, createdAt: any, defaultSystem?: boolean | null } };

export type UpdateTopUpMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTopUpInput;
}>;


export type UpdateTopUpMutation = { __typename?: 'Mutation', updateTopUp: string };

export type AssignUserToCustomerMutationVariables = Exact<{
  input: AssginUserCustomerInput;
}>;


export type AssignUserToCustomerMutation = { __typename?: 'Mutation', assignUserToCustomer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, sale?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null } };

export type EditAddOnFragment = { __typename?: 'Plan', id: string, name: string, code: string, description: string, amountCents: number, interval: PlanInterval, payInAdvance: boolean, trialPeriod: number, createdAt: any, defaultSystem?: boolean | null, isEdit?: boolean | null, condition?: { __typename?: 'PlanCondition', key: FeatureKey, operator: string, value: string, maxUploadFileSystem?: number | null, maxUploadFile?: number | null, maxUploadKnowledgeBase?: number | null, numberProject?: number | null, maxAccount?: number | null } | null };

export type GetSingleAddOnQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleAddOnQuery = { __typename?: 'Query', plan: { __typename?: 'Plan', id: string, name: string, code: string, description: string, amountCents: number, interval: PlanInterval, payInAdvance: boolean, trialPeriod: number, createdAt: any, defaultSystem?: boolean | null, isEdit?: boolean | null, condition?: { __typename?: 'PlanCondition', key: FeatureKey, operator: string, value: string, maxUploadFileSystem?: number | null, maxUploadFile?: number | null, maxUploadKnowledgeBase?: number | null, numberProject?: number | null, maxAccount?: number | null } | null } };

export type CreateAddOnMutationVariables = Exact<{
  input: CreatePlanInput;
}>;


export type CreateAddOnMutation = { __typename?: 'Mutation', createPlan: { __typename?: 'Plan', id: string } };

export type UpdateAddOnMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;


export type UpdateAddOnMutation = { __typename?: 'Mutation', updatePlan: { __typename?: 'Plan', id: string } };

export type AddCustomerDrawerFragment = { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, phone?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null, sale?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null };

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, phone?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null, sale?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null } };

export type AllInvoiceDetailsForCustomerInvoiceDetailsFragment = { __typename?: 'Invoice', invoiceType: InvoiceType, number: string, paymentStatus: InvoicePaymentStatus, status: InvoiceStatus, totalAmountCents: string, id: string, issuingDate: any, paymentMethod?: InvoicePaymentMethod | null, note?: string | null, paymentAt?: any | null, endAt?: any | null, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null }, fees?: Array<{ __typename?: 'Fee', id: string, amountCents: string, plan: { __typename?: 'Plan', id: string, name: string } }> | null, subscription?: { __typename?: 'Subscription', seller?: { __typename?: 'User', firstName: string, lastName: string } | null } | null };

export type GetInvoiceDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvoiceDetailsQuery = { __typename?: 'Query', invoice: { __typename?: 'Invoice', id: string, invoiceType: InvoiceType, number: string, paymentStatus: InvoicePaymentStatus, status: InvoiceStatus, totalAmountCents: string, issuingDate: any, paymentMethod?: InvoicePaymentMethod | null, note?: string | null, paymentAt?: any | null, endAt?: any | null, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null }, fees?: Array<{ __typename?: 'Fee', id: string, amountCents: string, plan: { __typename?: 'Plan', id: string, name: string } }> | null, subscription?: { __typename?: 'Subscription', seller?: { __typename?: 'User', firstName: string, lastName: string } | null } | null } };

export type RefreshInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefreshInvoiceMutation = { __typename?: 'Mutation', refreshDraftInvoice: { __typename?: 'Invoice', id: string, invoiceType: InvoiceType, number: string, paymentStatus: InvoicePaymentStatus, status: InvoiceStatus, totalAmountCents: string, issuingDate: any, paymentMethod?: InvoicePaymentMethod | null, note?: string | null, paymentAt?: any | null, endAt?: any | null, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null }, fees?: Array<{ __typename?: 'Fee', id: string, amountCents: string, plan: { __typename?: 'Plan', id: string, name: string } }> | null, subscription?: { __typename?: 'Subscription', seller?: { __typename?: 'User', firstName: string, lastName: string } | null } | null } };

export type AddOnsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type AddOnsQuery = { __typename?: 'Query', plans: { __typename?: 'PlanConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'Plan', id: string, name: string, code: string, amountCents: number, interval: PlanInterval, activeSubscriptionsCount: number, createdAt: any, defaultSystem?: boolean | null }> | null } };

export type AddOnInputFragment = { __typename?: 'Plan', id: string, name: string, code: string, amountCents: number };

export type EditPlanFragment = { __typename?: 'Plan', id: string, name: string, code: string, description: string, interval: PlanInterval, payInAdvance: boolean, amountCents: number, trialPeriod: number, createdAt: any, activeSubscriptionsCount: number, defaultSystem?: boolean | null, isEdit?: boolean | null, addons: Array<{ __typename?: 'Plan', id: string, name: string, code: string, amountCents: number }> };

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string, phone?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null, sale?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null } };

export type GetSubscriptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  customerId: Scalars['ID'];
  category: ProductCategory;
}>;


export type GetSubscriptionsQuery = { __typename?: 'Query', subscriptions: { __typename?: 'SubscriptionConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'Subscription', id: string, createdAt: any, isTrial: boolean, startAt: any, plan: { __typename?: 'Plan', id: string, name: string, code: string, amountCents: number, defaultSystem?: boolean | null } }> | null } };

export type GetCustomerDraftInvoicesQueryVariables = Exact<{
  customerId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InvoiceStatus>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type GetCustomerDraftInvoicesQuery = { __typename?: 'Query', invoices: { __typename?: 'InvoiceConnection', results?: Array<{ __typename?: 'Invoice', id: string, status: InvoiceStatus, paymentStatus: InvoicePaymentStatus, number: string, issuingDate: any, totalAmountCents: string, invoiceType: InvoiceType, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } }> | null, metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number } } };

export type GetCustomerInfosForDraftInvoicesListQueryVariables = Exact<{
  customerId: Scalars['ID'];
  status?: InputMaybe<InvoiceStatus>;
}>;


export type GetCustomerInfosForDraftInvoicesListQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null }, invoices: { __typename?: 'InvoiceConnection', metadata: { __typename?: 'PageInfo', totalCount: number } } };

export type CustomersQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers: { __typename?: 'CustomerConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'Customer', id: string, email: string, createdAt: any, updatedAt: any, firstName?: string | null, lastName?: string | null, activeSubscriptionCount?: number | null, phone?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: any | null, city?: string | null, zipcode?: string | null, sale?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null }> | null } };

export type CustomerStatisticsQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  type: FilterCustomer;
  currentTime: Scalars['String'];
}>;


export type CustomerStatisticsQuery = { __typename?: 'Query', customerStatistics: { __typename?: 'CustomCustomerConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number }, results?: Array<{ __typename?: 'CustomCustomerResult', customer?: { __typename?: 'Customer', _id?: string | null, email: string, firstName?: string | null, lastName?: string | null, createdAt: any } | null, subscriptions?: Array<{ __typename?: 'Subscription', createdAt: any, endAt?: any | null, isTrial: boolean, plan: { __typename?: 'Plan', name: string, category: ProductCategory } }> | null }> | null } | { __typename?: 'CustomerConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number }, results?: Array<{ __typename?: 'Customer', _id?: string | null, email: string, firstName?: string | null, lastName?: string | null, createdAt: any, sale?: { __typename?: 'User', id: string } | null }> | null } };

export type GetListInvoiceByTypeQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  type: FilterInvoice;
  currentTime: Scalars['String'];
}>;


export type GetListInvoiceByTypeQuery = { __typename?: 'Query', getListInvoiceByType: { __typename?: 'InvoiceConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number }, results?: Array<{ __typename?: 'Invoice', _id?: string | null, status: InvoiceStatus, paymentAt?: any | null, endAt?: any | null, number: string, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string } }> | null } };

export type GetinviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetinviteQuery = { __typename?: 'Query', invite: { __typename?: 'User', id: string, firstName: string, lastName: string, role: Role, email: string } };

export type AcceptInviteMutationVariables = Exact<{
  input: AcceptInviteInput;
}>;


export type AcceptInviteMutation = { __typename?: 'Mutation', acceptInvite: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string } } };

export type InvoiceDetailsForInvoiceOverviewFragment = { __typename?: 'Invoice', id: string, status: InvoiceStatus, issuingDate: any, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } };

export type InvoicesListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InvoiceStatus>;
  searchTerm?: InputMaybe<Scalars['String']>;
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatus> | InvoicePaymentStatus>;
  invoiceType?: InputMaybe<InvoiceType>;
}>;


export type InvoicesListQuery = { __typename?: 'Query', invoices: { __typename?: 'InvoiceConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'Invoice', id: string, status: InvoiceStatus, paymentStatus: InvoicePaymentStatus, number: string, issuingDate: any, totalAmountCents: string, invoiceType: InvoiceType, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } }> | null } };

export type PlansQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type PlansQuery = { __typename?: 'Query', plans: { __typename?: 'PlanConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'Plan', id: string, name: string, code: string, amountCents: number, interval: PlanInterval, activeSubscriptionsCount: number, createdAt: any, defaultSystem?: boolean | null }> | null } };

export type GetRequestTopUpQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRequestTopUpQuery = { __typename?: 'Query', getRequestTopUp: { __typename?: 'WalletTransaction', _id?: string | null, amount: string, status: WalletTransactionStatusEnum, transactionType: WalletTransactionTypeEnum, createdAt: any, note?: string | null, wallet: { __typename?: 'Wallet', name: string, status: WalletStatusEnum, balance: string, customer: { __typename?: 'Customer', firstName?: string | null, lastName?: string | null, email: string, phone?: string | null } }, seller?: { __typename?: 'User', _id?: string | null, firstName: string, lastName: string, email: string } | null, verifier?: { __typename?: 'User', _id?: string | null, firstName: string, lastName: string, email: string } | null } };

export type RequestTopUpsQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  status?: InputMaybe<WalletTransactionStatusEnum>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type RequestTopUpsQuery = { __typename?: 'Query', requestTopUps: { __typename?: 'WalletTransactionConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number }, results?: Array<{ __typename?: 'WalletTransaction', _id?: string | null, amount: string, status: WalletTransactionStatusEnum, transactionType: WalletTransactionTypeEnum, createdAt: any, note?: string | null, wallet: { __typename?: 'Wallet', balance: string, customer: { __typename?: 'Customer', firstName?: string | null, lastName?: string | null, email: string } } }> | null } };

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string } } };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', email: string, firstName: string, lastName: string, role: Role, phone?: string | null, facebook?: string | null, zalo?: string | null, telegram?: string | null, skype?: string | null, viber?: string | null, createdAt: any, enabled?: boolean | null } };

export type GetUsersQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', metadata: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalCount: number }, results?: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, role: Role, isVerified: boolean }> | null } };

export const FeatureDetailFragmentDoc = gql`
    fragment FeatureDetail on Feature {
  id
  label
  key
  type
  operators
}
    `;
export const InvoiceForInvoiceListFragmentDoc = gql`
    fragment InvoiceForInvoiceList on InvoiceConnection {
  results {
    id
    status
    paymentStatus
    number
    issuingDate
    totalAmountCents
    invoiceType
    customer {
      id
      firstName
      lastName
    }
  }
  metadata {
    currentPage
    totalCount
    totalPages
  }
}
    `;
export const AddCustomerDrawerFragmentDoc = gql`
    fragment AddCustomerDrawer on Customer {
  id
  firstName
  lastName
  email
  sale {
    id
    firstName
    lastName
    email
  }
  phone
  addressLine1
  addressLine2
  state
  country
  city
  zipcode
}
    `;
export const CustomerItemFragmentDoc = gql`
    fragment CustomerItem on Customer {
  id
  email
  createdAt
  updatedAt
  firstName
  lastName
  activeSubscriptionCount
  ...AddCustomerDrawer
}
    ${AddCustomerDrawerFragmentDoc}`;
export const InvoiceForUpdateInvoicePaymentStatusFragmentDoc = gql`
    fragment InvoiceForUpdateInvoicePaymentStatus on Invoice {
  id
  paymentStatus
  status
  invoiceType
  paymentAt
}
    `;
export const InvoiceListItemFragmentDoc = gql`
    fragment InvoiceListItem on Invoice {
  id
  status
  paymentStatus
  number
  issuingDate
  totalAmountCents
  invoiceType
  customer {
    id
    firstName
    lastName
  }
}
    `;
export const DeletePlanDialogFragmentDoc = gql`
    fragment DeletePlanDialog on Plan {
  id
  name
  activeSubscriptionsCount
}
    `;
export const PlanItemFragmentDoc = gql`
    fragment PlanItem on Plan {
  id
  name
  code
  amountCents
  interval
  activeSubscriptionsCount
  createdAt
  defaultSystem
}
    `;
export const RequestTopUpListItemFragmentDoc = gql`
    fragment RequestTopUpListItem on WalletTransaction {
  _id
  amount
  status
  transactionType
  createdAt
  note
  wallet {
    balance
    customer {
      firstName
      lastName
      email
    }
  }
}
    `;
export const CustomerSupportListItemFragmentDoc = gql`
    fragment CustomerSupportListItem on Customer {
  id
  firstName
  lastName
  email
  activeSubscriptionCount
  createdAt
}
    `;
export const MembershipItemFragmentDoc = gql`
    fragment MembershipItem on User {
  id
  email
  firstName
  lastName
  role
  isVerified
}
    `;
export const WalletForTopupFragmentDoc = gql`
    fragment WalletForTopup on Wallet {
  id
}
    `;
export const WalletAccordionFragmentDoc = gql`
    fragment WalletAccordion on Wallet {
  id
  name
  balance
  consumedCredits
  creditsBalance
  createdAt
  lastBalanceSyncAt
  lastConsumedCreditAt
  status
  terminatedAt
}
    `;
export const WalletInfosForTransactionsFragmentDoc = gql`
    fragment WalletInfosForTransactions on Wallet {
  id
  status
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
}
    `;
export const EditAddOnFragmentDoc = gql`
    fragment EditAddOn on Plan {
  id
  name
  code
  description
  amountCents
  interval
  payInAdvance
  trialPeriod
  createdAt
  defaultSystem
  isEdit
  condition {
    key
    operator
    value
    maxUploadFileSystem
    maxUploadFile
    maxUploadKnowledgeBase
    numberProject
    maxAccount
  }
}
    `;
export const InvoiceDetailsForInvoiceOverviewFragmentDoc = gql`
    fragment InvoiceDetailsForInvoiceOverview on Invoice {
  id
  status
  issuingDate
  customer {
    id
    firstName
    lastName
  }
}
    `;
export const InvoiceForDetailsTableFooterFragmentDoc = gql`
    fragment InvoiceForDetailsTableFooter on Invoice {
  totalAmountCents
}
    `;
export const InvoiceForDetailsTableFragmentDoc = gql`
    fragment InvoiceForDetailsTable on Invoice {
  invoiceType
  totalAmountCents
  ...InvoiceForDetailsTableFooter
  fees {
    id
    plan {
      id
      name
    }
    amountCents
  }
}
    ${InvoiceForDetailsTableFooterFragmentDoc}`;
export const InvoiceForInvoiceInfosFragmentDoc = gql`
    fragment InvoiceForInvoiceInfos on Invoice {
  number
  issuingDate
  paymentStatus
  invoiceType
  paymentMethod
  note
  paymentAt
  customer {
    id
    firstName
    lastName
    email
    addressLine1
    addressLine2
    state
    country
    city
    zipcode
  }
  endAt
  subscription {
    seller {
      firstName
      lastName
    }
  }
}
    `;
export const AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc = gql`
    fragment AllInvoiceDetailsForCustomerInvoiceDetails on Invoice {
  invoiceType
  number
  paymentStatus
  status
  totalAmountCents
  ...InvoiceDetailsForInvoiceOverview
  ...InvoiceForDetailsTable
  ...InvoiceForInvoiceInfos
}
    ${InvoiceDetailsForInvoiceOverviewFragmentDoc}
${InvoiceForDetailsTableFragmentDoc}
${InvoiceForInvoiceInfosFragmentDoc}`;
export const AddOnInputFragmentDoc = gql`
    fragment AddOnInput on Plan {
  id
  name
  code
  amountCents
}
    `;
export const EditPlanFragmentDoc = gql`
    fragment EditPlan on Plan {
  id
  name
  code
  description
  interval
  payInAdvance
  amountCents
  trialPeriod
  createdAt
  activeSubscriptionsCount
  defaultSystem
  isEdit
  addons {
    ...AddOnInput
  }
}
    ${AddOnInputFragmentDoc}`;
export const UserIdentifierDocument = gql`
    query UserIdentifier {
  me: me {
    id
    email
    firstName
    lastName
    phone
    facebook
    zalo
    telegram
    skype
    viber
    role
    isVerified
  }
}
    `;

/**
 * __useUserIdentifierQuery__
 *
 * To run a query within a React component, call `useUserIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentifierQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdentifierQuery(baseOptions?: Apollo.QueryHookOptions<UserIdentifierQuery, UserIdentifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIdentifierQuery, UserIdentifierQueryVariables>(UserIdentifierDocument, options);
      }
export function useUserIdentifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIdentifierQuery, UserIdentifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIdentifierQuery, UserIdentifierQueryVariables>(UserIdentifierDocument, options);
        }
export type UserIdentifierQueryHookResult = ReturnType<typeof useUserIdentifierQuery>;
export type UserIdentifierLazyQueryHookResult = ReturnType<typeof useUserIdentifierLazyQuery>;
export type UserIdentifierQueryResult = Apollo.QueryResult<UserIdentifierQuery, UserIdentifierQueryVariables>;
export const FeaturesDocument = gql`
    query features($page: Int, $limit: Int, $searchTerm: String) {
  features(page: $page, limit: $limit, query: $searchTerm) {
    ...FeatureDetail
  }
}
    ${FeatureDetailFragmentDoc}`;

/**
 * __useFeaturesQuery__
 *
 * To run a query within a React component, call `useFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
      }
export function useFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
        }
export type FeaturesQueryHookResult = ReturnType<typeof useFeaturesQuery>;
export type FeaturesLazyQueryHookResult = ReturnType<typeof useFeaturesLazyQuery>;
export type FeaturesQueryResult = Apollo.QueryResult<FeaturesQuery, FeaturesQueryVariables>;
export const DeleteAddOnDocument = gql`
    mutation deleteAddOn($id: ID!) {
  deletePlan(id: $id) {
    id
  }
}
    `;
export type DeleteAddOnMutationFn = Apollo.MutationFunction<DeleteAddOnMutation, DeleteAddOnMutationVariables>;

/**
 * __useDeleteAddOnMutation__
 *
 * To run a mutation, you first call `useDeleteAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddOnMutation, { data, loading, error }] = useDeleteAddOnMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAddOnMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddOnMutation, DeleteAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddOnMutation, DeleteAddOnMutationVariables>(DeleteAddOnDocument, options);
      }
export type DeleteAddOnMutationHookResult = ReturnType<typeof useDeleteAddOnMutation>;
export type DeleteAddOnMutationResult = Apollo.MutationResult<DeleteAddOnMutation>;
export type DeleteAddOnMutationOptions = Apollo.BaseMutationOptions<DeleteAddOnMutation, DeleteAddOnMutationVariables>;
export const GetCustomerInvoicesDocument = gql`
    query getCustomerInvoices($customerId: ID!, $limit: Int, $page: Int, $status: InvoiceStatus, $invoiceType: InvoiceType, $searchTerm: String) {
  invoices(
    customerId: $customerId
    limit: $limit
    page: $page
    status: $status
    invoiceType: $invoiceType
    query: $searchTerm
  ) {
    ...InvoiceForInvoiceList
  }
}
    ${InvoiceForInvoiceListFragmentDoc}`;

/**
 * __useGetCustomerInvoicesQuery__
 *
 * To run a query within a React component, call `useGetCustomerInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      invoiceType: // value for 'invoiceType'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCustomerInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>(GetCustomerInvoicesDocument, options);
      }
export function useGetCustomerInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>(GetCustomerInvoicesDocument, options);
        }
export type GetCustomerInvoicesQueryHookResult = ReturnType<typeof useGetCustomerInvoicesQuery>;
export type GetCustomerInvoicesLazyQueryHookResult = ReturnType<typeof useGetCustomerInvoicesLazyQuery>;
export type GetCustomerInvoicesQueryResult = Apollo.QueryResult<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>;
export const TerminateCustomerSubscriptionDocument = gql`
    mutation terminateCustomerSubscription($id: ID!, $note: String) {
  terminateSubscription(id: $id, note: $note) {
    id
  }
}
    `;
export type TerminateCustomerSubscriptionMutationFn = Apollo.MutationFunction<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>;

/**
 * __useTerminateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useTerminateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustomerSubscriptionMutation, { data, loading, error }] = useTerminateCustomerSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useTerminateCustomerSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>(TerminateCustomerSubscriptionDocument, options);
      }
export type TerminateCustomerSubscriptionMutationHookResult = ReturnType<typeof useTerminateCustomerSubscriptionMutation>;
export type TerminateCustomerSubscriptionMutationResult = Apollo.MutationResult<TerminateCustomerSubscriptionMutation>;
export type TerminateCustomerSubscriptionMutationOptions = Apollo.BaseMutationOptions<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>;
export const GetInvoiceDocument = gql`
    query getInvoice($invoiceId: ID, $subscriptionId: ID) {
  getInvoice(invoiceId: $invoiceId, subscriptionId: $subscriptionId) {
    id
    number
    status
    fees {
      plan {
        name
      }
    }
  }
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const SellerStatisticsDocument = gql`
    query sellerStatistics($page: Int!, $limit: Int!, $startDate: String, $endDate: String) {
  sellerStatistics(
    page: $page
    limit: $limit
    startDate: $startDate
    endDate: $endDate
  ) {
    metadata {
      currentPage
      totalCount
      totalPages
    }
    results {
      _id
      totalSubscription
      totalAmount
      user {
        _id
        email
        firstName
        lastName
        enabled
        createdAt
      }
    }
  }
}
    `;

/**
 * __useSellerStatisticsQuery__
 *
 * To run a query within a React component, call `useSellerStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerStatisticsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSellerStatisticsQuery(baseOptions: Apollo.QueryHookOptions<SellerStatisticsQuery, SellerStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerStatisticsQuery, SellerStatisticsQueryVariables>(SellerStatisticsDocument, options);
      }
export function useSellerStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerStatisticsQuery, SellerStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerStatisticsQuery, SellerStatisticsQueryVariables>(SellerStatisticsDocument, options);
        }
export type SellerStatisticsQueryHookResult = ReturnType<typeof useSellerStatisticsQuery>;
export type SellerStatisticsLazyQueryHookResult = ReturnType<typeof useSellerStatisticsLazyQuery>;
export type SellerStatisticsQueryResult = Apollo.QueryResult<SellerStatisticsQuery, SellerStatisticsQueryVariables>;
export const UpdateInvoicePaymentStatusDocument = gql`
    mutation updateInvoicePaymentStatus($input: UpdatePaymentInput!) {
  updateInvoicePaymentStatus(input: $input) {
    id
    ...InvoiceForUpdateInvoicePaymentStatus
  }
}
    ${InvoiceForUpdateInvoicePaymentStatusFragmentDoc}`;
export type UpdateInvoicePaymentStatusMutationFn = Apollo.MutationFunction<UpdateInvoicePaymentStatusMutation, UpdateInvoicePaymentStatusMutationVariables>;

/**
 * __useUpdateInvoicePaymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicePaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicePaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicePaymentStatusMutation, { data, loading, error }] = useUpdateInvoicePaymentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoicePaymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoicePaymentStatusMutation, UpdateInvoicePaymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoicePaymentStatusMutation, UpdateInvoicePaymentStatusMutationVariables>(UpdateInvoicePaymentStatusDocument, options);
      }
export type UpdateInvoicePaymentStatusMutationHookResult = ReturnType<typeof useUpdateInvoicePaymentStatusMutation>;
export type UpdateInvoicePaymentStatusMutationResult = Apollo.MutationResult<UpdateInvoicePaymentStatusMutation>;
export type UpdateInvoicePaymentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInvoicePaymentStatusMutation, UpdateInvoicePaymentStatusMutationVariables>;
export const UpdateInvoiceStatusDocument = gql`
    mutation updateInvoiceStatus($id: ID!, $status: InvoiceStatus!, $note: String) {
  updateInvoiceStatus(id: $id, status: $status, note: $note) {
    id
    status
    note
  }
}
    `;
export type UpdateInvoiceStatusMutationFn = Apollo.MutationFunction<UpdateInvoiceStatusMutation, UpdateInvoiceStatusMutationVariables>;

/**
 * __useUpdateInvoiceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceStatusMutation, { data, loading, error }] = useUpdateInvoiceStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateInvoiceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceStatusMutation, UpdateInvoiceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceStatusMutation, UpdateInvoiceStatusMutationVariables>(UpdateInvoiceStatusDocument, options);
      }
export type UpdateInvoiceStatusMutationHookResult = ReturnType<typeof useUpdateInvoiceStatusMutation>;
export type UpdateInvoiceStatusMutationResult = Apollo.MutationResult<UpdateInvoiceStatusMutation>;
export type UpdateInvoiceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceStatusMutation, UpdateInvoiceStatusMutationVariables>;
export const FinalizeInvoiceDocument = gql`
    mutation finalizeInvoice($id: ID!) {
  finalizeInvoice(id: $id) {
    id
  }
}
    `;
export type FinalizeInvoiceMutationFn = Apollo.MutationFunction<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;

/**
 * __useFinalizeInvoiceMutation__
 *
 * To run a mutation, you first call `useFinalizeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeInvoiceMutation, { data, loading, error }] = useFinalizeInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizeInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>(FinalizeInvoiceDocument, options);
      }
export type FinalizeInvoiceMutationHookResult = ReturnType<typeof useFinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationResult = Apollo.MutationResult<FinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationOptions = Apollo.BaseMutationOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;
export const DeletePlanDocument = gql`
    mutation deletePlan($id: ID!) {
  deletePlan(id: $id) {
    id
  }
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    user {
      id
    }
    accessToken
    refreshToken
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateUserInput!) {
  updateProfile(input: $input) {
    id
    email
    firstName
    lastName
    phone
    facebook
    zalo
    telegram
    skype
    viber
    role
    isVerified
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const CustomerSupportsDocument = gql`
    query customerSupports($page: Int!, $limit: Int!, $userId: String, $searchTerm: String) {
  customerSupports(
    page: $page
    limit: $limit
    userId: $userId
    query: $searchTerm
  ) {
    metadata {
      currentPage
      totalCount
      totalPages
    }
    results {
      ...CustomerSupportListItem
    }
  }
}
    ${CustomerSupportListItemFragmentDoc}`;

/**
 * __useCustomerSupportsQuery__
 *
 * To run a query within a React component, call `useCustomerSupportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSupportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSupportsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      userId: // value for 'userId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCustomerSupportsQuery(baseOptions: Apollo.QueryHookOptions<CustomerSupportsQuery, CustomerSupportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerSupportsQuery, CustomerSupportsQueryVariables>(CustomerSupportsDocument, options);
      }
export function useCustomerSupportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerSupportsQuery, CustomerSupportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerSupportsQuery, CustomerSupportsQueryVariables>(CustomerSupportsDocument, options);
        }
export type CustomerSupportsQueryHookResult = ReturnType<typeof useCustomerSupportsQuery>;
export type CustomerSupportsLazyQueryHookResult = ReturnType<typeof useCustomerSupportsLazyQuery>;
export type CustomerSupportsQueryResult = Apollo.QueryResult<CustomerSupportsQuery, CustomerSupportsQueryVariables>;
export const SellerStatisticsByUserDocument = gql`
    query sellerStatisticsByUser($userId: String, $currentTime: String!) {
  sellerStatisticsByUser(userId: $userId, currentTime: $currentTime) {
    totalAmount
    totalSubscription
    subscriptions
    amounts
  }
}
    `;

/**
 * __useSellerStatisticsByUserQuery__
 *
 * To run a query within a React component, call `useSellerStatisticsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerStatisticsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerStatisticsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useSellerStatisticsByUserQuery(baseOptions: Apollo.QueryHookOptions<SellerStatisticsByUserQuery, SellerStatisticsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerStatisticsByUserQuery, SellerStatisticsByUserQueryVariables>(SellerStatisticsByUserDocument, options);
      }
export function useSellerStatisticsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerStatisticsByUserQuery, SellerStatisticsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerStatisticsByUserQuery, SellerStatisticsByUserQueryVariables>(SellerStatisticsByUserDocument, options);
        }
export type SellerStatisticsByUserQueryHookResult = ReturnType<typeof useSellerStatisticsByUserQuery>;
export type SellerStatisticsByUserLazyQueryHookResult = ReturnType<typeof useSellerStatisticsByUserLazyQuery>;
export type SellerStatisticsByUserQueryResult = Apollo.QueryResult<SellerStatisticsByUserQuery, SellerStatisticsByUserQueryVariables>;
export const CreateInviteDocument = gql`
    mutation createInvite($input: CreateInviteInput!) {
  createInvite(input: $input) {
    id
    emailVerifyToken
  }
}
    `;
export type CreateInviteMutationFn = Apollo.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, options);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ResendInviteDocument = gql`
    mutation resendInvite($userId: ID!) {
  resendInvite(userId: $userId)
}
    `;
export type ResendInviteMutationFn = Apollo.MutationFunction<ResendInviteMutation, ResendInviteMutationVariables>;

/**
 * __useResendInviteMutation__
 *
 * To run a mutation, you first call `useResendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteMutation, { data, loading, error }] = useResendInviteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendInviteMutation, ResendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, options);
      }
export type ResendInviteMutationHookResult = ReturnType<typeof useResendInviteMutation>;
export type ResendInviteMutationResult = Apollo.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = Apollo.BaseMutationOptions<ResendInviteMutation, ResendInviteMutationVariables>;
export const CreateCustomerWalletDocument = gql`
    mutation createCustomerWallet($input: CreateCustomerWalletInput!) {
  createCustomerWallet(input: $input) {
    id
  }
}
    `;
export type CreateCustomerWalletMutationFn = Apollo.MutationFunction<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>;

/**
 * __useCreateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWalletMutation, { data, loading, error }] = useCreateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerWalletMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>(CreateCustomerWalletDocument, options);
      }
export type CreateCustomerWalletMutationHookResult = ReturnType<typeof useCreateCustomerWalletMutation>;
export type CreateCustomerWalletMutationResult = Apollo.MutationResult<CreateCustomerWalletMutation>;
export type CreateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>;
export const GetCustomerWalletListDocument = gql`
    query getCustomerWalletList($customerId: ID!, $page: Int, $limit: Int) {
  wallets(customerId: $customerId, page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    results {
      ...WalletAccordion
    }
  }
}
    ${WalletAccordionFragmentDoc}`;

/**
 * __useGetCustomerWalletListQuery__
 *
 * To run a query within a React component, call `useGetCustomerWalletListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerWalletListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerWalletListQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCustomerWalletListQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>(GetCustomerWalletListDocument, options);
      }
export function useGetCustomerWalletListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>(GetCustomerWalletListDocument, options);
        }
export type GetCustomerWalletListQueryHookResult = ReturnType<typeof useGetCustomerWalletListQuery>;
export type GetCustomerWalletListLazyQueryHookResult = ReturnType<typeof useGetCustomerWalletListLazyQuery>;
export type GetCustomerWalletListQueryResult = Apollo.QueryResult<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>;
export const TerminateCustomerWalletDocument = gql`
    mutation terminateCustomerWallet($id: ID!) {
  terminateCustomerWallet(id: $id) {
    id
    status
    ...WalletAccordion
  }
}
    ${WalletAccordionFragmentDoc}`;
export type TerminateCustomerWalletMutationFn = Apollo.MutationFunction<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>;

/**
 * __useTerminateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useTerminateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustomerWalletMutation, { data, loading, error }] = useTerminateCustomerWalletMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTerminateCustomerWalletMutation(baseOptions?: Apollo.MutationHookOptions<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>(TerminateCustomerWalletDocument, options);
      }
export type TerminateCustomerWalletMutationHookResult = ReturnType<typeof useTerminateCustomerWalletMutation>;
export type TerminateCustomerWalletMutationResult = Apollo.MutationResult<TerminateCustomerWalletMutation>;
export type TerminateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>;
export const CreateCustomerWalletTransactionDocument = gql`
    mutation createCustomerWalletTransaction($input: TopUpWalletInput!) {
  topupWalletTransaction(input: $input) {
    id
  }
}
    `;
export type CreateCustomerWalletTransactionMutationFn = Apollo.MutationFunction<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>;

/**
 * __useCreateCustomerWalletTransactionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWalletTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWalletTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWalletTransactionMutation, { data, loading, error }] = useCreateCustomerWalletTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerWalletTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>(CreateCustomerWalletTransactionDocument, options);
      }
export type CreateCustomerWalletTransactionMutationHookResult = ReturnType<typeof useCreateCustomerWalletTransactionMutation>;
export type CreateCustomerWalletTransactionMutationResult = Apollo.MutationResult<CreateCustomerWalletTransactionMutation>;
export type CreateCustomerWalletTransactionMutationOptions = Apollo.BaseMutationOptions<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>;
export const GetWalletTransactionsDocument = gql`
    query getWalletTransactions($walletId: ID!, $page: Int, $limit: Int) {
  walletTransactions(walletId: $walletId, page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      id
      status
      transactionType
      amount
      settledAt
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useGetWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletTransactionsQuery({
 *   variables: {
 *      walletId: // value for 'walletId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWalletTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(GetWalletTransactionsDocument, options);
      }
export function useGetWalletTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(GetWalletTransactionsDocument, options);
        }
export type GetWalletTransactionsQueryHookResult = ReturnType<typeof useGetWalletTransactionsQuery>;
export type GetWalletTransactionsLazyQueryHookResult = ReturnType<typeof useGetWalletTransactionsLazyQuery>;
export type GetWalletTransactionsQueryResult = Apollo.QueryResult<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($token: JWT!) {
  refreshToken(token: $token) {
    accessToken
    user {
      email
      firstName
      lastName
    }
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const GetPlanAddonsDocument = gql`
    query getPlanAddons($page: Int, $limit: Int, $searchTerm: String, $category: ProductCategory!) {
  plans(page: $page, limit: $limit, query: $searchTerm, category: $category) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      id
      ...PlanItem
    }
  }
}
    ${PlanItemFragmentDoc}`;

/**
 * __useGetPlanAddonsQuery__
 *
 * To run a query within a React component, call `useGetPlanAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanAddonsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetPlanAddonsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanAddonsQuery, GetPlanAddonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanAddonsQuery, GetPlanAddonsQueryVariables>(GetPlanAddonsDocument, options);
      }
export function useGetPlanAddonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanAddonsQuery, GetPlanAddonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanAddonsQuery, GetPlanAddonsQueryVariables>(GetPlanAddonsDocument, options);
        }
export type GetPlanAddonsQueryHookResult = ReturnType<typeof useGetPlanAddonsQuery>;
export type GetPlanAddonsLazyQueryHookResult = ReturnType<typeof useGetPlanAddonsLazyQuery>;
export type GetPlanAddonsQueryResult = Apollo.QueryResult<GetPlanAddonsQuery, GetPlanAddonsQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    id
    createdAt
    updatedAt
    isTrial
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const GetSinglePlanDocument = gql`
    query getSinglePlan($id: ID!) {
  plan(id: $id) {
    ...EditPlan
  }
}
    ${EditPlanFragmentDoc}`;

/**
 * __useGetSinglePlanQuery__
 *
 * To run a query within a React component, call `useGetSinglePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSinglePlanQuery(baseOptions: Apollo.QueryHookOptions<GetSinglePlanQuery, GetSinglePlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSinglePlanQuery, GetSinglePlanQueryVariables>(GetSinglePlanDocument, options);
      }
export function useGetSinglePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePlanQuery, GetSinglePlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSinglePlanQuery, GetSinglePlanQueryVariables>(GetSinglePlanDocument, options);
        }
export type GetSinglePlanQueryHookResult = ReturnType<typeof useGetSinglePlanQuery>;
export type GetSinglePlanLazyQueryHookResult = ReturnType<typeof useGetSinglePlanLazyQuery>;
export type GetSinglePlanQueryResult = Apollo.QueryResult<GetSinglePlanQuery, GetSinglePlanQueryVariables>;
export const CreatePlanDocument = gql`
    mutation createPlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    id
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation updatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    ...PlanItem
    ...DeletePlanDialog
  }
}
    ${PlanItemFragmentDoc}
${DeletePlanDialogFragmentDoc}`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const UpdateTopUpDocument = gql`
    mutation updateTopUp($id: ID!, $input: UpdateTopUpInput!) {
  updateTopUp(id: $id, input: $input)
}
    `;
export type UpdateTopUpMutationFn = Apollo.MutationFunction<UpdateTopUpMutation, UpdateTopUpMutationVariables>;

/**
 * __useUpdateTopUpMutation__
 *
 * To run a mutation, you first call `useUpdateTopUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopUpMutation, { data, loading, error }] = useUpdateTopUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopUpMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopUpMutation, UpdateTopUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopUpMutation, UpdateTopUpMutationVariables>(UpdateTopUpDocument, options);
      }
export type UpdateTopUpMutationHookResult = ReturnType<typeof useUpdateTopUpMutation>;
export type UpdateTopUpMutationResult = Apollo.MutationResult<UpdateTopUpMutation>;
export type UpdateTopUpMutationOptions = Apollo.BaseMutationOptions<UpdateTopUpMutation, UpdateTopUpMutationVariables>;
export const AssignUserToCustomerDocument = gql`
    mutation assignUserToCustomer($input: AssginUserCustomerInput!) {
  assignUserToCustomer(input: $input) {
    id
    firstName
    lastName
    sale {
      id
      firstName
      lastName
    }
  }
}
    `;
export type AssignUserToCustomerMutationFn = Apollo.MutationFunction<AssignUserToCustomerMutation, AssignUserToCustomerMutationVariables>;

/**
 * __useAssignUserToCustomerMutation__
 *
 * To run a mutation, you first call `useAssignUserToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserToCustomerMutation, { data, loading, error }] = useAssignUserToCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignUserToCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AssignUserToCustomerMutation, AssignUserToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignUserToCustomerMutation, AssignUserToCustomerMutationVariables>(AssignUserToCustomerDocument, options);
      }
export type AssignUserToCustomerMutationHookResult = ReturnType<typeof useAssignUserToCustomerMutation>;
export type AssignUserToCustomerMutationResult = Apollo.MutationResult<AssignUserToCustomerMutation>;
export type AssignUserToCustomerMutationOptions = Apollo.BaseMutationOptions<AssignUserToCustomerMutation, AssignUserToCustomerMutationVariables>;
export const GetSingleAddOnDocument = gql`
    query getSingleAddOn($id: ID!) {
  plan(id: $id) {
    ...EditAddOn
  }
}
    ${EditAddOnFragmentDoc}`;

/**
 * __useGetSingleAddOnQuery__
 *
 * To run a query within a React component, call `useGetSingleAddOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleAddOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleAddOnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleAddOnQuery(baseOptions: Apollo.QueryHookOptions<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>(GetSingleAddOnDocument, options);
      }
export function useGetSingleAddOnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>(GetSingleAddOnDocument, options);
        }
export type GetSingleAddOnQueryHookResult = ReturnType<typeof useGetSingleAddOnQuery>;
export type GetSingleAddOnLazyQueryHookResult = ReturnType<typeof useGetSingleAddOnLazyQuery>;
export type GetSingleAddOnQueryResult = Apollo.QueryResult<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>;
export const CreateAddOnDocument = gql`
    mutation createAddOn($input: CreatePlanInput!) {
  createPlan(input: $input) {
    id
  }
}
    `;
export type CreateAddOnMutationFn = Apollo.MutationFunction<CreateAddOnMutation, CreateAddOnMutationVariables>;

/**
 * __useCreateAddOnMutation__
 *
 * To run a mutation, you first call `useCreateAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddOnMutation, { data, loading, error }] = useCreateAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddOnMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddOnMutation, CreateAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddOnMutation, CreateAddOnMutationVariables>(CreateAddOnDocument, options);
      }
export type CreateAddOnMutationHookResult = ReturnType<typeof useCreateAddOnMutation>;
export type CreateAddOnMutationResult = Apollo.MutationResult<CreateAddOnMutation>;
export type CreateAddOnMutationOptions = Apollo.BaseMutationOptions<CreateAddOnMutation, CreateAddOnMutationVariables>;
export const UpdateAddOnDocument = gql`
    mutation updateAddOn($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    id
  }
}
    `;
export type UpdateAddOnMutationFn = Apollo.MutationFunction<UpdateAddOnMutation, UpdateAddOnMutationVariables>;

/**
 * __useUpdateAddOnMutation__
 *
 * To run a mutation, you first call `useUpdateAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddOnMutation, { data, loading, error }] = useUpdateAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddOnMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddOnMutation, UpdateAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddOnMutation, UpdateAddOnMutationVariables>(UpdateAddOnDocument, options);
      }
export type UpdateAddOnMutationHookResult = ReturnType<typeof useUpdateAddOnMutation>;
export type UpdateAddOnMutationResult = Apollo.MutationResult<UpdateAddOnMutation>;
export type UpdateAddOnMutationOptions = Apollo.BaseMutationOptions<UpdateAddOnMutation, UpdateAddOnMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: ID!, $input: UpdateCustomerInput!) {
  updateCustomer(id: $id, input: $input) {
    ...AddCustomerDrawer
  }
}
    ${AddCustomerDrawerFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const GetInvoiceDetailsDocument = gql`
    query getInvoiceDetails($id: ID!) {
  invoice(id: $id) {
    id
    ...AllInvoiceDetailsForCustomerInvoiceDetails
  }
}
    ${AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc}`;

/**
 * __useGetInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>(GetInvoiceDetailsDocument, options);
      }
export function useGetInvoiceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>(GetInvoiceDetailsDocument, options);
        }
export type GetInvoiceDetailsQueryHookResult = ReturnType<typeof useGetInvoiceDetailsQuery>;
export type GetInvoiceDetailsLazyQueryHookResult = ReturnType<typeof useGetInvoiceDetailsLazyQuery>;
export type GetInvoiceDetailsQueryResult = Apollo.QueryResult<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>;
export const RefreshInvoiceDocument = gql`
    mutation refreshInvoice($id: ID!) {
  refreshDraftInvoice(id: $id) {
    id
    ...AllInvoiceDetailsForCustomerInvoiceDetails
  }
}
    ${AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc}`;
export type RefreshInvoiceMutationFn = Apollo.MutationFunction<RefreshInvoiceMutation, RefreshInvoiceMutationVariables>;

/**
 * __useRefreshInvoiceMutation__
 *
 * To run a mutation, you first call `useRefreshInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshInvoiceMutation, { data, loading, error }] = useRefreshInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RefreshInvoiceMutation, RefreshInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshInvoiceMutation, RefreshInvoiceMutationVariables>(RefreshInvoiceDocument, options);
      }
export type RefreshInvoiceMutationHookResult = ReturnType<typeof useRefreshInvoiceMutation>;
export type RefreshInvoiceMutationResult = Apollo.MutationResult<RefreshInvoiceMutation>;
export type RefreshInvoiceMutationOptions = Apollo.BaseMutationOptions<RefreshInvoiceMutation, RefreshInvoiceMutationVariables>;
export const AddOnsDocument = gql`
    query addOns($page: Int, $limit: Int, $searchTerm: String) {
  plans(page: $page, limit: $limit, query: $searchTerm, category: ADDONS) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      id
      ...PlanItem
    }
  }
}
    ${PlanItemFragmentDoc}`;

/**
 * __useAddOnsQuery__
 *
 * To run a query within a React component, call `useAddOnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOnsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useAddOnsQuery(baseOptions?: Apollo.QueryHookOptions<AddOnsQuery, AddOnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOnsQuery, AddOnsQueryVariables>(AddOnsDocument, options);
      }
export function useAddOnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOnsQuery, AddOnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOnsQuery, AddOnsQueryVariables>(AddOnsDocument, options);
        }
export type AddOnsQueryHookResult = ReturnType<typeof useAddOnsQuery>;
export type AddOnsLazyQueryHookResult = ReturnType<typeof useAddOnsLazyQuery>;
export type AddOnsQueryResult = Apollo.QueryResult<AddOnsQuery, AddOnsQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($id: ID!) {
  customer(id: $id) {
    id
    firstName
    lastName
    email
    sale {
      id
      firstName
      lastName
      email
    }
    phone
    addressLine1
    addressLine2
    state
    country
    city
    zipcode
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query getSubscriptions($page: Int, $limit: Int, $customerId: ID!, $category: ProductCategory!) {
  subscriptions(
    page: $page
    limit: $limit
    customerId: $customerId
    category: $category
  ) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      id
      createdAt
      isTrial
      startAt
      plan {
        id
        name
        code
        amountCents
        defaultSystem
      }
    }
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      customerId: // value for 'customerId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const GetCustomerDraftInvoicesDocument = gql`
    query getCustomerDraftInvoices($customerId: ID!, $limit: Int, $page: Int, $status: InvoiceStatus, $searchTerm: String) {
  invoices(
    customerId: $customerId
    limit: $limit
    page: $page
    status: $status
    query: $searchTerm
  ) {
    ...InvoiceForInvoiceList
  }
}
    ${InvoiceForInvoiceListFragmentDoc}`;

/**
 * __useGetCustomerDraftInvoicesQuery__
 *
 * To run a query within a React component, call `useGetCustomerDraftInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDraftInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDraftInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCustomerDraftInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>(GetCustomerDraftInvoicesDocument, options);
      }
export function useGetCustomerDraftInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>(GetCustomerDraftInvoicesDocument, options);
        }
export type GetCustomerDraftInvoicesQueryHookResult = ReturnType<typeof useGetCustomerDraftInvoicesQuery>;
export type GetCustomerDraftInvoicesLazyQueryHookResult = ReturnType<typeof useGetCustomerDraftInvoicesLazyQuery>;
export type GetCustomerDraftInvoicesQueryResult = Apollo.QueryResult<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>;
export const GetCustomerInfosForDraftInvoicesListDocument = gql`
    query getCustomerInfosForDraftInvoicesList($customerId: ID!, $status: InvoiceStatus) {
  customer(id: $customerId) {
    id
    firstName
    lastName
  }
  invoices(customerId: $customerId, status: $status, page: 1, limit: 1, query: "") {
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useGetCustomerInfosForDraftInvoicesListQuery__
 *
 * To run a query within a React component, call `useGetCustomerInfosForDraftInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInfosForDraftInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInfosForDraftInvoicesListQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCustomerInfosForDraftInvoicesListQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerInfosForDraftInvoicesListQuery, GetCustomerInfosForDraftInvoicesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerInfosForDraftInvoicesListQuery, GetCustomerInfosForDraftInvoicesListQueryVariables>(GetCustomerInfosForDraftInvoicesListDocument, options);
      }
export function useGetCustomerInfosForDraftInvoicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerInfosForDraftInvoicesListQuery, GetCustomerInfosForDraftInvoicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerInfosForDraftInvoicesListQuery, GetCustomerInfosForDraftInvoicesListQueryVariables>(GetCustomerInfosForDraftInvoicesListDocument, options);
        }
export type GetCustomerInfosForDraftInvoicesListQueryHookResult = ReturnType<typeof useGetCustomerInfosForDraftInvoicesListQuery>;
export type GetCustomerInfosForDraftInvoicesListLazyQueryHookResult = ReturnType<typeof useGetCustomerInfosForDraftInvoicesListLazyQuery>;
export type GetCustomerInfosForDraftInvoicesListQueryResult = Apollo.QueryResult<GetCustomerInfosForDraftInvoicesListQuery, GetCustomerInfosForDraftInvoicesListQueryVariables>;
export const CustomersDocument = gql`
    query customers($page: Int!, $limit: Int!, $searchTerm: String) {
  customers(page: $page, limit: $limit, query: $searchTerm) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      ...CustomerItem
    }
  }
}
    ${CustomerItemFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const CustomerStatisticsDocument = gql`
    query customerStatistics($page: Int!, $limit: Int!, $type: FilterCustomer!, $currentTime: String!) {
  customerStatistics(
    page: $page
    limit: $limit
    type: $type
    currentTime: $currentTime
  ) {
    ... on CustomCustomerConnection {
      metadata {
        currentPage
        totalCount
        totalPages
      }
      results {
        customer {
          _id
          email
          firstName
          lastName
          createdAt
        }
        subscriptions {
          createdAt
          endAt
          isTrial
          plan {
            name
            category
          }
        }
      }
    }
    ... on CustomerConnection {
      metadata {
        currentPage
        totalCount
        totalPages
      }
      results {
        _id
        email
        firstName
        lastName
        createdAt
        sale {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerStatisticsQuery__
 *
 * To run a query within a React component, call `useCustomerStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerStatisticsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      type: // value for 'type'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useCustomerStatisticsQuery(baseOptions: Apollo.QueryHookOptions<CustomerStatisticsQuery, CustomerStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerStatisticsQuery, CustomerStatisticsQueryVariables>(CustomerStatisticsDocument, options);
      }
export function useCustomerStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerStatisticsQuery, CustomerStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerStatisticsQuery, CustomerStatisticsQueryVariables>(CustomerStatisticsDocument, options);
        }
export type CustomerStatisticsQueryHookResult = ReturnType<typeof useCustomerStatisticsQuery>;
export type CustomerStatisticsLazyQueryHookResult = ReturnType<typeof useCustomerStatisticsLazyQuery>;
export type CustomerStatisticsQueryResult = Apollo.QueryResult<CustomerStatisticsQuery, CustomerStatisticsQueryVariables>;
export const GetListInvoiceByTypeDocument = gql`
    query getListInvoiceByType($page: Int!, $limit: Int!, $type: FilterInvoice!, $currentTime: String!) {
  getListInvoiceByType(
    page: $page
    limit: $limit
    type: $type
    currentTime: $currentTime
  ) {
    metadata {
      currentPage
      totalCount
      totalPages
    }
    results {
      _id
      status
      paymentAt
      endAt
      number
      customer {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useGetListInvoiceByTypeQuery__
 *
 * To run a query within a React component, call `useGetListInvoiceByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListInvoiceByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListInvoiceByTypeQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      type: // value for 'type'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useGetListInvoiceByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetListInvoiceByTypeQuery, GetListInvoiceByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListInvoiceByTypeQuery, GetListInvoiceByTypeQueryVariables>(GetListInvoiceByTypeDocument, options);
      }
export function useGetListInvoiceByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListInvoiceByTypeQuery, GetListInvoiceByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListInvoiceByTypeQuery, GetListInvoiceByTypeQueryVariables>(GetListInvoiceByTypeDocument, options);
        }
export type GetListInvoiceByTypeQueryHookResult = ReturnType<typeof useGetListInvoiceByTypeQuery>;
export type GetListInvoiceByTypeLazyQueryHookResult = ReturnType<typeof useGetListInvoiceByTypeLazyQuery>;
export type GetListInvoiceByTypeQueryResult = Apollo.QueryResult<GetListInvoiceByTypeQuery, GetListInvoiceByTypeQueryVariables>;
export const GetinviteDocument = gql`
    query getinvite($token: String!) {
  invite(token: $token) {
    id
    firstName
    lastName
    role
    email
  }
}
    `;

/**
 * __useGetinviteQuery__
 *
 * To run a query within a React component, call `useGetinviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetinviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetinviteQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetinviteQuery(baseOptions: Apollo.QueryHookOptions<GetinviteQuery, GetinviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetinviteQuery, GetinviteQueryVariables>(GetinviteDocument, options);
      }
export function useGetinviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetinviteQuery, GetinviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetinviteQuery, GetinviteQueryVariables>(GetinviteDocument, options);
        }
export type GetinviteQueryHookResult = ReturnType<typeof useGetinviteQuery>;
export type GetinviteLazyQueryHookResult = ReturnType<typeof useGetinviteLazyQuery>;
export type GetinviteQueryResult = Apollo.QueryResult<GetinviteQuery, GetinviteQueryVariables>;
export const AcceptInviteDocument = gql`
    mutation acceptInvite($input: AcceptInviteInput!) {
  acceptInvite(input: $input) {
    user {
      id
    }
    accessToken
    refreshToken
  }
}
    `;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, options);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const InvoicesListDocument = gql`
    query invoicesList($limit: Int, $page: Int, $status: InvoiceStatus, $searchTerm: String, $paymentStatus: [InvoicePaymentStatus!], $invoiceType: InvoiceType) {
  invoices(
    limit: $limit
    page: $page
    status: $status
    query: $searchTerm
    paymentStatus: $paymentStatus
    invoiceType: $invoiceType
  ) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      id
      ...InvoiceListItem
    }
  }
}
    ${InvoiceListItemFragmentDoc}`;

/**
 * __useInvoicesListQuery__
 *
 * To run a query within a React component, call `useInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      searchTerm: // value for 'searchTerm'
 *      paymentStatus: // value for 'paymentStatus'
 *      invoiceType: // value for 'invoiceType'
 *   },
 * });
 */
export function useInvoicesListQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesListQuery, InvoicesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesListQuery, InvoicesListQueryVariables>(InvoicesListDocument, options);
      }
export function useInvoicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesListQuery, InvoicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesListQuery, InvoicesListQueryVariables>(InvoicesListDocument, options);
        }
export type InvoicesListQueryHookResult = ReturnType<typeof useInvoicesListQuery>;
export type InvoicesListLazyQueryHookResult = ReturnType<typeof useInvoicesListLazyQuery>;
export type InvoicesListQueryResult = Apollo.QueryResult<InvoicesListQuery, InvoicesListQueryVariables>;
export const PlansDocument = gql`
    query plans($page: Int!, $limit: Int!, $searchTerm: String) {
  plans(page: $page, limit: $limit, query: $searchTerm, category: BASE) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      ...PlanItem
    }
  }
}
    ${PlanItemFragmentDoc}`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function usePlansQuery(baseOptions: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const GetRequestTopUpDocument = gql`
    query getRequestTopUp($id: String!) {
  getRequestTopUp(id: $id) {
    _id
    amount
    status
    transactionType
    createdAt
    note
    wallet {
      name
      status
      balance
      customer {
        firstName
        lastName
        email
        phone
      }
    }
    seller {
      _id
      firstName
      lastName
      email
    }
    verifier {
      _id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetRequestTopUpQuery__
 *
 * To run a query within a React component, call `useGetRequestTopUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestTopUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestTopUpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequestTopUpQuery(baseOptions: Apollo.QueryHookOptions<GetRequestTopUpQuery, GetRequestTopUpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestTopUpQuery, GetRequestTopUpQueryVariables>(GetRequestTopUpDocument, options);
      }
export function useGetRequestTopUpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestTopUpQuery, GetRequestTopUpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestTopUpQuery, GetRequestTopUpQueryVariables>(GetRequestTopUpDocument, options);
        }
export type GetRequestTopUpQueryHookResult = ReturnType<typeof useGetRequestTopUpQuery>;
export type GetRequestTopUpLazyQueryHookResult = ReturnType<typeof useGetRequestTopUpLazyQuery>;
export type GetRequestTopUpQueryResult = Apollo.QueryResult<GetRequestTopUpQuery, GetRequestTopUpQueryVariables>;
export const RequestTopUpsDocument = gql`
    query requestTopUps($page: Int!, $limit: Int!, $status: WalletTransactionStatusEnum, $searchTerm: String) {
  requestTopUps(page: $page, limit: $limit, status: $status, keyword: $searchTerm) {
    metadata {
      currentPage
      totalCount
      totalPages
    }
    results {
      ...RequestTopUpListItem
    }
  }
}
    ${RequestTopUpListItemFragmentDoc}`;

/**
 * __useRequestTopUpsQuery__
 *
 * To run a query within a React component, call `useRequestTopUpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTopUpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTopUpsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useRequestTopUpsQuery(baseOptions: Apollo.QueryHookOptions<RequestTopUpsQuery, RequestTopUpsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestTopUpsQuery, RequestTopUpsQueryVariables>(RequestTopUpsDocument, options);
      }
export function useRequestTopUpsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestTopUpsQuery, RequestTopUpsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestTopUpsQuery, RequestTopUpsQueryVariables>(RequestTopUpsDocument, options);
        }
export type RequestTopUpsQueryHookResult = ReturnType<typeof useRequestTopUpsQuery>;
export type RequestTopUpsLazyQueryHookResult = ReturnType<typeof useRequestTopUpsLazyQuery>;
export type RequestTopUpsQueryResult = Apollo.QueryResult<RequestTopUpsQuery, RequestTopUpsQueryVariables>;
export const LoginUserDocument = gql`
    mutation loginUser($input: LoginUserInput!) {
  loginUser(input: $input) {
    user {
      id
    }
    accessToken
    refreshToken
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const UserDocument = gql`
    query user($id: String!) {
  user(id: $id) {
    email
    firstName
    lastName
    role
    phone
    facebook
    zalo
    telegram
    skype
    viber
    role
    createdAt
    enabled
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($page: Int!, $limit: Int!, $searchTerm: String) {
  users(page: $page, limit: $limit, query: $searchTerm) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    results {
      ...MembershipItem
    }
  }
}
    ${MembershipItemFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;