import { makeVar } from '@apollo/client'

import { getItemFromLS, getItemFromSS } from '../cacheUtils'

export const AUTH_TOKEN_LS_KEY = 'authToken'
export const AUTH_REFRESH_TOKEN_LS_KEY = 'authRefreshToken'

export const isAuthenticatedVar = makeVar<boolean>(!!getItemFromSS(AUTH_TOKEN_LS_KEY))
export const isAuthenticatedRefreshVar = makeVar<boolean>(
  !!getItemFromLS(AUTH_REFRESH_TOKEN_LS_KEY)
)

export * from './authTokenVar'
export * from './envGlobalVar'
export * from './internationalizationVar'
export * from './locationHistoryVar'
export * from './overwritePlanVar'
export * from './toastVar'
