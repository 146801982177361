import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: any
  /** An ISO 8601-encoded date */
  ISO8601Date: any
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any
  /** Represents untyped JSON */
  JSON: any
}

/** Autogenerated input type of AcceptInvite */
export type AcceptInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  password: Scalars['String']
  /** Uniq token of the Invite */
  token: Scalars['String']
}

/** Autogenerated input type of AddGocardlessPaymentProvider */
export type AddGocardlessPaymentProviderInput = {
  accessCode?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

export type AddOn = {
  __typename?: 'AddOn'
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  appliedAddOnsCount: Scalars['Int']
  code: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  /** Number of customers using this add-on */
  customerCount: Scalars['Int']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  organization?: Maybe<Organization>
  updatedAt: Scalars['ISO8601DateTime']
}

export type AddOnCollection = {
  __typename?: 'AddOnCollection'
  collection: Array<AddOn>
  metadata: CollectionMetadata
}

export type AddOnDetails = {
  __typename?: 'AddOnDetails'
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  appliedAddOnsCount: Scalars['Int']
  code: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  /** Number of customers using this add-on */
  customerCount: Scalars['Int']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  organization?: Maybe<Organization>
  updatedAt: Scalars['ISO8601DateTime']
}

/** Autogenerated input type of AddStripePaymentProvider */
export type AddStripePaymentProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  createCustomers?: InputMaybe<Scalars['Boolean']>
  secretKey?: InputMaybe<Scalars['String']>
}

export enum AggregationTypeEnum {
  CountAgg = 'count_agg',
  MaxAgg = 'max_agg',
  RecurringCountAgg = 'recurring_count_agg',
  SumAgg = 'sum_agg',
  UniqueCountAgg = 'unique_count_agg',
}

export type AppliedAddOn = {
  __typename?: 'AppliedAddOn'
  addOn: AddOn
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
}

export type AppliedCoupon = {
  __typename?: 'AppliedCoupon'
  amountCents?: Maybe<Scalars['BigInt']>
  amountCentsRemaining?: Maybe<Scalars['BigInt']>
  amountCurrency?: Maybe<CurrencyEnum>
  coupon: Coupon
  createdAt: Scalars['ISO8601DateTime']
  frequency: CouponFrequency
  frequencyDuration?: Maybe<Scalars['Int']>
  frequencyDurationRemaining?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  percentageRate?: Maybe<Scalars['Float']>
  terminatedAt: Scalars['ISO8601DateTime']
}

export type BillableMetric = {
  __typename?: 'BillableMetric'
  activeSubscriptionsCount: Scalars['Int']
  aggregationType: AggregationTypeEnum
  code: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  description?: Maybe<Scalars['String']>
  draftInvoicesCount: Scalars['Int']
  fieldName?: Maybe<Scalars['String']>
  flatGroups?: Maybe<Array<Group>>
  group?: Maybe<Scalars['JSON']>
  id: Scalars['ID']
  name: Scalars['String']
  organization?: Maybe<Organization>
  subscriptionsCount: Scalars['Int']
  updatedAt: Scalars['ISO8601DateTime']
}

export type BillableMetricCollection = {
  __typename?: 'BillableMetricCollection'
  collection: Array<BillableMetric>
  metadata: CollectionMetadata
}

export type BillableMetricDetail = {
  __typename?: 'BillableMetricDetail'
  activeSubscriptionsCount: Scalars['Int']
  aggregationType: AggregationTypeEnum
  code: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  description?: Maybe<Scalars['String']>
  draftInvoicesCount: Scalars['Int']
  fieldName?: Maybe<Scalars['String']>
  flatGroups?: Maybe<Array<Group>>
  group?: Maybe<Scalars['JSON']>
  id: Scalars['ID']
  name: Scalars['String']
  organization?: Maybe<Organization>
  subscriptionsCount: Scalars['Int']
  updatedAt: Scalars['ISO8601DateTime']
}

export enum BillingTimeEnum {
  Anniversary = 'anniversary',
  Calendar = 'calendar',
}

export type Charge = {
  __typename?: 'Charge'
  billableMetric: BillableMetric
  chargeModel: ChargeModelEnum
  createdAt: Scalars['ISO8601DateTime']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  groupProperties?: Maybe<Array<GroupProperties>>
  id: Scalars['ID']
  properties?: Maybe<Properties>
  updatedAt: Scalars['ISO8601DateTime']
}

export type ChargeInput = {
  billableMetricId: Scalars['ID']
  chargeModel: ChargeModelEnum
  groupProperties?: InputMaybe<Array<GroupPropertiesInput>>
  id?: InputMaybe<Scalars['ID']>
  properties?: InputMaybe<PropertiesInput>
}

export enum ChargeModelEnum {
  Graduated = 'graduated',
  Package = 'package',
  Percentage = 'percentage',
  Standard = 'standard',
  Volume = 'volume',
}

export type ChargeUsage = {
  __typename?: 'ChargeUsage'
  amountCents: Scalars['BigInt']
  billableMetric: BillableMetric
  charge: Charge
  groups?: Maybe<Array<GroupUsage>>
  units: Scalars['Float']
}

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata'
  currentPage: Scalars['Int']
  limitValue: Scalars['Int']
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
}

export enum CountryCode {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia (Plurinational State of) */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom of Great Britain and Northern Ireland */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran (Islamic Republic of) */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea (Democratic People's Republic of) */
  Kp = 'KP',
  /** Korea (Republic of) */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova (Republic of) */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela (Bolivarian Republic of) */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export type Coupon = {
  __typename?: 'Coupon'
  amountCents?: Maybe<Scalars['BigInt']>
  amountCurrency?: Maybe<CurrencyEnum>
  appliedCouponsCount: Scalars['Int']
  code?: Maybe<Scalars['String']>
  couponType: CouponTypeEnum
  createdAt: Scalars['ISO8601DateTime']
  /** Number of customers using this coupon */
  customerCount: Scalars['Int']
  expiration: CouponExpiration
  expirationAt?: Maybe<Scalars['ISO8601DateTime']>
  frequency: CouponFrequency
  frequencyDuration?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  limitedPlans: Scalars['Boolean']
  name: Scalars['String']
  organization?: Maybe<Organization>
  percentageRate?: Maybe<Scalars['Float']>
  plans?: Maybe<Array<Plan>>
  reusable: Scalars['Boolean']
  status: CouponStatusEnum
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
}

export type CouponCollection = {
  __typename?: 'CouponCollection'
  collection: Array<Coupon>
  metadata: CollectionMetadata
}

export type CouponDetails = {
  __typename?: 'CouponDetails'
  amountCents?: Maybe<Scalars['BigInt']>
  amountCurrency?: Maybe<CurrencyEnum>
  appliedCouponsCount: Scalars['Int']
  code?: Maybe<Scalars['String']>
  couponType: CouponTypeEnum
  createdAt: Scalars['ISO8601DateTime']
  /** Number of customers using this coupon */
  customerCount: Scalars['Int']
  expiration: CouponExpiration
  expirationAt?: Maybe<Scalars['ISO8601DateTime']>
  frequency: CouponFrequency
  frequencyDuration?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  limitedPlans: Scalars['Boolean']
  name: Scalars['String']
  organization?: Maybe<Organization>
  percentageRate?: Maybe<Scalars['Float']>
  plans?: Maybe<Array<Plan>>
  reusable: Scalars['Boolean']
  status: CouponStatusEnum
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
}

export enum CouponExpiration {
  NoExpiration = 'no_expiration',
  TimeLimit = 'time_limit',
}

export enum CouponFrequency {
  Forever = 'forever',
  Once = 'once',
  Recurring = 'recurring',
}

export enum CouponStatusEnum {
  Active = 'active',
  Terminated = 'terminated',
}

export enum CouponTypeEnum {
  FixedAmount = 'fixed_amount',
  Percentage = 'percentage',
}

/** Autogenerated input type of CreateAddOn */
export type CreateAddOnInput = {
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

/** Autogenerated input type of CreateAppliedAddOn */
export type CreateAppliedAddOnInput = {
  addOnId: Scalars['ID']
  amountCents?: InputMaybe<Scalars['BigInt']>
  amountCurrency?: InputMaybe<CurrencyEnum>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  customerId: Scalars['ID']
}

/** Autogenerated input type of CreateAppliedCoupon */
export type CreateAppliedCouponInput = {
  amountCents?: InputMaybe<Scalars['BigInt']>
  amountCurrency?: InputMaybe<CurrencyEnum>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  couponId: Scalars['ID']
  customerId: Scalars['ID']
  frequency?: InputMaybe<CouponFrequency>
  frequencyDuration?: InputMaybe<Scalars['Int']>
  percentageRate?: InputMaybe<Scalars['Float']>
}

/** Autogenerated input type of CreateBillableMetric */
export type CreateBillableMetricInput = {
  aggregationType: AggregationTypeEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  description: Scalars['String']
  fieldName?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['JSON']>
  name: Scalars['String']
}

/** Autogenerated input type of CreateCoupon */
export type CreateCouponInput = {
  amountCents?: InputMaybe<Scalars['BigInt']>
  amountCurrency?: InputMaybe<CurrencyEnum>
  appliesTo?: InputMaybe<LimitationInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponType: CouponTypeEnum
  expiration: CouponExpiration
  expirationAt?: InputMaybe<Scalars['ISO8601DateTime']>
  frequency: CouponFrequency
  frequencyDuration?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  percentageRate?: InputMaybe<Scalars['Float']>
  reusable?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated input type of CreateCreditNote */
export type CreateCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  creditAmountCents?: InputMaybe<Scalars['BigInt']>
  description?: InputMaybe<Scalars['String']>
  invoiceId: Scalars['ID']
  items: Array<CreditNoteItemInput>
  reason: CreditNoteReasonEnum
  refundAmountCents?: InputMaybe<Scalars['BigInt']>
}

/** Autogenerated input type of CreateCustomer */
export type CreateCustomerInput = {
  addressLine1?: InputMaybe<Scalars['String']>
  addressLine2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  country?: InputMaybe<CountryCode>
  currency?: InputMaybe<CurrencyEnum>
  email?: InputMaybe<Scalars['String']>
  externalId: Scalars['String']
  invoiceGracePeriod?: InputMaybe<Scalars['Int']>
  legalName?: InputMaybe<Scalars['String']>
  legalNumber?: InputMaybe<Scalars['String']>
  logoUrl?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  paymentProvider?: InputMaybe<ProviderTypeEnum>
  phone?: InputMaybe<Scalars['String']>
  providerCustomer?: InputMaybe<ProviderCustomerInput>
  state?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<TimezoneEnum>
  url?: InputMaybe<Scalars['String']>
  vatRate?: InputMaybe<Scalars['Float']>
  zipcode?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of CreateCustomerWallet */
export type CreateCustomerWalletInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  currency: CurrencyEnum
  customerId: Scalars['ID']
  expirationAt?: InputMaybe<Scalars['ISO8601DateTime']>
  grantedCredits: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  paidCredits: Scalars['String']
  rateAmount: Scalars['String']
}

/** Autogenerated input type of CreateCustomerWalletTransaction */
export type CreateCustomerWalletTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  grantedCredits: Scalars['String']
  paidCredits: Scalars['String']
  walletId: Scalars['ID']
}

/** Autogenerated input type of CreateInvite */
export type CreateInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
}

/** Autogenerated input type of CreatePlan */
export type CreatePlanInput = {
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  billChargesMonthly?: InputMaybe<Scalars['Boolean']>
  charges: Array<ChargeInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  interval: PlanInterval
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['ID']>
  payInAdvance: Scalars['Boolean']
  trialPeriod?: InputMaybe<Scalars['Float']>
}

/** Autogenerated input type of CreateSubscription */
export type CreateSubscriptionInput = {
  billingTime: BillingTimeEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  customerId: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  planId: Scalars['ID']
  subscriptionAt?: InputMaybe<Scalars['ISO8601DateTime']>
  subscriptionId?: InputMaybe<Scalars['ID']>
}

export type CreditNote = {
  __typename?: 'CreditNote'
  balanceAmountCents: Scalars['BigInt']
  balanceAmountCurrency: CurrencyEnum
  /** Check if credit note can be voided */
  canBeVoided: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  creditAmountCents: Scalars['BigInt']
  creditAmountCurrency: CurrencyEnum
  creditStatus?: Maybe<CreditNoteCreditStatusEnum>
  customer: Customer
  description?: Maybe<Scalars['String']>
  fileUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invoice?: Maybe<Invoice>
  issuingDate: Scalars['ISO8601Date']
  items: Array<CreditNoteItem>
  number: Scalars['String']
  reason: CreditNoteReasonEnum
  refundAmountCents: Scalars['BigInt']
  refundAmountCurrency: CurrencyEnum
  refundStatus?: Maybe<CreditNoteRefundStatusEnum>
  refundedAt?: Maybe<Scalars['ISO8601DateTime']>
  sequentialId: Scalars['ID']
  subTotalVatExcludedAmountCents: Scalars['BigInt']
  subTotalVatExcludedAmountCurrency: CurrencyEnum
  totalAmountCents: Scalars['BigInt']
  totalAmountCurrency: CurrencyEnum
  updatedAt: Scalars['ISO8601DateTime']
  vatAmountCents: Scalars['BigInt']
  vatAmountCurrency: CurrencyEnum
  voidedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type CreditNoteCollection = {
  __typename?: 'CreditNoteCollection'
  collection: Array<CreditNote>
  metadata: CollectionMetadata
}

export enum CreditNoteCreditStatusEnum {
  Available = 'available',
  Consumed = 'consumed',
  Voided = 'voided',
}

export type CreditNoteItem = {
  __typename?: 'CreditNoteItem'
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  createdAt: Scalars['ISO8601DateTime']
  fee: Fee
  id: Scalars['ID']
  totalAmountCents: Scalars['BigInt']
  totalAmountCurrency: CurrencyEnum
  vatAmountCents: Scalars['BigInt']
  vatAmountCurrency: CurrencyEnum
}

export type CreditNoteItemInput = {
  amountCents: Scalars['BigInt']
  feeId: Scalars['ID']
}

export enum CreditNoteReasonEnum {
  DuplicatedCharge = 'duplicated_charge',
  FraudulentCharge = 'fraudulent_charge',
  OrderCancellation = 'order_cancellation',
  OrderChange = 'order_change',
  Other = 'other',
  ProductUnsatisfactory = 'product_unsatisfactory',
}

export enum CreditNoteRefundStatusEnum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}

export enum CurrencyEnum {
  /** United Arab Emirates Dirham */
  Aed = 'AED',
  /** Afghan Afghani */
  Afn = 'AFN',
  /** Albanian Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Gulden */
  Ang = 'ANG',
  /** Angolan Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijani Manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark */
  Bam = 'BAM',
  /** Barbadian Dollar */
  Bbd = 'BBD',
  /** Bangladeshi Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Burundian Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Bolivian Boliviano */
  Bob = 'BOB',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Botswana Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble */
  Byn = 'BYN',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** Swiss Franc */
  Chf = 'CHF',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Chinese Renminbi Yuan */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Costa Rican Colón */
  Crc = 'CRC',
  /** Cape Verdean Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djiboutian Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fijian Dollar */
  Fjd = 'FJD',
  /** Falkland Pound */
  Fkp = 'FKP',
  /** British Pound */
  Gbp = 'GBP',
  /** Georgian Lari */
  Gel = 'GEL',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Gambian Dalasi */
  Gmd = 'GMD',
  /** Guinean Franc */
  Gnf = 'GNF',
  /** Guatemalan Quetzal */
  Gtq = 'GTQ',
  /** Guyanese Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Honduran Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian Gourde */
  Htg = 'HTG',
  /** Hungarian Forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli New Sheqel */
  Ils = 'ILS',
  /** Indian Rupee */
  Inr = 'INR',
  /** Icelandic Króna */
  Isk = 'ISK',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Japanese Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Kyrgyzstani Som */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc */
  Kmf = 'KMF',
  /** South Korean Won */
  Krw = 'KRW',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Kazakhstani Tenge */
  Kzt = 'KZT',
  /** Lao Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lankan Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho Loti */
  Lsl = 'LSL',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Macedonian Denar */
  Mkd = 'MKD',
  /** Myanmar Kyat */
  Mmk = 'MMK',
  /** Mongolian Tögrög */
  Mnt = 'MNT',
  /** Macanese Pataca */
  Mop = 'MOP',
  /** Mauritanian Ouguiya */
  Mro = 'MRO',
  /** Mauritian Rupee */
  Mur = 'MUR',
  /** Maldivian Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Panamanian Balboa */
  Pab = 'PAB',
  /** Peruvian Sol */
  Pen = 'PEN',
  /** Papua New Guinean Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistani Rupee */
  Pkr = 'PKR',
  /** Polish Złoty */
  Pln = 'PLN',
  /** Paraguayan Guaraní */
  Pyg = 'PYG',
  /** Qatari Riyal */
  Qar = 'QAR',
  /** Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwandan Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychellois Rupee */
  Scr = 'SCR',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helenian Pound */
  Shp = 'SHP',
  /** Sierra Leonean Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinamese Dollar */
  Srd = 'SRD',
  /** São Tomé and Príncipe Dobra */
  Std = 'STD',
  /** Swazi Lilangeni */
  Szl = 'SZL',
  /** Thai Baht */
  Thb = 'THB',
  /** Tajikistani Somoni */
  Tjs = 'TJS',
  /** Tongan Paʻanga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia */
  Uah = 'UAH',
  /** Ugandan Shilling */
  Ugx = 'UGX',
  /** United States Dollar */
  Usd = 'USD',
  /** Uruguayan Peso */
  Uyu = 'UYU',
  /** Uzbekistan Som */
  Uzs = 'UZS',
  /** Vietnamese Đồng */
  Vnd = 'VND',
  /** Vanuatu Vatu */
  Vuv = 'VUV',
  /** Samoan Tala */
  Wst = 'WST',
  /** Central African Cfa Franc */
  Xaf = 'XAF',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** West African Cfa Franc */
  Xof = 'XOF',
  /** Cfp Franc */
  Xpf = 'XPF',
  /** Yemeni Rial */
  Yer = 'YER',
  /** South African Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmw = 'ZMW',
}

export type CurrentVersion = {
  __typename?: 'CurrentVersion'
  githubUrl: Scalars['String']
  number: Scalars['String']
}

export type Customer = {
  __typename?: 'Customer'
  /** Number of active subscriptions per customer */
  activeSubscriptionCount: Scalars['Int']
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  applicableTimezone: TimezoneEnum
  /** Check if customer attributes are editable */
  canEditAttributes: Scalars['Boolean']
  city?: Maybe<Scalars['String']>
  country?: Maybe<CountryCode>
  createdAt: Scalars['ISO8601DateTime']
  /** Credit notes credits balance available per customer */
  creditNotesBalanceAmountCents: Scalars['BigInt']
  /** Number of available credits from credit notes per customer */
  creditNotesCreditsAvailableCount: Scalars['Int']
  currency?: Maybe<CurrencyEnum>
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  email?: Maybe<Scalars['String']>
  externalId: Scalars['String']
  /** Define if a customer has an active wallet */
  hasActiveWallet: Scalars['Boolean']
  /** Define if a customer has any credit note */
  hasCreditNotes: Scalars['Boolean']
  id: Scalars['ID']
  invoiceGracePeriod?: Maybe<Scalars['Int']>
  legalName?: Maybe<Scalars['String']>
  legalNumber?: Maybe<Scalars['String']>
  logoUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  paymentProvider?: Maybe<ProviderTypeEnum>
  phone?: Maybe<Scalars['String']>
  providerCustomer?: Maybe<ProviderCustomer>
  sequentialId: Scalars['String']
  slug: Scalars['String']
  state?: Maybe<Scalars['String']>
  subscriptions?: Maybe<Array<Subscription>>
  timezone?: Maybe<TimezoneEnum>
  updatedAt: Scalars['ISO8601DateTime']
  url?: Maybe<Scalars['String']>
  vatRate?: Maybe<Scalars['Float']>
  zipcode?: Maybe<Scalars['String']>
}

export type CustomerCollection = {
  __typename?: 'CustomerCollection'
  collection: Array<Customer>
  metadata: CollectionMetadata
}

export type CustomerDetails = {
  __typename?: 'CustomerDetails'
  /** Number of active subscriptions per customer */
  activeSubscriptionCount: Scalars['Int']
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  applicableTimezone: TimezoneEnum
  appliedAddOns?: Maybe<Array<AppliedAddOn>>
  appliedCoupons?: Maybe<Array<AppliedCoupon>>
  /** Check if customer attributes are editable */
  canEditAttributes: Scalars['Boolean']
  city?: Maybe<Scalars['String']>
  country?: Maybe<CountryCode>
  createdAt: Scalars['ISO8601DateTime']
  creditNotes?: Maybe<Array<CreditNote>>
  /** Credit notes credits balance available per customer */
  creditNotesBalanceAmountCents: Scalars['BigInt']
  /** Number of available credits from credit notes per customer */
  creditNotesCreditsAvailableCount: Scalars['Int']
  currency?: Maybe<CurrencyEnum>
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  email?: Maybe<Scalars['String']>
  externalId: Scalars['String']
  /** Define if a customer has an active wallet */
  hasActiveWallet: Scalars['Boolean']
  /** Define if a customer has any credit note */
  hasCreditNotes: Scalars['Boolean']
  id: Scalars['ID']
  invoiceGracePeriod?: Maybe<Scalars['Int']>
  invoices?: Maybe<Array<Invoice>>
  legalName?: Maybe<Scalars['String']>
  legalNumber?: Maybe<Scalars['String']>
  logoUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  paymentProvider?: Maybe<ProviderTypeEnum>
  phone?: Maybe<Scalars['String']>
  providerCustomer?: Maybe<ProviderCustomer>
  sequentialId: Scalars['String']
  slug: Scalars['String']
  state?: Maybe<Scalars['String']>
  /** Query subscriptions of a customer */
  subscriptions: Array<Subscription>
  timezone?: Maybe<TimezoneEnum>
  updatedAt: Scalars['ISO8601DateTime']
  url?: Maybe<Scalars['String']>
  vatRate?: Maybe<Scalars['Float']>
  zipcode?: Maybe<Scalars['String']>
}

export type CustomerDetailsSubscriptionsArgs = {
  status?: InputMaybe<Array<StatusTypeEnum>>
}

export type CustomerUsage = {
  __typename?: 'CustomerUsage'
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  chargesUsage: Array<ChargeUsage>
  fromDatetime: Scalars['ISO8601DateTime']
  issuingDate: Scalars['ISO8601Date']
  toDatetime: Scalars['ISO8601DateTime']
  totalAmountCents: Scalars['BigInt']
  totalAmountCurrency: CurrencyEnum
  vatAmountCents: Scalars['BigInt']
  vatAmountCurrency: CurrencyEnum
}

/** Autogenerated input type of DestroyAddOn */
export type DestroyAddOnInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of DestroyAddOn */
export type DestroyAddOnPayload = {
  __typename?: 'DestroyAddOnPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DestroyBillableMetric */
export type DestroyBillableMetricInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
}

/** Autogenerated return type of DestroyBillableMetric */
export type DestroyBillableMetricPayload = {
  __typename?: 'DestroyBillableMetricPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DestroyCoupon */
export type DestroyCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of DestroyCoupon */
export type DestroyCouponPayload = {
  __typename?: 'DestroyCouponPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DestroyCustomer */
export type DestroyCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of DestroyCustomer */
export type DestroyCustomerPayload = {
  __typename?: 'DestroyCustomerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DestroyPaymentProvider */
export type DestroyPaymentProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of DestroyPaymentProvider */
export type DestroyPaymentProviderPayload = {
  __typename?: 'DestroyPaymentProviderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DestroyPlan */
export type DestroyPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of DestroyPlan */
export type DestroyPlanPayload = {
  __typename?: 'DestroyPlanPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DownloadCreditNote */
export type DownloadCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated input type of DownloadInvoice */
export type DownloadInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type Event = {
  __typename?: 'Event'
  apiClient?: Maybe<Scalars['String']>
  billableMetricName?: Maybe<Scalars['String']>
  code: Scalars['String']
  customerTimezone: TimezoneEnum
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  externalCustomerId: Scalars['String']
  externalSubscriptionId: Scalars['String']
  id: Scalars['ID']
  ipAddress?: Maybe<Scalars['String']>
  matchBillableMetric: Scalars['Boolean']
  matchCustomField: Scalars['Boolean']
  payload: Scalars['JSON']
  receivedAt: Scalars['ISO8601DateTime']
  timestamp?: Maybe<Scalars['ISO8601DateTime']>
  transactionId?: Maybe<Scalars['String']>
}

export type EventCollection = {
  __typename?: 'EventCollection'
  collection: Array<Event>
  metadata: CollectionMetadata
}

export type Fee = InvoiceItem & {
  __typename?: 'Fee'
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  charge?: Maybe<Charge>
  creditableAmountCents: Scalars['BigInt']
  eventsCount?: Maybe<Scalars['BigInt']>
  feeType: FeeTypesEnum
  group?: Maybe<Group>
  id: Scalars['ID']
  itemCode: Scalars['String']
  itemName: Scalars['String']
  itemType: Scalars['String']
  subscription?: Maybe<Subscription>
  units: Scalars['Float']
  vatAmountCents: Scalars['BigInt']
  vatAmountCurrency: CurrencyEnum
  vatRate?: Maybe<Scalars['Float']>
}

export enum FeeTypesEnum {
  AddOn = 'add_on',
  Charge = 'charge',
  Credit = 'credit',
  Subscription = 'subscription',
}

/** Autogenerated input type of FinalizeInvoice */
export type FinalizeInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type GocardlessProvider = {
  __typename?: 'GocardlessProvider'
  hasAccessToken: Scalars['Boolean']
  id: Scalars['ID']
  webhookSecret?: Maybe<Scalars['String']>
}

export type GraduatedRange = {
  __typename?: 'GraduatedRange'
  flatAmount: Scalars['String']
  fromValue: Scalars['Int']
  perUnitAmount: Scalars['String']
  toValue?: Maybe<Scalars['Int']>
}

export type GraduatedRangeInput = {
  flatAmount: Scalars['String']
  fromValue: Scalars['Int']
  perUnitAmount: Scalars['String']
  toValue?: InputMaybe<Scalars['Int']>
}

export type Group = {
  __typename?: 'Group'
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  key?: Maybe<Scalars['String']>
  value: Scalars['String']
}

export type GroupProperties = {
  __typename?: 'GroupProperties'
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>
  groupId: Scalars['ID']
  values: Properties
}

export type GroupPropertiesInput = {
  groupId: Scalars['ID']
  values: PropertiesInput
}

export type GroupUsage = {
  __typename?: 'GroupUsage'
  amountCents: Scalars['BigInt']
  id: Scalars['ID']
  key?: Maybe<Scalars['String']>
  units: Scalars['Float']
  value: Scalars['String']
}

export type Invite = {
  __typename?: 'Invite'
  acceptedAt: Scalars['ISO8601DateTime']
  email: Scalars['String']
  id: Scalars['ID']
  organization: Organization
  recipient: Membership
  revokedAt: Scalars['ISO8601DateTime']
  status: InviteStatusTypeEnum
  token: Scalars['String']
}

export type InviteCollection = {
  __typename?: 'InviteCollection'
  collection: Array<Invite>
  metadata: CollectionMetadata
}

export enum InviteStatusTypeEnum {
  Accepted = 'accepted',
  Pending = 'pending',
  Revoked = 'revoked',
}

export type Invoice = {
  __typename?: 'Invoice'
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  chargeAmountCents: Scalars['BigInt']
  couponTotalAmountCents: Scalars['BigInt']
  createdAt: Scalars['ISO8601DateTime']
  creditAmountCents: Scalars['BigInt']
  creditAmountCurrency: CurrencyEnum
  creditNoteTotalAmountCents: Scalars['BigInt']
  creditNotes?: Maybe<Array<CreditNote>>
  creditableAmountCents: Scalars['BigInt']
  customer: Customer
  fees?: Maybe<Array<Fee>>
  fileUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invoiceSubscriptions?: Maybe<Array<InvoiceSubscription>>
  invoiceType: InvoiceTypeEnum
  issuingDate: Scalars['ISO8601Date']
  legacy: Scalars['Boolean']
  number: Scalars['String']
  paymentStatus: InvoicePaymentStatusTypeEnum
  refundableAmountCents: Scalars['BigInt']
  sequentialId: Scalars['ID']
  status: InvoiceStatusTypeEnum
  subTotalVatExcludedAmountCents: Scalars['BigInt']
  subTotalVatIncludedAmountCents: Scalars['BigInt']
  subscriptions?: Maybe<Array<Subscription>>
  subtotalBeforePrepaidCredits: Scalars['String']
  totalAmountCents: Scalars['BigInt']
  totalAmountCurrency: CurrencyEnum
  updatedAt: Scalars['ISO8601DateTime']
  vatAmountCents: Scalars['BigInt']
  vatAmountCurrency: CurrencyEnum
  vatRate: Scalars['Float']
  walletTransactionAmountCents: Scalars['BigInt']
}

export type InvoiceCollection = {
  __typename?: 'InvoiceCollection'
  collection: Array<Invoice>
  metadata: CollectionMetadata
}

/** Invoice Item */
export type InvoiceItem = {
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  group?: Maybe<Group>
  id: Scalars['ID']
  itemCode: Scalars['String']
  itemName: Scalars['String']
  itemType: Scalars['String']
}

export enum InvoicePaymentStatusTypeEnum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}

export enum InvoiceStatusTypeEnum {
  Draft = 'draft',
  Finalized = 'finalized',
}

export type InvoiceSubscription = {
  __typename?: 'InvoiceSubscription'
  chargeAmountCents: Scalars['BigInt']
  fees?: Maybe<Array<Fee>>
  fromDatetime?: Maybe<Scalars['ISO8601DateTime']>
  invoice: Invoice
  subscription: Subscription
  subscriptionAmountCents: Scalars['BigInt']
  toDatetime?: Maybe<Scalars['ISO8601DateTime']>
  totalAmountCents: Scalars['BigInt']
}

export enum InvoiceTypeEnum {
  AddOn = 'add_on',
  Credit = 'credit',
  Subscription = 'subscription',
}

export enum LagoApiError {
  CouponIsNotReusable = 'coupon_is_not_reusable',
  CurrenciesDoesNotMatch = 'currencies_does_not_match',
  DoesNotMatchItemAmounts = 'does_not_match_item_amounts',
  EmailAlreadyUsed = 'email_already_used',
  ExpiredJwtToken = 'expired_jwt_token',
  Forbidden = 'forbidden',
  IncorrectLoginOrPassword = 'incorrect_login_or_password',
  InternalError = 'internal_error',
  InviteAlreadyExists = 'invite_already_exists',
  InviteNotFound = 'invite_not_found',
  NotFound = 'not_found',
  NotOrganizationMember = 'not_organization_member',
  PaymentProcessorIsCurrentlyHandlingPayment = 'payment_processor_is_currently_handling_payment',
  PlanNotFound = 'plan_not_found',
  PlanOverlapping = 'plan_overlapping',
  TokenEncodingError = 'token_encoding_error',
  Unauthorized = 'unauthorized',
  UnprocessableEntity = 'unprocessable_entity',
  UrlIsInvalid = 'url_is_invalid',
  UserAlreadyExists = 'user_already_exists',
  ValueAlreadyExist = 'value_already_exist',
  ValueIsInvalid = 'value_is_invalid',
  ValueIsOutOfRange = 'value_is_out_of_range',
}

export type LimitationInput = {
  planIds?: InputMaybe<Array<Scalars['ID']>>
}

export type LoginUser = {
  __typename?: 'LoginUser'
  token: Scalars['String']
  user: User
}

/** Autogenerated input type of LoginUser */
export type LoginUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  password: Scalars['String']
}

export type Membership = {
  __typename?: 'Membership'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  organization: Organization
  revokedAt: Scalars['ISO8601DateTime']
  role?: Maybe<Scalars['String']>
  status: MembershipStatus
  updatedAt: Scalars['ISO8601DateTime']
  user: User
}

export type MembershipCollection = {
  __typename?: 'MembershipCollection'
  collection: Array<Membership>
  metadata: CollectionMetadata
}

export enum MembershipStatus {
  Active = 'active',
  Revoked = 'revoked',
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Accepts a new Invite */
  acceptInvite?: Maybe<RegisterUser>
  /** Add or update Gocardless payment provider */
  addGocardlessPaymentProvider?: Maybe<GocardlessProvider>
  /** Add or update Stripe API keys to the organization */
  addStripePaymentProvider?: Maybe<StripeProvider>
  /** Creates a new add-on */
  createAddOn?: Maybe<AddOn>
  /** Assigns an add-on to a Customer */
  createAppliedAddOn?: Maybe<AppliedAddOn>
  /** Assigns a Coupon to a Customer */
  createAppliedCoupon?: Maybe<AppliedCoupon>
  /** Creates a new Billable metric */
  createBillableMetric?: Maybe<BillableMetric>
  /** Creates a new Coupon */
  createCoupon?: Maybe<Coupon>
  /** Creates a new Credit Note */
  createCreditNote?: Maybe<CreditNote>
  /** Creates a new customer */
  createCustomer?: Maybe<Customer>
  /** Creates a new Customer Wallet */
  createCustomerWallet?: Maybe<Wallet>
  /** Creates a new Customer Wallet Transaction */
  createCustomerWalletTransaction?: Maybe<WalletTransactionCollection>
  /** Creates a new Invite */
  createInvite?: Maybe<Invite>
  /** Creates a new Plan */
  createPlan?: Maybe<Plan>
  /** Create a new Subscription */
  createSubscription?: Maybe<Subscription>
  /** Deletes an add-on */
  destroyAddOn?: Maybe<DestroyAddOnPayload>
  /** Deletes a Billable metric */
  destroyBillableMetric?: Maybe<DestroyBillableMetricPayload>
  /** Deletes a coupon */
  destroyCoupon?: Maybe<DestroyCouponPayload>
  /** Delete a Customer */
  destroyCustomer?: Maybe<DestroyCustomerPayload>
  /** Destroy a payment provider */
  destroyPaymentProvider?: Maybe<DestroyPaymentProviderPayload>
  /** Deletes a Plan */
  destroyPlan?: Maybe<DestroyPlanPayload>
  /** Download a Credit Note PDF */
  downloadCreditNote?: Maybe<CreditNote>
  /** Download an Invoice PDF */
  downloadInvoice?: Maybe<Invoice>
  /** Finalize a draft invoice */
  finalizeInvoice?: Maybe<Invoice>
  /** Opens a session for an existing user */
  loginUser?: Maybe<LoginUser>
  /** Refresh a draft invoice */
  refreshInvoice?: Maybe<Invoice>
  /** Registers a new user and creates related organization */
  registerUser?: Maybe<RegisterUser>
  /** Retry all invoice payments */
  retryAllInvoicePayments?: Maybe<InvoiceCollection>
  /** Retry invoice payment */
  retryInvoicePayment?: Maybe<Invoice>
  /** Revokes a invite */
  revokeInvite?: Maybe<Invite>
  /** Revoke a membership */
  revokeMembership?: Maybe<Membership>
  /** Unassign a coupon from a customer */
  terminateAppliedCoupon?: Maybe<AppliedCoupon>
  /** Deletes a coupon */
  terminateCoupon?: Maybe<Coupon>
  /** Terminates a new Customer Wallet */
  terminateCustomerWallet?: Maybe<Wallet>
  /** Terminate a Subscription */
  terminateSubscription?: Maybe<Subscription>
  /** Update an existing add-on */
  updateAddOn?: Maybe<AddOn>
  /** Updates an existing Billable metric */
  updateBillableMetric?: Maybe<BillableMetric>
  /** Update an existing coupon */
  updateCoupon?: Maybe<Coupon>
  /** Updates an existing Credit Note */
  updateCreditNote?: Maybe<CreditNote>
  /** Updates an existing Customer */
  updateCustomer?: Maybe<Customer>
  /** Assign the invoice grace period to Customers */
  updateCustomerInvoiceGracePeriod?: Maybe<CustomerDetails>
  /** Assign the vat rate to Customers */
  updateCustomerVatRate?: Maybe<CustomerDetails>
  /** Updates a new Customer Wallet */
  updateCustomerWallet?: Maybe<Wallet>
  /** Updates an Organization */
  updateOrganization?: Maybe<Organization>
  /** Updates an existing Plan */
  updatePlan?: Maybe<Plan>
  /** Update a Subscription */
  updateSubscription?: Maybe<Subscription>
  /** Voids a Credit Note */
  voidCreditNote?: Maybe<CreditNote>
}

export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput
}

export type MutationAddGocardlessPaymentProviderArgs = {
  input: AddGocardlessPaymentProviderInput
}

export type MutationAddStripePaymentProviderArgs = {
  input: AddStripePaymentProviderInput
}

export type MutationCreateAddOnArgs = {
  input: CreateAddOnInput
}

export type MutationCreateAppliedAddOnArgs = {
  input: CreateAppliedAddOnInput
}

export type MutationCreateAppliedCouponArgs = {
  input: CreateAppliedCouponInput
}

export type MutationCreateBillableMetricArgs = {
  input: CreateBillableMetricInput
}

export type MutationCreateCouponArgs = {
  input: CreateCouponInput
}

export type MutationCreateCreditNoteArgs = {
  input: CreateCreditNoteInput
}

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput
}

export type MutationCreateCustomerWalletArgs = {
  input: CreateCustomerWalletInput
}

export type MutationCreateCustomerWalletTransactionArgs = {
  input: CreateCustomerWalletTransactionInput
}

export type MutationCreateInviteArgs = {
  input: CreateInviteInput
}

export type MutationCreatePlanArgs = {
  input: CreatePlanInput
}

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput
}

export type MutationDestroyAddOnArgs = {
  input: DestroyAddOnInput
}

export type MutationDestroyBillableMetricArgs = {
  input: DestroyBillableMetricInput
}

export type MutationDestroyCouponArgs = {
  input: DestroyCouponInput
}

export type MutationDestroyCustomerArgs = {
  input: DestroyCustomerInput
}

export type MutationDestroyPaymentProviderArgs = {
  input: DestroyPaymentProviderInput
}

export type MutationDestroyPlanArgs = {
  input: DestroyPlanInput
}

export type MutationDownloadCreditNoteArgs = {
  input: DownloadCreditNoteInput
}

export type MutationDownloadInvoiceArgs = {
  input: DownloadInvoiceInput
}

export type MutationFinalizeInvoiceArgs = {
  input: FinalizeInvoiceInput
}

export type MutationLoginUserArgs = {
  input: LoginUserInput
}

export type MutationRefreshInvoiceArgs = {
  input: RefreshInvoiceInput
}

export type MutationRegisterUserArgs = {
  input: RegisterUserInput
}

export type MutationRetryAllInvoicePaymentsArgs = {
  input: RetryAllInvoicePaymentsInput
}

export type MutationRetryInvoicePaymentArgs = {
  input: RetryInvoicePaymentInput
}

export type MutationRevokeInviteArgs = {
  input: RevokeInviteInput
}

export type MutationRevokeMembershipArgs = {
  input: RevokeMembershipInput
}

export type MutationTerminateAppliedCouponArgs = {
  input: TerminateAppliedCouponInput
}

export type MutationTerminateCouponArgs = {
  input: TerminateCouponInput
}

export type MutationTerminateCustomerWalletArgs = {
  input: TerminateCustomerWalletInput
}

export type MutationTerminateSubscriptionArgs = {
  input: TerminateSubscriptionInput
}

export type MutationUpdateAddOnArgs = {
  input: UpdateAddOnInput
}

export type MutationUpdateBillableMetricArgs = {
  input: UpdateBillableMetricInput
}

export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput
}

export type MutationUpdateCreditNoteArgs = {
  input: UpdateCreditNoteInput
}

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput
}

export type MutationUpdateCustomerInvoiceGracePeriodArgs = {
  input: UpdateCustomerInvoiceGracePeriodInput
}

export type MutationUpdateCustomerVatRateArgs = {
  input: UpdateCustomerVatRateInput
}

export type MutationUpdateCustomerWalletArgs = {
  input: UpdateCustomerWalletInput
}

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput
}

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput
}

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput
}

export type MutationVoidCreditNoteArgs = {
  input: VoidCreditNoteInput
}

export type Organization = {
  __typename?: 'Organization'
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  apiKey: Scalars['String']
  billingConfiguration?: Maybe<OrganizationBillingConfiguration>
  city?: Maybe<Scalars['String']>
  country?: Maybe<CountryCode>
  createdAt: Scalars['ISO8601DateTime']
  email?: Maybe<Scalars['String']>
  gocardlessPaymentProvider?: Maybe<GocardlessProvider>
  id: Scalars['ID']
  legalName?: Maybe<Scalars['String']>
  legalNumber?: Maybe<Scalars['String']>
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  state?: Maybe<Scalars['String']>
  stripePaymentProvider?: Maybe<StripeProvider>
  timezone?: Maybe<TimezoneEnum>
  updatedAt: Scalars['ISO8601DateTime']
  webhookUrl?: Maybe<Scalars['String']>
  zipcode?: Maybe<Scalars['String']>
}

export type OrganizationBillingConfiguration = {
  __typename?: 'OrganizationBillingConfiguration'
  id: Scalars['ID']
  invoiceFooter?: Maybe<Scalars['String']>
  invoiceGracePeriod: Scalars['Int']
  vatRate: Scalars['Float']
}

export type OrganizationBillingConfigurationInput = {
  invoiceFooter?: InputMaybe<Scalars['String']>
  invoiceGracePeriod?: InputMaybe<Scalars['Int']>
  vatRate?: InputMaybe<Scalars['Float']>
}

export type Plan = {
  __typename?: 'Plan'
  activeSubscriptionsCount: Scalars['Int']
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  billChargesMonthly?: Maybe<Scalars['Boolean']>
  /** Number of charges attached to a plan */
  chargeCount: Scalars['Int']
  charges?: Maybe<Array<Charge>>
  code: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  /** Number of customers attached to a plan */
  customerCount: Scalars['Int']
  description?: Maybe<Scalars['String']>
  draftInvoicesCount: Scalars['Int']
  id: Scalars['ID']
  interval: PlanInterval
  name: Scalars['String']
  organization?: Maybe<Organization>
  parentId?: Maybe<Scalars['ID']>
  payInAdvance: Scalars['Boolean']
  subscriptionsCount: Scalars['Int']
  trialPeriod?: Maybe<Scalars['Float']>
  updatedAt: Scalars['ISO8601DateTime']
}

export type PlanCollection = {
  __typename?: 'PlanCollection'
  collection: Array<Plan>
  metadata: CollectionMetadata
}

export type PlanDetails = {
  __typename?: 'PlanDetails'
  activeSubscriptionsCount: Scalars['Int']
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  billChargesMonthly?: Maybe<Scalars['Boolean']>
  /** Number of charges attached to a plan */
  chargeCount: Scalars['Int']
  charges?: Maybe<Array<Charge>>
  code: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  /** Number of customers attached to a plan */
  customerCount: Scalars['Int']
  description?: Maybe<Scalars['String']>
  draftInvoicesCount: Scalars['Int']
  id: Scalars['ID']
  interval: PlanInterval
  name: Scalars['String']
  organization?: Maybe<Organization>
  parentId?: Maybe<Scalars['ID']>
  payInAdvance: Scalars['Boolean']
  subscriptionsCount: Scalars['Int']
  trialPeriod?: Maybe<Scalars['Float']>
  updatedAt: Scalars['ISO8601DateTime']
}

export enum PlanInterval {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export type Properties = {
  __typename?: 'Properties'
  amount?: Maybe<Scalars['String']>
  fixedAmount?: Maybe<Scalars['String']>
  freeUnits?: Maybe<Scalars['Int']>
  freeUnitsPerEvents?: Maybe<Scalars['Int']>
  freeUnitsPerTotalAggregation?: Maybe<Scalars['String']>
  graduatedRanges?: Maybe<Array<GraduatedRange>>
  packageSize?: Maybe<Scalars['Int']>
  rate?: Maybe<Scalars['String']>
  volumeRanges?: Maybe<Array<VolumeRange>>
}

export type PropertiesInput = {
  amount?: InputMaybe<Scalars['String']>
  fixedAmount?: InputMaybe<Scalars['String']>
  freeUnits?: InputMaybe<Scalars['Int']>
  freeUnitsPerEvents?: InputMaybe<Scalars['Int']>
  freeUnitsPerTotalAggregation?: InputMaybe<Scalars['String']>
  graduatedRanges?: InputMaybe<Array<GraduatedRangeInput>>
  packageSize?: InputMaybe<Scalars['Int']>
  rate?: InputMaybe<Scalars['String']>
  volumeRanges?: InputMaybe<Array<VolumeRangeInput>>
}

export type ProviderCustomer = {
  __typename?: 'ProviderCustomer'
  id: Scalars['ID']
  providerCustomerId?: Maybe<Scalars['ID']>
  syncWithProvider?: Maybe<Scalars['Boolean']>
}

export type ProviderCustomerInput = {
  providerCustomerId?: InputMaybe<Scalars['ID']>
  syncWithProvider?: InputMaybe<Scalars['Boolean']>
}

export enum ProviderTypeEnum {
  Gocardless = 'gocardless',
  Stripe = 'stripe',
}

export type Query = {
  __typename?: 'Query'
  /** Query a single add-on of an organization */
  addOn?: Maybe<AddOnDetails>
  /** Query add-ons of an organization */
  addOns: AddOnCollection
  /** Query a single billable metric of an organization */
  billableMetric?: Maybe<BillableMetricDetail>
  /** Query billable metrics of an organization */
  billableMetrics: BillableMetricCollection
  /** Query a single coupon of an organization */
  coupon?: Maybe<CouponDetails>
  /** Query coupons of an organization */
  coupons: CouponCollection
  /** Query a single credit note */
  creditNote?: Maybe<CreditNote>
  /** Retrieves currently connected user */
  currentUser: User
  /** Retrieve the version of the application */
  currentVersion: CurrentVersion
  /** Query a single customer of an organization */
  customer?: Maybe<CustomerDetails>
  /** Query customer's credit note */
  customerCreditNotes?: Maybe<CreditNoteCollection>
  /** Query invoices of a customer */
  customerInvoices: InvoiceCollection
  /** Query the usage of the customer on the current billing period */
  customerUsage: CustomerUsage
  /** Query customers of an organization */
  customers: CustomerCollection
  /** Query events of an organization */
  events?: Maybe<EventCollection>
  /** Query a single Invite */
  invite?: Maybe<Invite>
  /** Query pending invites of an organization */
  invites: InviteCollection
  /** Query a single Invoice of an organization */
  invoice?: Maybe<Invoice>
  /** Query invoice's credit note */
  invoiceCreditNotes?: Maybe<CreditNoteCollection>
  /** Query invoices */
  invoices: InvoiceCollection
  /** Query memberships of an organization */
  memberships: MembershipCollection
  /** Query the current organization */
  organization?: Maybe<Organization>
  /** Query a single plan of an organization */
  plan?: Maybe<PlanDetails>
  /** Query plans of an organization */
  plans: PlanCollection
  /** Query a single wallet */
  wallet?: Maybe<WalletDetails>
  /** Query a single wallet transaction */
  walletTransaction?: Maybe<WalletTransactionDetails>
  /** Query wallet transactions */
  walletTransactions: WalletTransactionCollection
  /** Query wallets */
  wallets: WalletCollection
}

export type QueryAddOnArgs = {
  id: Scalars['ID']
}

export type QueryAddOnsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryBillableMetricArgs = {
  id: Scalars['ID']
}

export type QueryBillableMetricsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryCouponArgs = {
  id: Scalars['ID']
}

export type QueryCouponsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
  status?: InputMaybe<CouponStatusEnum>
}

export type QueryCreditNoteArgs = {
  id: Scalars['ID']
}

export type QueryCustomerArgs = {
  id: Scalars['ID']
}

export type QueryCustomerCreditNotesArgs = {
  customerId: Scalars['ID']
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryCustomerInvoicesArgs = {
  customerId: Scalars['ID']
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
  status?: InputMaybe<InvoiceStatusTypeEnum>
}

export type QueryCustomerUsageArgs = {
  customerId?: InputMaybe<Scalars['ID']>
  subscriptionId: Scalars['ID']
}

export type QueryCustomersArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type QueryInviteArgs = {
  token: Scalars['String']
}

export type QueryInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type QueryInvoiceArgs = {
  id: Scalars['ID']
}

export type QueryInvoiceCreditNotesArgs = {
  invoiceId: Scalars['ID']
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type QueryInvoicesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatusTypeEnum>>
  searchTerm?: InputMaybe<Scalars['String']>
  status?: InputMaybe<InvoiceStatusTypeEnum>
}

export type QueryMembershipsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type QueryPlanArgs = {
  id: Scalars['ID']
}

export type QueryPlansArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryWalletArgs = {
  id: Scalars['ID']
}

export type QueryWalletTransactionArgs = {
  id: Scalars['ID']
}

export type QueryWalletTransactionsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<WalletTransactionStatusEnum>
  transactionType?: InputMaybe<WalletTransactionTransactionTypeEnum>
  walletId: Scalars['ID']
}

export type QueryWalletsArgs = {
  customerId: Scalars['ID']
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<WalletStatusEnum>
}

/** Autogenerated input type of RefreshInvoice */
export type RefreshInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RegisterUser = {
  __typename?: 'RegisterUser'
  membership: Membership
  organization: Organization
  token: Scalars['String']
  user: User
}

/** Autogenerated input type of RegisterUser */
export type RegisterUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  organizationName: Scalars['String']
  password: Scalars['String']
}

/** Autogenerated input type of RetryAllInvoicePayments */
export type RetryAllInvoicePaymentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of RetryInvoicePayment */
export type RetryInvoicePaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated input type of RevokeInvite */
export type RevokeInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated input type of RevokeMembership */
export type RevokeMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export enum StatusTypeEnum {
  Active = 'active',
  Canceled = 'canceled',
  Pending = 'pending',
  Terminated = 'terminated',
}

export type StripeProvider = {
  __typename?: 'StripeProvider'
  createCustomers: Scalars['Boolean']
  id: Scalars['ID']
  secretKey: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  billingTime?: Maybe<BillingTimeEnum>
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  customer: Customer
  externalId: Scalars['String']
  fees?: Maybe<Array<Fee>>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  nextName?: Maybe<Scalars['String']>
  nextPendingStartDate?: Maybe<Scalars['ISO8601Date']>
  nextPlan?: Maybe<Plan>
  periodEndDate?: Maybe<Scalars['ISO8601Date']>
  plan: Plan
  startedAt?: Maybe<Scalars['ISO8601DateTime']>
  status?: Maybe<StatusTypeEnum>
  subscriptionAt?: Maybe<Scalars['ISO8601DateTime']>
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
}

/** Autogenerated input type of TerminateAppliedCoupon */
export type TerminateAppliedCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated input type of TerminateCoupon */
export type TerminateCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated input type of TerminateCustomerWallet */
export type TerminateCustomerWalletInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated input type of TerminateSubscription */
export type TerminateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export enum TimezoneEnum {
  /** Africa/Algiers */
  TzAfricaAlgiers = 'TZ_AFRICA_ALGIERS',
  /** Africa/Cairo */
  TzAfricaCairo = 'TZ_AFRICA_CAIRO',
  /** Africa/Casablanca */
  TzAfricaCasablanca = 'TZ_AFRICA_CASABLANCA',
  /** Africa/Harare */
  TzAfricaHarare = 'TZ_AFRICA_HARARE',
  /** Africa/Johannesburg */
  TzAfricaJohannesburg = 'TZ_AFRICA_JOHANNESBURG',
  /** Africa/Monrovia */
  TzAfricaMonrovia = 'TZ_AFRICA_MONROVIA',
  /** Africa/Nairobi */
  TzAfricaNairobi = 'TZ_AFRICA_NAIROBI',
  /** America/Argentina/Buenos_Aires */
  TzAmericaArgentinaBuenosAires = 'TZ_AMERICA_ARGENTINA_BUENOS_AIRES',
  /** America/Bogota */
  TzAmericaBogota = 'TZ_AMERICA_BOGOTA',
  /** America/Caracas */
  TzAmericaCaracas = 'TZ_AMERICA_CARACAS',
  /** America/Chicago */
  TzAmericaChicago = 'TZ_AMERICA_CHICAGO',
  /** America/Chihuahua */
  TzAmericaChihuahua = 'TZ_AMERICA_CHIHUAHUA',
  /** America/Denver */
  TzAmericaDenver = 'TZ_AMERICA_DENVER',
  /** America/Godthab */
  TzAmericaGodthab = 'TZ_AMERICA_GODTHAB',
  /** America/Guatemala */
  TzAmericaGuatemala = 'TZ_AMERICA_GUATEMALA',
  /** America/Guyana */
  TzAmericaGuyana = 'TZ_AMERICA_GUYANA',
  /** America/Halifax */
  TzAmericaHalifax = 'TZ_AMERICA_HALIFAX',
  /** America/Indiana/Indianapolis */
  TzAmericaIndianaIndianapolis = 'TZ_AMERICA_INDIANA_INDIANAPOLIS',
  /** America/Juneau */
  TzAmericaJuneau = 'TZ_AMERICA_JUNEAU',
  /** America/La_Paz */
  TzAmericaLaPaz = 'TZ_AMERICA_LA_PAZ',
  /** America/Lima */
  TzAmericaLima = 'TZ_AMERICA_LIMA',
  /** America/Los_Angeles */
  TzAmericaLosAngeles = 'TZ_AMERICA_LOS_ANGELES',
  /** America/Mazatlan */
  TzAmericaMazatlan = 'TZ_AMERICA_MAZATLAN',
  /** America/Mexico_City */
  TzAmericaMexicoCity = 'TZ_AMERICA_MEXICO_CITY',
  /** America/Monterrey */
  TzAmericaMonterrey = 'TZ_AMERICA_MONTERREY',
  /** America/Montevideo */
  TzAmericaMontevideo = 'TZ_AMERICA_MONTEVIDEO',
  /** America/New_York */
  TzAmericaNewYork = 'TZ_AMERICA_NEW_YORK',
  /** America/Phoenix */
  TzAmericaPhoenix = 'TZ_AMERICA_PHOENIX',
  /** America/Puerto_Rico */
  TzAmericaPuertoRico = 'TZ_AMERICA_PUERTO_RICO',
  /** America/Regina */
  TzAmericaRegina = 'TZ_AMERICA_REGINA',
  /** America/Santiago */
  TzAmericaSantiago = 'TZ_AMERICA_SANTIAGO',
  /** America/Sao_Paulo */
  TzAmericaSaoPaulo = 'TZ_AMERICA_SAO_PAULO',
  /** America/St_Johns */
  TzAmericaStJohns = 'TZ_AMERICA_ST_JOHNS',
  /** America/Tijuana */
  TzAmericaTijuana = 'TZ_AMERICA_TIJUANA',
  /** Asia/Almaty */
  TzAsiaAlmaty = 'TZ_ASIA_ALMATY',
  /** Asia/Baghdad */
  TzAsiaBaghdad = 'TZ_ASIA_BAGHDAD',
  /** Asia/Baku */
  TzAsiaBaku = 'TZ_ASIA_BAKU',
  /** Asia/Bangkok */
  TzAsiaBangkok = 'TZ_ASIA_BANGKOK',
  /** Asia/Chongqing */
  TzAsiaChongqing = 'TZ_ASIA_CHONGQING',
  /** Asia/Colombo */
  TzAsiaColombo = 'TZ_ASIA_COLOMBO',
  /** Asia/Dhaka */
  TzAsiaDhaka = 'TZ_ASIA_DHAKA',
  /** Asia/Hong_Kong */
  TzAsiaHongKong = 'TZ_ASIA_HONG_KONG',
  /** Asia/Irkutsk */
  TzAsiaIrkutsk = 'TZ_ASIA_IRKUTSK',
  /** Asia/Jakarta */
  TzAsiaJakarta = 'TZ_ASIA_JAKARTA',
  /** Asia/Jerusalem */
  TzAsiaJerusalem = 'TZ_ASIA_JERUSALEM',
  /** Asia/Kabul */
  TzAsiaKabul = 'TZ_ASIA_KABUL',
  /** Asia/Kamchatka */
  TzAsiaKamchatka = 'TZ_ASIA_KAMCHATKA',
  /** Asia/Karachi */
  TzAsiaKarachi = 'TZ_ASIA_KARACHI',
  /** Asia/Kathmandu */
  TzAsiaKathmandu = 'TZ_ASIA_KATHMANDU',
  /** Asia/Kolkata */
  TzAsiaKolkata = 'TZ_ASIA_KOLKATA',
  /** Asia/Krasnoyarsk */
  TzAsiaKrasnoyarsk = 'TZ_ASIA_KRASNOYARSK',
  /** Asia/Kuala_Lumpur */
  TzAsiaKualaLumpur = 'TZ_ASIA_KUALA_LUMPUR',
  /** Asia/Kuwait */
  TzAsiaKuwait = 'TZ_ASIA_KUWAIT',
  /** Asia/Magadan */
  TzAsiaMagadan = 'TZ_ASIA_MAGADAN',
  /** Asia/Muscat */
  TzAsiaMuscat = 'TZ_ASIA_MUSCAT',
  /** Asia/Novosibirsk */
  TzAsiaNovosibirsk = 'TZ_ASIA_NOVOSIBIRSK',
  /** Asia/Rangoon */
  TzAsiaRangoon = 'TZ_ASIA_RANGOON',
  /** Asia/Riyadh */
  TzAsiaRiyadh = 'TZ_ASIA_RIYADH',
  /** Asia/Seoul */
  TzAsiaSeoul = 'TZ_ASIA_SEOUL',
  /** Asia/Shanghai */
  TzAsiaShanghai = 'TZ_ASIA_SHANGHAI',
  /** Asia/Singapore */
  TzAsiaSingapore = 'TZ_ASIA_SINGAPORE',
  /** Asia/Srednekolymsk */
  TzAsiaSrednekolymsk = 'TZ_ASIA_SREDNEKOLYMSK',
  /** Asia/Taipei */
  TzAsiaTaipei = 'TZ_ASIA_TAIPEI',
  /** Asia/Tashkent */
  TzAsiaTashkent = 'TZ_ASIA_TASHKENT',
  /** Asia/Tbilisi */
  TzAsiaTbilisi = 'TZ_ASIA_TBILISI',
  /** Asia/Tehran */
  TzAsiaTehran = 'TZ_ASIA_TEHRAN',
  /** Asia/Tokyo */
  TzAsiaTokyo = 'TZ_ASIA_TOKYO',
  /** Asia/Ulaanbaatar */
  TzAsiaUlaanbaatar = 'TZ_ASIA_ULAANBAATAR',
  /** Asia/Urumqi */
  TzAsiaUrumqi = 'TZ_ASIA_URUMQI',
  /** Asia/Vladivostok */
  TzAsiaVladivostok = 'TZ_ASIA_VLADIVOSTOK',
  /** Asia/Yakutsk */
  TzAsiaYakutsk = 'TZ_ASIA_YAKUTSK',
  /** Asia/Yekaterinburg */
  TzAsiaYekaterinburg = 'TZ_ASIA_YEKATERINBURG',
  /** Asia/Yerevan */
  TzAsiaYerevan = 'TZ_ASIA_YEREVAN',
  /** Atlantic/Azores */
  TzAtlanticAzores = 'TZ_ATLANTIC_AZORES',
  /** Atlantic/Cape_Verde */
  TzAtlanticCapeVerde = 'TZ_ATLANTIC_CAPE_VERDE',
  /** Atlantic/South_Georgia */
  TzAtlanticSouthGeorgia = 'TZ_ATLANTIC_SOUTH_GEORGIA',
  /** Australia/Adelaide */
  TzAustraliaAdelaide = 'TZ_AUSTRALIA_ADELAIDE',
  /** Australia/Brisbane */
  TzAustraliaBrisbane = 'TZ_AUSTRALIA_BRISBANE',
  /** Australia/Darwin */
  TzAustraliaDarwin = 'TZ_AUSTRALIA_DARWIN',
  /** Australia/Hobart */
  TzAustraliaHobart = 'TZ_AUSTRALIA_HOBART',
  /** Australia/Melbourne */
  TzAustraliaMelbourne = 'TZ_AUSTRALIA_MELBOURNE',
  /** Australia/Perth */
  TzAustraliaPerth = 'TZ_AUSTRALIA_PERTH',
  /** Australia/Sydney */
  TzAustraliaSydney = 'TZ_AUSTRALIA_SYDNEY',
  /** Etc/GMT+12 */
  TzEtcGmt_12 = 'TZ_ETC_GMT_12',
  /** Europe/Amsterdam */
  TzEuropeAmsterdam = 'TZ_EUROPE_AMSTERDAM',
  /** Europe/Athens */
  TzEuropeAthens = 'TZ_EUROPE_ATHENS',
  /** Europe/Belgrade */
  TzEuropeBelgrade = 'TZ_EUROPE_BELGRADE',
  /** Europe/Berlin */
  TzEuropeBerlin = 'TZ_EUROPE_BERLIN',
  /** Europe/Bratislava */
  TzEuropeBratislava = 'TZ_EUROPE_BRATISLAVA',
  /** Europe/Brussels */
  TzEuropeBrussels = 'TZ_EUROPE_BRUSSELS',
  /** Europe/Bucharest */
  TzEuropeBucharest = 'TZ_EUROPE_BUCHAREST',
  /** Europe/Budapest */
  TzEuropeBudapest = 'TZ_EUROPE_BUDAPEST',
  /** Europe/Copenhagen */
  TzEuropeCopenhagen = 'TZ_EUROPE_COPENHAGEN',
  /** Europe/Dublin */
  TzEuropeDublin = 'TZ_EUROPE_DUBLIN',
  /** Europe/Helsinki */
  TzEuropeHelsinki = 'TZ_EUROPE_HELSINKI',
  /** Europe/Istanbul */
  TzEuropeIstanbul = 'TZ_EUROPE_ISTANBUL',
  /** Europe/Kaliningrad */
  TzEuropeKaliningrad = 'TZ_EUROPE_KALININGRAD',
  /** Europe/Kiev */
  TzEuropeKiev = 'TZ_EUROPE_KIEV',
  /** Europe/Lisbon */
  TzEuropeLisbon = 'TZ_EUROPE_LISBON',
  /** Europe/Ljubljana */
  TzEuropeLjubljana = 'TZ_EUROPE_LJUBLJANA',
  /** Europe/London */
  TzEuropeLondon = 'TZ_EUROPE_LONDON',
  /** Europe/Madrid */
  TzEuropeMadrid = 'TZ_EUROPE_MADRID',
  /** Europe/Minsk */
  TzEuropeMinsk = 'TZ_EUROPE_MINSK',
  /** Europe/Moscow */
  TzEuropeMoscow = 'TZ_EUROPE_MOSCOW',
  /** Europe/Paris */
  TzEuropeParis = 'TZ_EUROPE_PARIS',
  /** Europe/Prague */
  TzEuropePrague = 'TZ_EUROPE_PRAGUE',
  /** Europe/Riga */
  TzEuropeRiga = 'TZ_EUROPE_RIGA',
  /** Europe/Rome */
  TzEuropeRome = 'TZ_EUROPE_ROME',
  /** Europe/Samara */
  TzEuropeSamara = 'TZ_EUROPE_SAMARA',
  /** Europe/Sarajevo */
  TzEuropeSarajevo = 'TZ_EUROPE_SARAJEVO',
  /** Europe/Skopje */
  TzEuropeSkopje = 'TZ_EUROPE_SKOPJE',
  /** Europe/Sofia */
  TzEuropeSofia = 'TZ_EUROPE_SOFIA',
  /** Europe/Stockholm */
  TzEuropeStockholm = 'TZ_EUROPE_STOCKHOLM',
  /** Europe/Tallinn */
  TzEuropeTallinn = 'TZ_EUROPE_TALLINN',
  /** Europe/Vienna */
  TzEuropeVienna = 'TZ_EUROPE_VIENNA',
  /** Europe/Vilnius */
  TzEuropeVilnius = 'TZ_EUROPE_VILNIUS',
  /** Europe/Volgograd */
  TzEuropeVolgograd = 'TZ_EUROPE_VOLGOGRAD',
  /** Europe/Warsaw */
  TzEuropeWarsaw = 'TZ_EUROPE_WARSAW',
  /** Europe/Zagreb */
  TzEuropeZagreb = 'TZ_EUROPE_ZAGREB',
  /** Europe/Zurich */
  TzEuropeZurich = 'TZ_EUROPE_ZURICH',
  /** Pacific/Apia */
  TzPacificApia = 'TZ_PACIFIC_APIA',
  /** Pacific/Auckland */
  TzPacificAuckland = 'TZ_PACIFIC_AUCKLAND',
  /** Pacific/Chatham */
  TzPacificChatham = 'TZ_PACIFIC_CHATHAM',
  /** Pacific/Fakaofo */
  TzPacificFakaofo = 'TZ_PACIFIC_FAKAOFO',
  /** Pacific/Fiji */
  TzPacificFiji = 'TZ_PACIFIC_FIJI',
  /** Pacific/Guadalcanal */
  TzPacificGuadalcanal = 'TZ_PACIFIC_GUADALCANAL',
  /** Pacific/Guam */
  TzPacificGuam = 'TZ_PACIFIC_GUAM',
  /** Pacific/Honolulu */
  TzPacificHonolulu = 'TZ_PACIFIC_HONOLULU',
  /** Pacific/Majuro */
  TzPacificMajuro = 'TZ_PACIFIC_MAJURO',
  /** Pacific/Midway */
  TzPacificMidway = 'TZ_PACIFIC_MIDWAY',
  /** Pacific/Noumea */
  TzPacificNoumea = 'TZ_PACIFIC_NOUMEA',
  /** Pacific/Pago_Pago */
  TzPacificPagoPago = 'TZ_PACIFIC_PAGO_PAGO',
  /** Pacific/Port_Moresby */
  TzPacificPortMoresby = 'TZ_PACIFIC_PORT_MORESBY',
  /** Pacific/Tongatapu */
  TzPacificTongatapu = 'TZ_PACIFIC_TONGATAPU',
  /** UTC */
  TzUtc = 'TZ_UTC',
}

/** Autogenerated input type of UpdateAddOn */
export type UpdateAddOnInput = {
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

/** Autogenerated input type of UpdateBillableMetric */
export type UpdateBillableMetricInput = {
  aggregationType: AggregationTypeEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  description: Scalars['String']
  fieldName?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['JSON']>
  id: Scalars['String']
  name: Scalars['String']
}

/** Autogenerated input type of UpdateCoupon */
export type UpdateCouponInput = {
  amountCents?: InputMaybe<Scalars['BigInt']>
  amountCurrency?: InputMaybe<CurrencyEnum>
  appliesTo?: InputMaybe<LimitationInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponType: CouponTypeEnum
  expiration: CouponExpiration
  expirationAt?: InputMaybe<Scalars['ISO8601DateTime']>
  frequency: CouponFrequency
  frequencyDuration?: InputMaybe<Scalars['Int']>
  id: Scalars['String']
  name: Scalars['String']
  percentageRate?: InputMaybe<Scalars['Float']>
  reusable?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated input type of UpdateCreditNote */
export type UpdateCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  refundStatus: CreditNoteRefundStatusEnum
}

/** Autogenerated input type of UpdateCustomer */
export type UpdateCustomerInput = {
  addressLine1?: InputMaybe<Scalars['String']>
  addressLine2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  country?: InputMaybe<CountryCode>
  currency?: InputMaybe<CurrencyEnum>
  email?: InputMaybe<Scalars['String']>
  externalId: Scalars['String']
  id: Scalars['ID']
  invoiceGracePeriod?: InputMaybe<Scalars['Int']>
  legalName?: InputMaybe<Scalars['String']>
  legalNumber?: InputMaybe<Scalars['String']>
  logoUrl?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  paymentProvider?: InputMaybe<ProviderTypeEnum>
  phone?: InputMaybe<Scalars['String']>
  providerCustomer?: InputMaybe<ProviderCustomerInput>
  state?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<TimezoneEnum>
  url?: InputMaybe<Scalars['String']>
  vatRate?: InputMaybe<Scalars['Float']>
  zipcode?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of UpdateCustomerInvoiceGracePeriod */
export type UpdateCustomerInvoiceGracePeriodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  invoiceGracePeriod?: InputMaybe<Scalars['Int']>
}

/** Autogenerated input type of UpdateCustomerVatRate */
export type UpdateCustomerVatRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  vatRate?: InputMaybe<Scalars['Float']>
}

/** Autogenerated input type of UpdateCustomerWallet */
export type UpdateCustomerWalletInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  expirationAt?: InputMaybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of UpdateOrganization */
export type UpdateOrganizationInput = {
  addressLine1?: InputMaybe<Scalars['String']>
  addressLine2?: InputMaybe<Scalars['String']>
  billingConfiguration?: InputMaybe<OrganizationBillingConfigurationInput>
  city?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  country?: InputMaybe<CountryCode>
  email?: InputMaybe<Scalars['String']>
  legalName?: InputMaybe<Scalars['String']>
  legalNumber?: InputMaybe<Scalars['String']>
  logo?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<TimezoneEnum>
  webhookUrl?: InputMaybe<Scalars['String']>
  zipcode?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of UpdatePlan */
export type UpdatePlanInput = {
  amountCents: Scalars['BigInt']
  amountCurrency: CurrencyEnum
  billChargesMonthly?: InputMaybe<Scalars['Boolean']>
  charges: Array<ChargeInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  interval: PlanInterval
  name: Scalars['String']
  payInAdvance: Scalars['Boolean']
  trialPeriod?: InputMaybe<Scalars['Float']>
}

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  subscriptionAt?: InputMaybe<Scalars['ISO8601DateTime']>
}

export type User = {
  __typename?: 'User'
  createdAt: Scalars['ISO8601DateTime']
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  organizations?: Maybe<Array<Organization>>
  premium: Scalars['Boolean']
  updatedAt: Scalars['ISO8601DateTime']
}

/** Autogenerated input type of VoidCreditNote */
export type VoidCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type VolumeRange = {
  __typename?: 'VolumeRange'
  flatAmount: Scalars['String']
  fromValue: Scalars['Int']
  perUnitAmount: Scalars['String']
  toValue?: Maybe<Scalars['Int']>
}

export type VolumeRangeInput = {
  flatAmount: Scalars['String']
  fromValue: Scalars['Int']
  perUnitAmount: Scalars['String']
  toValue?: InputMaybe<Scalars['Int']>
}

export type Wallet = {
  __typename?: 'Wallet'
  balance: Scalars['String']
  consumedAmount: Scalars['String']
  consumedCredits: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  creditsBalance: Scalars['String']
  currency: CurrencyEnum
  customer?: Maybe<Customer>
  expirationAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  lastBalanceSyncAt?: Maybe<Scalars['ISO8601DateTime']>
  lastConsumedCreditAt?: Maybe<Scalars['ISO8601DateTime']>
  name?: Maybe<Scalars['String']>
  rateAmount: Scalars['String']
  status: WalletStatusEnum
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
}

export type WalletCollection = {
  __typename?: 'WalletCollection'
  collection: Array<Wallet>
  metadata: CollectionMetadata
}

export type WalletDetails = {
  __typename?: 'WalletDetails'
  // balance: Scalars['String'];
  consumedAmount: Scalars['String']
  // consumedCredits: Scalars['String'];
  // createdAt: Scalars['ISO8601DateTime'];
  // creditsBalance: Scalars['String'];
  // currency: CurrencyEnum;
  customer?: Maybe<Customer>
  expirationAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  lastBalanceSyncAt?: Maybe<Scalars['ISO8601DateTime']>
  lastConsumedCreditAt?: Maybe<Scalars['ISO8601DateTime']>
  name?: Maybe<Scalars['String']>
  rateAmount: Scalars['String']
  status: WalletStatusEnum
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
}

export enum WalletStatusEnum {
  Active = 'active',
  Terminated = 'terminated',
}

export type WalletTransaction = {
  __typename?: 'WalletTransaction'
  // amount: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime']
  // creditAmount: Scalars['String'];
  id: Scalars['ID']
  // settledAt?: Maybe<Scalars['ISO8601DateTime']>;
  // status: WalletTransactionStatusEnum;
  // transactionType: WalletTransactionTransactionTypeEnum;
  // updatedAt: Scalars['ISO8601DateTime'];
  // wallet?: Maybe<Wallet>;
}

export type WalletTransactionCollection = {
  __typename?: 'WalletTransactionCollection'
  collection: Array<WalletTransaction>
  metadata: CollectionMetadata
}

export type WalletTransactionDetails = {
  __typename?: 'WalletTransactionDetails'
  amount: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  creditAmount: Scalars['String']
  id: Scalars['ID']
  settledAt?: Maybe<Scalars['ISO8601DateTime']>
  status: WalletTransactionStatusEnum
  transactionType: WalletTransactionTransactionTypeEnum
  updatedAt: Scalars['ISO8601DateTime']
  wallet?: Maybe<Wallet>
}

export enum WalletTransactionStatusEnum {
  Pending = 'pending',
  Settled = 'settled',
}

export enum WalletTransactionTransactionTypeEnum {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export type UserIdentifierQueryVariables = Exact<{ [key: string]: never }>

export type UserIdentifierQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    email?: string | null
    premium: boolean
    organizations?: Array<{
      __typename?: 'Organization'
      id: string
      name: string
      logoUrl?: string | null
    }> | null
  }
  organization?: {
    __typename?: 'Organization'
    id: string
    name: string
    logoUrl?: string | null
    timezone?: TimezoneEnum | null
  } | null
}

export type AddOnItemFragment = {
  __typename?: 'AddOn'
  id: string
  name: string
  amountCurrency: CurrencyEnum
  amountCents: any
  customerCount: number
  createdAt: any
  appliedAddOnsCount: number
}

export type DeleteAddOnFragment = { __typename?: 'AddOn'; id: string; name: string }

export type DeleteAddOnMutationVariables = Exact<{
  input: DestroyAddOnInput
}>

export type DeleteAddOnMutation = {
  __typename?: 'Mutation'
  destroyAddOn?: { __typename?: 'DestroyAddOnPayload'; id?: string | null } | null
}

export type BillableMetricItemFragment = {
  __typename?: 'BillableMetric'
  id: string
  name: string
  code: string
  createdAt: any
  draftInvoicesCount: number
  activeSubscriptionsCount: number
}

export type DeleteBillableMetricDialogFragment = {
  __typename?: 'BillableMetric'
  id: string
  name: string
  draftInvoicesCount: number
  activeSubscriptionsCount: number
}

export type DeleteBillableMetricMutationVariables = Exact<{
  input: DestroyBillableMetricInput
}>

export type DeleteBillableMetricMutation = {
  __typename?: 'Mutation'
  destroyBillableMetric?: { __typename?: 'DestroyBillableMetricPayload'; id?: string | null } | null
}

export type PlansForCouponsFragment = {
  __typename?: 'Plan'
  id: string
  name: string
  code: string
}

export type GetPlansForCouponsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetPlansForCouponsQuery = {
  __typename?: 'Query'
  plans: {
    __typename?: 'PlanCollection'
    collection: Array<{ __typename?: 'Plan'; id: string; name: string; code: string }>
  }
}

export type CouponCaptionFragment = {
  __typename?: 'Coupon'
  id: string
  amountCurrency?: CurrencyEnum | null
  amountCents?: any | null
  couponType: CouponTypeEnum
  percentageRate?: number | null
  frequency: CouponFrequency
  frequencyDuration?: number | null
}

export type AppliedCouponCaptionFragment = {
  __typename?: 'AppliedCoupon'
  id: string
  amountCurrency?: CurrencyEnum | null
  amountCents?: any | null
  amountCentsRemaining?: any | null
  percentageRate?: number | null
  frequency: CouponFrequency
  frequencyDuration?: number | null
  frequencyDurationRemaining?: number | null
}

export type CouponItemFragment = {
  __typename?: 'Coupon'
  id: string
  name: string
  customerCount: number
  status: CouponStatusEnum
  amountCurrency?: CurrencyEnum | null
  amountCents?: any | null
  appliedCouponsCount: number
  expiration: CouponExpiration
  expirationAt?: any | null
  couponType: CouponTypeEnum
  percentageRate?: number | null
  frequency: CouponFrequency
  frequencyDuration?: number | null
}

export type DeleteCouponFragment = { __typename?: 'Coupon'; id: string; name: string }

export type DeleteCouponMutationVariables = Exact<{
  input: DestroyCouponInput
}>

export type DeleteCouponMutation = {
  __typename?: 'Mutation'
  destroyCoupon?: { __typename?: 'DestroyCouponPayload'; id?: string | null } | null
}

export type TerminateCouponFragment = { __typename?: 'Coupon'; id: string; name: string }

export type TerminateCouponMutationVariables = Exact<{
  input: TerminateCouponInput
}>

export type TerminateCouponMutation = {
  __typename?: 'Mutation'
  terminateCoupon?: { __typename?: 'Coupon'; id: string } | null
}

export type CreditNoteFormFragment = {
  __typename?: 'Invoice'
  id: string
  paymentStatus: InvoicePaymentStatusTypeEnum
  creditableAmountCents: any
  refundableAmountCents: any
  vatRate: number
  amountCurrency: CurrencyEnum
}

export type GetAddOnsForCustomerQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetAddOnsForCustomerQuery = {
  __typename?: 'Query'
  addOns: {
    __typename?: 'AddOnCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'AddOn'
      id: string
      name: string
      amountCurrency: CurrencyEnum
      amountCents: any
    }>
  }
}

export type AddAddOnMutationVariables = Exact<{
  input: CreateAppliedAddOnInput
}>

export type AddAddOnMutation = {
  __typename?: 'Mutation'
  createAppliedAddOn?: { __typename?: 'AppliedAddOn'; id: string } | null
}

export type CouponPlansForCustomerFragment = { __typename?: 'Plan'; id: string; name: string }

export type GetCouponForCustomerQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<CouponStatusEnum>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetCouponForCustomerQuery = {
  __typename?: 'Query'
  coupons: {
    __typename?: 'CouponCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'Coupon'
      id: string
      name: string
      amountCurrency?: CurrencyEnum | null
      amountCents?: any | null
      couponType: CouponTypeEnum
      percentageRate?: number | null
      frequency: CouponFrequency
      frequencyDuration?: number | null
      plans?: Array<{ __typename?: 'Plan'; id: string; name: string }> | null
    }>
  }
}

export type AddCouponMutationVariables = Exact<{
  input: CreateAppliedCouponInput
}>

export type AddCouponMutation = {
  __typename?: 'Mutation'
  createAppliedCoupon?: { __typename?: 'AppliedCoupon'; id: string } | null
}

export type CustomerAddOnsFragment = {
  __typename?: 'AppliedAddOn'
  id: string
  amountCents: any
  amountCurrency: CurrencyEnum
  createdAt: any
  addOn: { __typename?: 'AddOn'; id: string; name: string }
}

export type CustomerCouponFragment = {
  __typename?: 'AppliedCoupon'
  id: string
  amountCurrency?: CurrencyEnum | null
  amountCents?: any | null
  amountCentsRemaining?: any | null
  percentageRate?: number | null
  frequency: CouponFrequency
  frequencyDuration?: number | null
  frequencyDurationRemaining?: number | null
  coupon: { __typename?: 'Coupon'; id: string; name: string }
}

export type RemoveCouponMutationVariables = Exact<{
  input: TerminateAppliedCouponInput
}>

export type RemoveCouponMutation = {
  __typename?: 'Mutation'
  terminateAppliedCoupon?: { __typename?: 'AppliedCoupon'; id: string } | null
}

export type GetCustomerCreditNotesQueryVariables = Exact<{
  customerId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetCustomerCreditNotesQuery = {
  __typename?: 'Query'
  customerCreditNotes?: {
    __typename?: 'CreditNoteCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'CreditNote'
      id: string
      canBeVoided: boolean
      createdAt: any
      creditStatus?: CreditNoteCreditStatusEnum | null
      number: string
      totalAmountCents: any
      totalAmountCurrency: CurrencyEnum
    }>
  } | null
}

export type InvoiceForInvoiceListFragment = {
  __typename?: 'InvoiceCollection'
  collection: Array<{
    __typename?: 'Invoice'
    id: string
    status: InvoiceStatusTypeEnum
    paymentStatus: InvoicePaymentStatusTypeEnum
    number: string
    issuingDate: any
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
    customer: {
      __typename?: 'Customer'
      id: string
      applicableTimezone: TimezoneEnum
      name?: string | null
    }
  }>
  metadata: {
    __typename?: 'CollectionMetadata'
    currentPage: number
    totalCount: number
    totalPages: number
  }
}

export type GetCustomerInvoicesQueryVariables = Exact<{
  customerId: Scalars['ID']
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<InvoiceStatusTypeEnum>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetCustomerInvoicesQuery = {
  __typename?: 'Query'
  customerInvoices: {
    __typename?: 'InvoiceCollection'
    collection: Array<{
      __typename?: 'Invoice'
      id: string
      status: InvoiceStatusTypeEnum
      paymentStatus: InvoicePaymentStatusTypeEnum
      number: string
      issuingDate: any
      totalAmountCents: any
      totalAmountCurrency: CurrencyEnum
      customer: {
        __typename?: 'Customer'
        id: string
        applicableTimezone: TimezoneEnum
        name?: string | null
      }
    }>
    metadata: {
      __typename?: 'CollectionMetadata'
      currentPage: number
      totalCount: number
      totalPages: number
    }
  }
}

export type CustomerItemFragment = {
  __typename?: 'Customer'
  id: string
  name?: string | null
  externalId: string
  createdAt: any
  activeSubscriptionCount: number
  legalName?: string | null
  legalNumber?: string | null
  phone?: string | null
  email?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  state?: string | null
  country?: CountryCode | null
  currency?: CurrencyEnum | null
  canEditAttributes: boolean
  city?: string | null
  zipcode?: string | null
  paymentProvider?: ProviderTypeEnum | null
  timezone?: TimezoneEnum | null
  providerCustomer?: {
    __typename?: 'ProviderCustomer'
    id: string
    providerCustomerId?: string | null
    syncWithProvider?: boolean | null
  } | null
}

export type CustomerMainInfosFragment = {
  __typename?: 'CustomerDetails'
  id: string
  name?: string | null
  externalId: string
  legalName?: string | null
  legalNumber?: string | null
  phone?: string | null
  email?: string | null
  currency?: CurrencyEnum | null
  addressLine1?: string | null
  addressLine2?: string | null
  state?: string | null
  country?: CountryCode | null
  city?: string | null
  zipcode?: string | null
  paymentProvider?: ProviderTypeEnum | null
  timezone?: TimezoneEnum | null
  providerCustomer?: {
    __typename?: 'ProviderCustomer'
    id: string
    providerCustomerId?: string | null
  } | null
}

export type GetCustomerSettingsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCustomerSettingsQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'CustomerDetails'
    id: string
    vatRate?: number | null
    invoiceGracePeriod?: number | null
    name?: string | null
  } | null
  organization?: {
    __typename?: 'Organization'
    id: string
    billingConfiguration?: {
      __typename?: 'OrganizationBillingConfiguration'
      id: string
      vatRate: number
      invoiceGracePeriod: number
    } | null
  } | null
}

export type DeleteCustomerDialogFragment = {
  __typename?: 'Customer'
  id: string
  name?: string | null
}

export type DeleteCustomerMutationVariables = Exact<{
  input: DestroyCustomerInput
}>

export type DeleteCustomerMutation = {
  __typename?: 'Mutation'
  destroyCustomer?: { __typename?: 'DestroyCustomerPayload'; id?: string | null } | null
}

export type DeleteCustomerGracePeriodFragment = {
  __typename?: 'CustomerDetails'
  id: string
  name?: string | null
}

export type DeleteCustomerGracePeriodMutationVariables = Exact<{
  input: UpdateCustomerInvoiceGracePeriodInput
}>

export type DeleteCustomerGracePeriodMutation = {
  __typename?: 'Mutation'
  updateCustomerInvoiceGracePeriod?: {
    __typename?: 'CustomerDetails'
    id: string
    invoiceGracePeriod?: number | null
  } | null
}

export type DeleteCustomerVatRateFragment = {
  __typename?: 'CustomerDetails'
  id: string
  name?: string | null
}

export type DeleteCustomerVatRateMutationVariables = Exact<{
  input: UpdateCustomerVatRateInput
}>

export type DeleteCustomerVatRateMutation = {
  __typename?: 'Mutation'
  updateCustomerVatRate?: {
    __typename?: 'CustomerDetails'
    id: string
    vatRate?: number | null
  } | null
}

export type EditCustomerInvoiceGracePeriodFragment = {
  __typename?: 'CustomerDetails'
  id: string
  invoiceGracePeriod?: number | null
}

export type UpdateCustomerInvoiceGracePeriodMutationVariables = Exact<{
  input: UpdateCustomerInvoiceGracePeriodInput
}>

export type UpdateCustomerInvoiceGracePeriodMutation = {
  __typename?: 'Mutation'
  updateCustomerInvoiceGracePeriod?: {
    __typename?: 'CustomerDetails'
    id: string
    invoiceGracePeriod?: number | null
  } | null
}

export type EditCustomerVatRateFragment = {
  __typename?: 'CustomerDetails'
  id: string
  name?: string | null
  vatRate?: number | null
}

export type UpdateCustomerVatRateMutationVariables = Exact<{
  input: UpdateCustomerVatRateInput
}>

export type UpdateCustomerVatRateMutation = {
  __typename?: 'Mutation'
  updateCustomerVatRate?: {
    __typename?: 'CustomerDetails'
    id: string
    name?: string | null
    vatRate?: number | null
  } | null
}

export type CreditNotesForListFragment = {
  __typename?: 'CreditNoteCollection'
  metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
  collection: Array<{
    __typename?: 'CreditNote'
    id: string
    canBeVoided: boolean
    createdAt: any
    creditStatus?: CreditNoteCreditStatusEnum | null
    number: string
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
  }>
}

export type DownloadCreditNoteMutationVariables = Exact<{
  input: DownloadCreditNoteInput
}>

export type DownloadCreditNoteMutation = {
  __typename?: 'Mutation'
  downloadCreditNote?: { __typename?: 'CreditNote'; id: string; fileUrl?: string | null } | null
}

export type VoidCreditNoteMutationVariables = Exact<{
  input: VoidCreditNoteInput
}>

export type VoidCreditNoteMutation = {
  __typename?: 'Mutation'
  voidCreditNote?: { __typename?: 'CreditNote'; id: string } | null
}

export type UpdateCustomerSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput
}>

export type UpdateCustomerSubscriptionMutation = {
  __typename?: 'Mutation'
  updateSubscription?: {
    __typename?: 'Subscription'
    id: string
    name?: string | null
    status?: StatusTypeEnum | null
    startedAt?: any | null
    subscriptionAt?: any | null
  } | null
}

export type SubscriptionItemFragment = {
  __typename?: 'Subscription'
  id: string
  status?: StatusTypeEnum | null
  startedAt?: any | null
  nextPendingStartDate?: any | null
  name?: string | null
  nextName?: string | null
  externalId: string
  periodEndDate?: any | null
  subscriptionAt?: any | null
  plan: { __typename?: 'Plan'; id: string; name: string; code: string }
  nextPlan?: { __typename?: 'Plan'; id: string; name: string; code: string } | null
}

export type SubscriptionLinePlanFragment = {
  __typename?: 'Plan'
  id: string
  name: string
  code: string
}

export type TerminateCustomerSubscriptionMutationVariables = Exact<{
  input: TerminateSubscriptionInput
}>

export type TerminateCustomerSubscriptionMutation = {
  __typename?: 'Mutation'
  terminateSubscription?: { __typename?: 'Subscription'; id: string } | null
}

export type CustomerUsageSubscriptionFragment = {
  __typename?: 'Subscription'
  id: string
  name?: string | null
  status?: StatusTypeEnum | null
  plan: { __typename?: 'Plan'; id: string; name: string; code: string }
}

export type CustomerUsageForUsageDetailsFragment = {
  __typename?: 'CustomerUsage'
  fromDatetime: any
  toDatetime: any
  chargesUsage: Array<{
    __typename?: 'ChargeUsage'
    billableMetric: { __typename?: 'BillableMetric'; name: string }
    groups?: Array<{
      __typename?: 'GroupUsage'
      id: string
      amountCents: any
      key?: string | null
      units: number
      value: string
    }> | null
  }>
}

export type CustomerUsageQueryVariables = Exact<{
  customerId: Scalars['ID']
  subscriptionId: Scalars['ID']
}>

export type CustomerUsageQuery = {
  __typename?: 'Query'
  customerUsage: {
    __typename?: 'CustomerUsage'
    amountCents: any
    amountCurrency: CurrencyEnum
    fromDatetime: any
    toDatetime: any
    chargesUsage: Array<{
      __typename?: 'ChargeUsage'
      units: number
      amountCents: any
      billableMetric: { __typename?: 'BillableMetric'; id: string; code: string; name: string }
      groups?: Array<{
        __typename?: 'GroupUsage'
        id: string
        amountCents: any
        key?: string | null
        units: number
        value: string
      }> | null
    }>
  }
}

export type EventItemFragment = {
  __typename?: 'Event'
  id: string
  code: string
  externalCustomerId: string
  timestamp?: any | null
  matchBillableMetric: boolean
  matchCustomField: boolean
}

export type DeleteWebhookMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type DeleteWebhookMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    webhookUrl?: string | null
  } | null
}

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    webhookUrl?: string | null
  } | null
}

export type InvoiceForFinalizeInvoiceFragment = {
  __typename?: 'Invoice'
  id: string
  issuingDate: any
  customer: { __typename?: 'Customer'; id: string; applicableTimezone: TimezoneEnum }
}

export type FinalizeInvoiceMutationVariables = Exact<{
  input: FinalizeInvoiceInput
}>

export type FinalizeInvoiceMutation = {
  __typename?: 'Mutation'
  finalizeInvoice?: {
    __typename?: 'Invoice'
    id: string
    invoiceType: InvoiceTypeEnum
    number: string
    paymentStatus: InvoicePaymentStatusTypeEnum
    status: InvoiceStatusTypeEnum
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
    refundableAmountCents: any
    creditableAmountCents: any
    issuingDate: any
    subTotalVatExcludedAmountCents: any
    couponTotalAmountCents: any
    creditAmountCurrency: CurrencyEnum
    subTotalVatIncludedAmountCents: any
    vatAmountCents: any
    vatAmountCurrency: CurrencyEnum
    walletTransactionAmountCents: any
    creditNoteTotalAmountCents: any
    customer: {
      __typename?: 'Customer'
      id: string
      applicableTimezone: TimezoneEnum
      currency?: CurrencyEnum | null
      name?: string | null
      legalName?: string | null
      email?: string | null
      addressLine1?: string | null
      addressLine2?: string | null
      state?: string | null
      country?: CountryCode | null
      city?: string | null
      zipcode?: string | null
      deletedAt?: any | null
    }
    creditNotes?: Array<{
      __typename?: 'CreditNote'
      id: string
      creditAmountCurrency: CurrencyEnum
      number: string
      subTotalVatExcludedAmountCents: any
      subTotalVatExcludedAmountCurrency: CurrencyEnum
      totalAmountCents: any
      vatAmountCents: any
      vatAmountCurrency: CurrencyEnum
      items: Array<{
        __typename?: 'CreditNoteItem'
        amountCents: any
        amountCurrency: CurrencyEnum
        fee: {
          __typename?: 'Fee'
          id: string
          amountCents: any
          amountCurrency: CurrencyEnum
          eventsCount?: any | null
          units: number
          feeType: FeeTypesEnum
          itemName: string
          charge?: {
            __typename?: 'Charge'
            id: string
            billableMetric: {
              __typename?: 'BillableMetric'
              id: string
              name: string
              aggregationType: AggregationTypeEnum
            }
          } | null
          subscription?: {
            __typename?: 'Subscription'
            id: string
            name?: string | null
            plan: { __typename?: 'Plan'; id: string; name: string }
          } | null
          group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
        }
      }>
    }> | null
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCents: any
      itemName: string
      units: number
      feeType: FeeTypesEnum
    }> | null
    invoiceSubscriptions?: Array<{
      __typename?: 'InvoiceSubscription'
      subscription: {
        __typename?: 'Subscription'
        id: string
        name?: string | null
        subscriptionAt?: any | null
        periodEndDate?: any | null
        plan: {
          __typename?: 'Plan'
          id: string
          name: string
          interval: PlanInterval
          amountCents: any
          amountCurrency: CurrencyEnum
        }
      }
      fees?: Array<{
        __typename?: 'Fee'
        id: string
        amountCents: any
        eventsCount?: any | null
        units: number
        feeType: FeeTypesEnum
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: {
            __typename?: 'BillableMetric'
            id: string
            name: string
            aggregationType: AggregationTypeEnum
          }
        } | null
        group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
      }> | null
    }> | null
  } | null
}

export type InvoiceForCreditNotesTableFragment = {
  __typename?: 'Invoice'
  id: string
  subTotalVatExcludedAmountCents: any
  customer: { __typename?: 'Customer'; id: string }
  creditNotes?: Array<{
    __typename?: 'CreditNote'
    id: string
    creditAmountCurrency: CurrencyEnum
    number: string
    subTotalVatExcludedAmountCents: any
    subTotalVatExcludedAmountCurrency: CurrencyEnum
    totalAmountCents: any
    vatAmountCents: any
    vatAmountCurrency: CurrencyEnum
    items: Array<{
      __typename?: 'CreditNoteItem'
      amountCents: any
      amountCurrency: CurrencyEnum
      fee: {
        __typename?: 'Fee'
        id: string
        amountCents: any
        amountCurrency: CurrencyEnum
        eventsCount?: any | null
        units: number
        feeType: FeeTypesEnum
        itemName: string
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: {
            __typename?: 'BillableMetric'
            id: string
            name: string
            aggregationType: AggregationTypeEnum
          }
        } | null
        subscription?: {
          __typename?: 'Subscription'
          id: string
          name?: string | null
          plan: { __typename?: 'Plan'; id: string; name: string }
        } | null
        group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
      }
    }>
  }> | null
}

export type InvoiceForInvoiceInfosFragment = {
  __typename?: 'Invoice'
  number: string
  issuingDate: any
  customer: {
    __typename?: 'Customer'
    id: string
    name?: string | null
    legalName?: string | null
    email?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    state?: string | null
    country?: CountryCode | null
    city?: string | null
    zipcode?: string | null
    applicableTimezone: TimezoneEnum
    deletedAt?: any | null
  }
}

export type InvoiceForDetailsTableFragment = {
  __typename?: 'Invoice'
  couponTotalAmountCents: any
  creditAmountCurrency: CurrencyEnum
  invoiceType: InvoiceTypeEnum
  subTotalVatExcludedAmountCents: any
  subTotalVatIncludedAmountCents: any
  totalAmountCents: any
  totalAmountCurrency: CurrencyEnum
  vatAmountCents: any
  vatAmountCurrency: CurrencyEnum
  walletTransactionAmountCents: any
  creditNoteTotalAmountCents: any
  fees?: Array<{
    __typename?: 'Fee'
    id: string
    amountCents: any
    itemName: string
    units: number
    feeType: FeeTypesEnum
  }> | null
  customer: { __typename?: 'Customer'; currency?: CurrencyEnum | null }
  invoiceSubscriptions?: Array<{
    __typename?: 'InvoiceSubscription'
    subscription: {
      __typename?: 'Subscription'
      id: string
      name?: string | null
      subscriptionAt?: any | null
      periodEndDate?: any | null
      plan: {
        __typename?: 'Plan'
        id: string
        name: string
        interval: PlanInterval
        amountCents: any
        amountCurrency: CurrencyEnum
      }
    }
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCents: any
      eventsCount?: any | null
      units: number
      feeType: FeeTypesEnum
      charge?: {
        __typename?: 'Charge'
        id: string
        billableMetric: {
          __typename?: 'BillableMetric'
          id: string
          name: string
          aggregationType: AggregationTypeEnum
        }
      } | null
      group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
    }> | null
  }> | null
}

export type InvoiceForDetailsTableFooterFragment = {
  __typename?: 'Invoice'
  couponTotalAmountCents: any
  creditNoteTotalAmountCents: any
  subTotalVatExcludedAmountCents: any
  subTotalVatIncludedAmountCents: any
  totalAmountCents: any
  totalAmountCurrency: CurrencyEnum
  vatAmountCents: any
  walletTransactionAmountCents: any
}

export type InvoiceListItemFragment = {
  __typename?: 'Invoice'
  id: string
  status: InvoiceStatusTypeEnum
  paymentStatus: InvoicePaymentStatusTypeEnum
  number: string
  issuingDate: any
  totalAmountCents: any
  totalAmountCurrency: CurrencyEnum
  customer: {
    __typename?: 'Customer'
    id: string
    name?: string | null
    applicableTimezone: TimezoneEnum
  }
}

export type DownloadInvoiceItemMutationVariables = Exact<{
  input: DownloadInvoiceInput
}>

export type DownloadInvoiceItemMutation = {
  __typename?: 'Mutation'
  downloadInvoice?: { __typename?: 'Invoice'; id: string; fileUrl?: string | null } | null
}

export type RetryInvoicePaymentMutationVariables = Exact<{
  input: RetryInvoicePaymentInput
}>

export type RetryInvoicePaymentMutation = {
  __typename?: 'Mutation'
  retryInvoicePayment?: {
    __typename?: 'Invoice'
    id: string
    status: InvoiceStatusTypeEnum
    paymentStatus: InvoicePaymentStatusTypeEnum
    number: string
    issuingDate: any
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
    customer: {
      __typename?: 'Customer'
      id: string
      name?: string | null
      applicableTimezone: TimezoneEnum
    }
  } | null
}

export type BillableMetricForPlanFragment = {
  __typename?: 'BillableMetric'
  id: string
  name: string
  code: string
  flatGroups?: Array<{
    __typename?: 'Group'
    id: string
    key?: string | null
    value: string
  }> | null
}

export type GetbillableMetricsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetbillableMetricsQuery = {
  __typename?: 'Query'
  billableMetrics: {
    __typename?: 'BillableMetricCollection'
    collection: Array<{
      __typename?: 'BillableMetric'
      id: string
      name: string
      code: string
      flatGroups?: Array<{
        __typename?: 'Group'
        id: string
        key?: string | null
        value: string
      }> | null
    }>
  }
}

export type ChargeAccordionFragment = {
  __typename?: 'Charge'
  id: string
  chargeModel: ChargeModelEnum
  properties?: {
    __typename?: 'Properties'
    amount?: string | null
    packageSize?: number | null
    freeUnits?: number | null
    fixedAmount?: string | null
    freeUnitsPerEvents?: number | null
    freeUnitsPerTotalAggregation?: string | null
    rate?: string | null
    graduatedRanges?: Array<{
      __typename?: 'GraduatedRange'
      flatAmount: string
      fromValue: number
      perUnitAmount: string
      toValue?: number | null
    }> | null
    volumeRanges?: Array<{
      __typename?: 'VolumeRange'
      flatAmount: string
      fromValue: number
      perUnitAmount: string
      toValue?: number | null
    }> | null
  } | null
  groupProperties?: Array<{
    __typename?: 'GroupProperties'
    groupId: string
    values: {
      __typename?: 'Properties'
      amount?: string | null
      packageSize?: number | null
      freeUnits?: number | null
      fixedAmount?: string | null
      freeUnitsPerEvents?: number | null
      freeUnitsPerTotalAggregation?: string | null
      rate?: string | null
      graduatedRanges?: Array<{
        __typename?: 'GraduatedRange'
        flatAmount: string
        fromValue: number
        perUnitAmount: string
        toValue?: number | null
      }> | null
      volumeRanges?: Array<{
        __typename?: 'VolumeRange'
        flatAmount: string
        fromValue: number
        perUnitAmount: string
        toValue?: number | null
      }> | null
    }
  }> | null
  billableMetric: {
    __typename?: 'BillableMetric'
    id: string
    name: string
    code: string
    flatGroups?: Array<{
      __typename?: 'Group'
      id: string
      key?: string | null
      value: string
    }> | null
  }
}

export type PercentageChargeFragment = {
  __typename?: 'Charge'
  id: string
  properties?: {
    __typename?: 'Properties'
    fixedAmount?: string | null
    freeUnitsPerEvents?: number | null
    freeUnitsPerTotalAggregation?: string | null
    rate?: string | null
  } | null
  groupProperties?: Array<{
    __typename?: 'GroupProperties'
    groupId: string
    values: {
      __typename?: 'Properties'
      fixedAmount?: string | null
      freeUnitsPerEvents?: number | null
      freeUnitsPerTotalAggregation?: string | null
      rate?: string | null
    }
  }> | null
}

export type DeletePlanDialogFragment = {
  __typename?: 'Plan'
  id: string
  name: string
  draftInvoicesCount: number
  activeSubscriptionsCount: number
}

export type DeletePlanMutationVariables = Exact<{
  input: DestroyPlanInput
}>

export type DeletePlanMutation = {
  __typename?: 'Mutation'
  destroyPlan?: { __typename?: 'DestroyPlanPayload'; id?: string | null } | null
}

export type GraduatedChargeFragment = {
  __typename?: 'Charge'
  id: string
  properties?: {
    __typename?: 'Properties'
    graduatedRanges?: Array<{
      __typename?: 'GraduatedRange'
      flatAmount: string
      fromValue: number
      perUnitAmount: string
      toValue?: number | null
    }> | null
  } | null
  groupProperties?: Array<{
    __typename?: 'GroupProperties'
    groupId: string
    values: {
      __typename?: 'Properties'
      graduatedRanges?: Array<{
        __typename?: 'GraduatedRange'
        flatAmount: string
        fromValue: number
        perUnitAmount: string
        toValue?: number | null
      }> | null
    }
  }> | null
}

export type PackageChargeFragment = {
  __typename?: 'Charge'
  id: string
  properties?: {
    __typename?: 'Properties'
    amount?: string | null
    packageSize?: number | null
    freeUnits?: number | null
  } | null
  groupProperties?: Array<{
    __typename?: 'GroupProperties'
    groupId: string
    values: {
      __typename?: 'Properties'
      amount?: string | null
      packageSize?: number | null
      freeUnits?: number | null
    }
  }> | null
}

export type PlanItemFragment = {
  __typename?: 'Plan'
  id: string
  name: string
  code: string
  chargeCount: number
  customerCount: number
  createdAt: any
  draftInvoicesCount: number
  activeSubscriptionsCount: number
}

export type VolumeRangesFragment = {
  __typename?: 'Charge'
  properties?: {
    __typename?: 'Properties'
    volumeRanges?: Array<{
      __typename?: 'VolumeRange'
      flatAmount: string
      fromValue: number
      perUnitAmount: string
      toValue?: number | null
    }> | null
  } | null
  groupProperties?: Array<{
    __typename?: 'GroupProperties'
    groupId: string
    values: {
      __typename?: 'Properties'
      volumeRanges?: Array<{
        __typename?: 'VolumeRange'
        flatAmount: string
        fromValue: number
        perUnitAmount: string
        toValue?: number | null
      }> | null
    }
  }> | null
}

export type UpdateOrganizationGracePeriodMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationGracePeriodMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    billingConfiguration?: {
      __typename?: 'OrganizationBillingConfiguration'
      id: string
      invoiceGracePeriod: number
    } | null
  } | null
}

export type EditOrganizationInformationsDialogFragment = {
  __typename?: 'Organization'
  id: string
  logoUrl?: string | null
  name: string
  legalName?: string | null
  legalNumber?: string | null
  email?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  zipcode?: string | null
  city?: string | null
  state?: string | null
  country?: CountryCode | null
}

export type UpdateOrganizationInformationsMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationInformationsMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    logoUrl?: string | null
    name: string
    legalName?: string | null
    legalNumber?: string | null
    email?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    zipcode?: string | null
    city?: string | null
    state?: string | null
    country?: CountryCode | null
    timezone?: TimezoneEnum | null
  } | null
}

export type EditOrganizationInvoiceTemplateDialogFragment = {
  __typename?: 'Organization'
  billingConfiguration?: {
    __typename?: 'OrganizationBillingConfiguration'
    id: string
    invoiceFooter?: string | null
  } | null
}

export type UpdateOrganizationInvoiceTemplateMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationInvoiceTemplateMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    billingConfiguration?: {
      __typename?: 'OrganizationBillingConfiguration'
      id: string
      invoiceFooter?: string | null
    } | null
  } | null
}

export type UpdateOrganizationTimezoneMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationTimezoneMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    timezone?: TimezoneEnum | null
  } | null
}

export type UpdateVatRateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateVatRateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'Organization'
    id: string
    billingConfiguration?: {
      __typename?: 'OrganizationBillingConfiguration'
      id: string
      vatRate: number
    } | null
  } | null
}

export type AddStripeApiKeyMutationVariables = Exact<{
  input: AddStripePaymentProviderInput
}>

export type AddStripeApiKeyMutation = {
  __typename?: 'Mutation'
  addStripePaymentProvider?: {
    __typename?: 'StripeProvider'
    id: string
    secretKey: string
    createCustomers: boolean
  } | null
}

export type DeleteStripeMutationVariables = Exact<{
  input: DestroyPaymentProviderInput
}>

export type DeleteStripeMutation = {
  __typename?: 'Mutation'
  destroyPaymentProvider?: {
    __typename?: 'DestroyPaymentProviderPayload'
    id?: string | null
  } | null
}

export type CreateInviteMutationVariables = Exact<{
  input: CreateInviteInput
}>

export type CreateInviteMutation = {
  __typename?: 'Mutation'
  createInvite?: { __typename?: 'Invite'; id: string; token: string } | null
}

export type InviteItemFragment = {
  __typename?: 'Invite'
  id: string
  email: string
  token: string
  organization: { __typename?: 'Organization'; id: string; name: string }
}

export type MembershipItemFragment = {
  __typename?: 'Membership'
  id: string
  user: { __typename?: 'User'; id: string; email?: string | null }
}

export type RevokeInviteMutationVariables = Exact<{
  input: RevokeInviteInput
}>

export type RevokeInviteMutation = {
  __typename?: 'Mutation'
  revokeInvite?: { __typename?: 'Invite'; id: string } | null
}

export type RevokeMembershipMutationVariables = Exact<{
  input: RevokeMembershipInput
}>

export type RevokeMembershipMutation = {
  __typename?: 'Mutation'
  revokeMembership?: { __typename?: 'Membership'; id: string } | null
}

export type CreateCustomerWalletMutationVariables = Exact<{
  input: CreateCustomerWalletInput
}>

export type CreateCustomerWalletMutation = {
  __typename?: 'Mutation'
  createCustomerWallet?: { __typename?: 'Wallet'; id: string } | null
}

export type GetCustomerWalletListQueryVariables = Exact<{
  customerId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetCustomerWalletListQuery = {
  __typename?: 'Query'
  wallets: {
    __typename?: 'WalletCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'Wallet'
      id: string
      currency: CurrencyEnum
      rateAmount: string
      name?: string | null
      expirationAt?: any | null
      balance: string
      consumedAmount: string
      consumedCredits: string
      createdAt: any
      creditsBalance: string
      lastBalanceSyncAt?: any | null
      lastConsumedCreditAt?: any | null
      status: WalletStatusEnum
      terminatedAt?: any | null
    }>
  }
}

export type TerminateCustomerWalletMutationVariables = Exact<{
  input: TerminateCustomerWalletInput
}>

export type TerminateCustomerWalletMutation = {
  __typename?: 'Mutation'
  terminateCustomerWallet?: {
    __typename?: 'Wallet'
    id: string
    status: WalletStatusEnum
    balance: string
    consumedAmount: string
    consumedCredits: string
    createdAt: any
    creditsBalance: string
    currency: CurrencyEnum
    expirationAt?: any | null
    lastBalanceSyncAt?: any | null
    lastConsumedCreditAt?: any | null
    name?: string | null
    rateAmount: string
    terminatedAt?: any | null
  } | null
}

export type CreateCustomerWalletTransactionMutationVariables = Exact<{
  input: CreateCustomerWalletTransactionInput
}>

export type CreateCustomerWalletTransactionMutation = {
  __typename?: 'Mutation'
  createCustomerWalletTransaction?: {
    __typename?: 'WalletTransactionCollection'
    collection: Array<{ __typename?: 'WalletTransaction'; id: string }>
  } | null
}

export type WalletForTopupFragment = {
  __typename?: 'Wallet'
  id: string
  currency: CurrencyEnum
  rateAmount: string
}

export type UpdateCustomerWalletMutationVariables = Exact<{
  input: UpdateCustomerWalletInput
}>

export type UpdateCustomerWalletMutation = {
  __typename?: 'Mutation'
  updateCustomerWallet?: {
    __typename?: 'Wallet'
    id: string
    name?: string | null
    expirationAt?: any | null
  } | null
}

export type WalletForUpdateFragment = {
  __typename?: 'Wallet'
  id: string
  name?: string | null
  expirationAt?: any | null
}

export type WalletAccordionFragment = {
  __typename?: 'Wallet'
  id: string
  balance: string
  consumedAmount: string
  consumedCredits: string
  createdAt: any
  creditsBalance: string
  currency: CurrencyEnum
  expirationAt?: any | null
  lastBalanceSyncAt?: any | null
  lastConsumedCreditAt?: any | null
  name?: string | null
  rateAmount: string
  status: WalletStatusEnum
  terminatedAt?: any | null
}

export type GetWalletTransactionsQueryVariables = Exact<{
  walletId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetWalletTransactionsQuery = {
  __typename?: 'Query'
  walletTransactions: {
    __typename?: 'WalletTransactionCollection'
    metadata: {
      __typename?: 'CollectionMetadata'
      currentPage: number
      totalPages: number
      totalCount: number
    }
    collection: Array<{
      __typename?: 'WalletTransaction'
      id: string
      status: WalletTransactionStatusEnum
      transactionType: WalletTransactionTransactionTypeEnum
      amount: string
      creditAmount: string
      settledAt?: any | null
      createdAt: any
    }>
  }
}

export type WalletInfosForTransactionsFragment = {
  __typename?: 'Wallet'
  id: string
  currency: CurrencyEnum
  status: WalletStatusEnum
}

export type CurrentUserFragment = {
  __typename?: 'User'
  id: string
  organizations?: Array<{
    __typename?: 'Organization'
    id: string
    timezone?: TimezoneEnum | null
  }> | null
}

export type AddSubscriptionPlanFragment = {
  __typename?: 'Plan'
  id: string
  name: string
  code: string
  interval: PlanInterval
}

export type GetPlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetPlansQuery = {
  __typename?: 'Query'
  plans: {
    __typename?: 'PlanCollection'
    collection: Array<{
      __typename?: 'Plan'
      id: string
      name: string
      code: string
      interval: PlanInterval
    }>
  }
}

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput
}>

export type CreateSubscriptionMutation = {
  __typename?: 'Mutation'
  createSubscription?: {
    __typename?: 'Subscription'
    id: string
    status?: StatusTypeEnum | null
    startedAt?: any | null
    nextPendingStartDate?: any | null
    name?: string | null
    nextName?: string | null
    externalId: string
    periodEndDate?: any | null
    subscriptionAt?: any | null
    plan: { __typename?: 'Plan'; id: string; name: string; code: string }
    nextPlan?: { __typename?: 'Plan'; id: string; name: string; code: string } | null
  } | null
}

export type GetSinglePlanQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSinglePlanQuery = {
  __typename?: 'Query'
  plan?: {
    __typename?: 'PlanDetails'
    id: string
    name: string
    code: string
    description?: string | null
    interval: PlanInterval
    payInAdvance: boolean
    amountCents: any
    amountCurrency: CurrencyEnum
    trialPeriod?: number | null
    subscriptionsCount: number
    billChargesMonthly?: boolean | null
    charges?: Array<{
      __typename?: 'Charge'
      id: string
      chargeModel: ChargeModelEnum
      billableMetric: {
        __typename?: 'BillableMetric'
        id: string
        code: string
        name: string
        flatGroups?: Array<{
          __typename?: 'Group'
          id: string
          key?: string | null
          value: string
        }> | null
      }
      properties?: {
        __typename?: 'Properties'
        amount?: string | null
        packageSize?: number | null
        freeUnits?: number | null
        fixedAmount?: string | null
        freeUnitsPerEvents?: number | null
        freeUnitsPerTotalAggregation?: string | null
        rate?: string | null
        graduatedRanges?: Array<{
          __typename?: 'GraduatedRange'
          flatAmount: string
          fromValue: number
          perUnitAmount: string
          toValue?: number | null
        }> | null
        volumeRanges?: Array<{
          __typename?: 'VolumeRange'
          flatAmount: string
          fromValue: number
          perUnitAmount: string
          toValue?: number | null
        }> | null
      } | null
      groupProperties?: Array<{
        __typename?: 'GroupProperties'
        groupId: string
        values: {
          __typename?: 'Properties'
          amount?: string | null
          packageSize?: number | null
          freeUnits?: number | null
          fixedAmount?: string | null
          freeUnitsPerEvents?: number | null
          freeUnitsPerTotalAggregation?: string | null
          rate?: string | null
          graduatedRanges?: Array<{
            __typename?: 'GraduatedRange'
            flatAmount: string
            fromValue: number
            perUnitAmount: string
            toValue?: number | null
          }> | null
          volumeRanges?: Array<{
            __typename?: 'VolumeRange'
            flatAmount: string
            fromValue: number
            perUnitAmount: string
            toValue?: number | null
          }> | null
        }
      }> | null
    }> | null
  } | null
}

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput
}>

export type CreatePlanMutation = {
  __typename?: 'Mutation'
  createPlan?: { __typename?: 'Plan'; id: string } | null
}

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput
}>

export type UpdatePlanMutation = {
  __typename?: 'Mutation'
  updatePlan?: {
    __typename?: 'Plan'
    id: string
    name: string
    code: string
    chargeCount: number
    customerCount: number
    createdAt: any
    draftInvoicesCount: number
    activeSubscriptionsCount: number
  } | null
}

export type InvoiceFeeFragment = {
  __typename?: 'Fee'
  id: string
  amountCurrency: CurrencyEnum
  feeType: FeeTypesEnum
  vatRate?: number | null
  creditableAmountCents: any
  charge?: {
    __typename?: 'Charge'
    id: string
    billableMetric: { __typename?: 'BillableMetric'; id: string; name: string }
  } | null
  group?: { __typename?: 'Group'; key?: string | null; value: string } | null
}

export type InvoiceCreateCreditNoteFragment = {
  __typename?: 'Invoice'
  id: string
  refundableAmountCents: any
  creditableAmountCents: any
  invoiceType: InvoiceTypeEnum
  amountCurrency: CurrencyEnum
  number: string
  paymentStatus: InvoicePaymentStatusTypeEnum
  subTotalVatIncludedAmountCents: any
  vatRate: number
  fees?: Array<{
    __typename?: 'Fee'
    id: string
    amountCurrency: CurrencyEnum
    itemCode: string
    itemName: string
    creditableAmountCents: any
    vatRate?: number | null
  }> | null
  invoiceSubscriptions?: Array<{
    __typename?: 'InvoiceSubscription'
    subscription: {
      __typename?: 'Subscription'
      id: string
      name?: string | null
      plan: { __typename?: 'Plan'; id: string; name: string }
    }
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCurrency: CurrencyEnum
      feeType: FeeTypesEnum
      vatRate?: number | null
      creditableAmountCents: any
      charge?: {
        __typename?: 'Charge'
        id: string
        billableMetric: { __typename?: 'BillableMetric'; id: string; name: string }
      } | null
      group?: { __typename?: 'Group'; key?: string | null; value: string } | null
    }> | null
  }> | null
}

export type GetInvoiceCreateCreditNoteQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetInvoiceCreateCreditNoteQuery = {
  __typename?: 'Query'
  invoice?: {
    __typename?: 'Invoice'
    id: string
    refundableAmountCents: any
    creditableAmountCents: any
    invoiceType: InvoiceTypeEnum
    amountCurrency: CurrencyEnum
    number: string
    paymentStatus: InvoicePaymentStatusTypeEnum
    subTotalVatIncludedAmountCents: any
    vatRate: number
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCurrency: CurrencyEnum
      itemCode: string
      itemName: string
      creditableAmountCents: any
      vatRate?: number | null
    }> | null
    invoiceSubscriptions?: Array<{
      __typename?: 'InvoiceSubscription'
      subscription: {
        __typename?: 'Subscription'
        id: string
        name?: string | null
        plan: { __typename?: 'Plan'; id: string; name: string }
      }
      fees?: Array<{
        __typename?: 'Fee'
        id: string
        amountCurrency: CurrencyEnum
        feeType: FeeTypesEnum
        vatRate?: number | null
        creditableAmountCents: any
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: { __typename?: 'BillableMetric'; id: string; name: string }
        } | null
        group?: { __typename?: 'Group'; key?: string | null; value: string } | null
      }> | null
    }> | null
  } | null
}

export type CreateCreditNoteMutationVariables = Exact<{
  input: CreateCreditNoteInput
}>

export type CreateCreditNoteMutation = {
  __typename?: 'Mutation'
  createCreditNote?: { __typename?: 'CreditNote'; id: string } | null
}

export type EditAddOnFragment = {
  __typename?: 'AddOnDetails'
  id: string
  name: string
  code: string
  description?: string | null
  amountCents: any
  amountCurrency: CurrencyEnum
  appliedAddOnsCount: number
}

export type GetSingleAddOnQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSingleAddOnQuery = {
  __typename?: 'Query'
  addOn?: {
    __typename?: 'AddOnDetails'
    id: string
    name: string
    code: string
    description?: string | null
    amountCents: any
    amountCurrency: CurrencyEnum
    appliedAddOnsCount: number
  } | null
}

export type CreateAddOnMutationVariables = Exact<{
  input: CreateAddOnInput
}>

export type CreateAddOnMutation = {
  __typename?: 'Mutation'
  createAddOn?: { __typename?: 'AddOn'; id: string } | null
}

export type UpdateAddOnMutationVariables = Exact<{
  input: UpdateAddOnInput
}>

export type UpdateAddOnMutation = {
  __typename?: 'Mutation'
  updateAddOn?: {
    __typename?: 'AddOn'
    id: string
    name: string
    amountCurrency: CurrencyEnum
    amountCents: any
    customerCount: number
    createdAt: any
    appliedAddOnsCount: number
  } | null
}

export type GetSingleBillableMetricQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSingleBillableMetricQuery = {
  __typename?: 'Query'
  billableMetric?: {
    __typename?: 'BillableMetricDetail'
    id: string
    name: string
    code: string
    description?: string | null
    group?: any | null
    aggregationType: AggregationTypeEnum
    fieldName?: string | null
    subscriptionsCount: number
  } | null
}

export type CreateBillableMetricMutationVariables = Exact<{
  input: CreateBillableMetricInput
}>

export type CreateBillableMetricMutation = {
  __typename?: 'Mutation'
  createBillableMetric?: { __typename?: 'BillableMetric'; id: string; group?: any | null } | null
}

export type UpdateBillableMetricMutationVariables = Exact<{
  input: UpdateBillableMetricInput
}>

export type UpdateBillableMetricMutation = {
  __typename?: 'Mutation'
  updateBillableMetric?: {
    __typename?: 'BillableMetric'
    id: string
    name: string
    code: string
    createdAt: any
    draftInvoicesCount: number
    activeSubscriptionsCount: number
  } | null
}

export type EditCouponFragment = {
  __typename?: 'CouponDetails'
  id: string
  amountCents?: any | null
  name: string
  amountCurrency?: CurrencyEnum | null
  code?: string | null
  reusable: boolean
  expiration: CouponExpiration
  expirationAt?: any | null
  couponType: CouponTypeEnum
  percentageRate?: number | null
  frequency: CouponFrequency
  frequencyDuration?: number | null
  appliedCouponsCount: number
  limitedPlans: boolean
  plans?: Array<{ __typename?: 'Plan'; id: string; name: string; code: string }> | null
}

export type GetSingleCouponQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSingleCouponQuery = {
  __typename?: 'Query'
  coupon?: {
    __typename?: 'CouponDetails'
    id: string
    amountCents?: any | null
    name: string
    amountCurrency?: CurrencyEnum | null
    code?: string | null
    reusable: boolean
    expiration: CouponExpiration
    expirationAt?: any | null
    couponType: CouponTypeEnum
    percentageRate?: number | null
    frequency: CouponFrequency
    frequencyDuration?: number | null
    appliedCouponsCount: number
    limitedPlans: boolean
    plans?: Array<{ __typename?: 'Plan'; id: string; name: string; code: string }> | null
  } | null
}

export type CreateCouponMutationVariables = Exact<{
  input: CreateCouponInput
}>

export type CreateCouponMutation = {
  __typename?: 'Mutation'
  createCoupon?: { __typename?: 'Coupon'; id: string } | null
}

export type UpdateCouponMutationVariables = Exact<{
  input: UpdateCouponInput
}>

export type UpdateCouponMutation = {
  __typename?: 'Mutation'
  updateCoupon?: {
    __typename?: 'Coupon'
    id: string
    name: string
    customerCount: number
    status: CouponStatusEnum
    amountCurrency?: CurrencyEnum | null
    amountCents?: any | null
    appliedCouponsCount: number
    expiration: CouponExpiration
    expirationAt?: any | null
    couponType: CouponTypeEnum
    percentageRate?: number | null
    frequency: CouponFrequency
    frequencyDuration?: number | null
  } | null
}

export type AddCustomerDrawerFragment = {
  __typename?: 'Customer'
  id: string
  name?: string | null
  externalId: string
  legalName?: string | null
  legalNumber?: string | null
  phone?: string | null
  email?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  state?: string | null
  country?: CountryCode | null
  currency?: CurrencyEnum | null
  canEditAttributes: boolean
  city?: string | null
  zipcode?: string | null
  paymentProvider?: ProviderTypeEnum | null
  timezone?: TimezoneEnum | null
  providerCustomer?: {
    __typename?: 'ProviderCustomer'
    id: string
    providerCustomerId?: string | null
    syncWithProvider?: boolean | null
  } | null
}

export type AddCustomerDrawerDetailFragment = {
  __typename?: 'CustomerDetails'
  id: string
  name?: string | null
  externalId: string
  legalName?: string | null
  legalNumber?: string | null
  phone?: string | null
  email?: string | null
  currency?: CurrencyEnum | null
  canEditAttributes: boolean
  addressLine1?: string | null
  addressLine2?: string | null
  state?: string | null
  country?: CountryCode | null
  city?: string | null
  zipcode?: string | null
  applicableTimezone: TimezoneEnum
  paymentProvider?: ProviderTypeEnum | null
  timezone?: TimezoneEnum | null
  providerCustomer?: {
    __typename?: 'ProviderCustomer'
    id: string
    providerCustomerId?: string | null
    syncWithProvider?: boolean | null
  } | null
}

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput
}>

export type CreateCustomerMutation = {
  __typename?: 'Mutation'
  createCustomer?: {
    __typename?: 'Customer'
    id: string
    name?: string | null
    externalId: string
    legalName?: string | null
    legalNumber?: string | null
    phone?: string | null
    email?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    state?: string | null
    country?: CountryCode | null
    currency?: CurrencyEnum | null
    canEditAttributes: boolean
    city?: string | null
    zipcode?: string | null
    paymentProvider?: ProviderTypeEnum | null
    timezone?: TimezoneEnum | null
    createdAt: any
    activeSubscriptionCount: number
    providerCustomer?: {
      __typename?: 'ProviderCustomer'
      id: string
      providerCustomerId?: string | null
      syncWithProvider?: boolean | null
    } | null
  } | null
}

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput
}>

export type UpdateCustomerMutation = {
  __typename?: 'Mutation'
  updateCustomer?: {
    __typename?: 'Customer'
    id: string
    name?: string | null
    externalId: string
    legalName?: string | null
    legalNumber?: string | null
    phone?: string | null
    email?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    state?: string | null
    country?: CountryCode | null
    currency?: CurrencyEnum | null
    canEditAttributes: boolean
    city?: string | null
    zipcode?: string | null
    paymentProvider?: ProviderTypeEnum | null
    timezone?: TimezoneEnum | null
    createdAt: any
    activeSubscriptionCount: number
    providerCustomer?: {
      __typename?: 'ProviderCustomer'
      id: string
      providerCustomerId?: string | null
      syncWithProvider?: boolean | null
    } | null
  } | null
}

export type CurrentUserInfosFragment = {
  __typename?: 'User'
  id: string
  email?: string | null
  premium: boolean
  organizations?: Array<{
    __typename?: 'Organization'
    id: string
    name: string
    logoUrl?: string | null
  }> | null
}

export type GetCurrentUserInfosQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserInfosQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'User'
    id: string
    email?: string | null
    premium: boolean
    organizations?: Array<{
      __typename?: 'Organization'
      id: string
      name: string
      logoUrl?: string | null
    }> | null
  }
}

export type MainOrganizationInfosFragment = {
  __typename?: 'Organization'
  id: string
  name: string
  logoUrl?: string | null
  timezone?: TimezoneEnum | null
}

export type GetOrganizationInfosQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationInfosQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    name: string
    logoUrl?: string | null
    timezone?: TimezoneEnum | null
  } | null
}

export type AllInvoiceDetailsForCustomerInvoiceDetailsFragment = {
  __typename?: 'Invoice'
  invoiceType: InvoiceTypeEnum
  number: string
  paymentStatus: InvoicePaymentStatusTypeEnum
  status: InvoiceStatusTypeEnum
  totalAmountCents: any
  totalAmountCurrency: CurrencyEnum
  refundableAmountCents: any
  creditableAmountCents: any
  id: string
  issuingDate: any
  subTotalVatExcludedAmountCents: any
  couponTotalAmountCents: any
  creditAmountCurrency: CurrencyEnum
  subTotalVatIncludedAmountCents: any
  vatAmountCents: any
  vatAmountCurrency: CurrencyEnum
  walletTransactionAmountCents: any
  creditNoteTotalAmountCents: any
  customer: {
    __typename?: 'Customer'
    id: string
    applicableTimezone: TimezoneEnum
    currency?: CurrencyEnum | null
    name?: string | null
    legalName?: string | null
    email?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    state?: string | null
    country?: CountryCode | null
    city?: string | null
    zipcode?: string | null
    deletedAt?: any | null
  }
  creditNotes?: Array<{
    __typename?: 'CreditNote'
    id: string
    creditAmountCurrency: CurrencyEnum
    number: string
    subTotalVatExcludedAmountCents: any
    subTotalVatExcludedAmountCurrency: CurrencyEnum
    totalAmountCents: any
    vatAmountCents: any
    vatAmountCurrency: CurrencyEnum
    items: Array<{
      __typename?: 'CreditNoteItem'
      amountCents: any
      amountCurrency: CurrencyEnum
      fee: {
        __typename?: 'Fee'
        id: string
        amountCents: any
        amountCurrency: CurrencyEnum
        eventsCount?: any | null
        units: number
        feeType: FeeTypesEnum
        itemName: string
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: {
            __typename?: 'BillableMetric'
            id: string
            name: string
            aggregationType: AggregationTypeEnum
          }
        } | null
        subscription?: {
          __typename?: 'Subscription'
          id: string
          name?: string | null
          plan: { __typename?: 'Plan'; id: string; name: string }
        } | null
        group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
      }
    }>
  }> | null
  fees?: Array<{
    __typename?: 'Fee'
    id: string
    amountCents: any
    itemName: string
    units: number
    feeType: FeeTypesEnum
  }> | null
  invoiceSubscriptions?: Array<{
    __typename?: 'InvoiceSubscription'
    subscription: {
      __typename?: 'Subscription'
      id: string
      name?: string | null
      subscriptionAt?: any | null
      periodEndDate?: any | null
      plan: {
        __typename?: 'Plan'
        id: string
        name: string
        interval: PlanInterval
        amountCents: any
        amountCurrency: CurrencyEnum
      }
    }
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCents: any
      eventsCount?: any | null
      units: number
      feeType: FeeTypesEnum
      charge?: {
        __typename?: 'Charge'
        id: string
        billableMetric: {
          __typename?: 'BillableMetric'
          id: string
          name: string
          aggregationType: AggregationTypeEnum
        }
      } | null
      group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
    }> | null
  }> | null
}

export type GetInvoiceDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetInvoiceDetailsQuery = {
  __typename?: 'Query'
  invoice?: {
    __typename?: 'Invoice'
    id: string
    invoiceType: InvoiceTypeEnum
    number: string
    paymentStatus: InvoicePaymentStatusTypeEnum
    status: InvoiceStatusTypeEnum
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
    refundableAmountCents: any
    creditableAmountCents: any
    issuingDate: any
    subTotalVatExcludedAmountCents: any
    couponTotalAmountCents: any
    creditAmountCurrency: CurrencyEnum
    subTotalVatIncludedAmountCents: any
    vatAmountCents: any
    vatAmountCurrency: CurrencyEnum
    walletTransactionAmountCents: any
    creditNoteTotalAmountCents: any
    customer: {
      __typename?: 'Customer'
      id: string
      applicableTimezone: TimezoneEnum
      currency?: CurrencyEnum | null
      name?: string | null
      legalName?: string | null
      email?: string | null
      addressLine1?: string | null
      addressLine2?: string | null
      state?: string | null
      country?: CountryCode | null
      city?: string | null
      zipcode?: string | null
      deletedAt?: any | null
    }
    creditNotes?: Array<{
      __typename?: 'CreditNote'
      id: string
      creditAmountCurrency: CurrencyEnum
      number: string
      subTotalVatExcludedAmountCents: any
      subTotalVatExcludedAmountCurrency: CurrencyEnum
      totalAmountCents: any
      vatAmountCents: any
      vatAmountCurrency: CurrencyEnum
      items: Array<{
        __typename?: 'CreditNoteItem'
        amountCents: any
        amountCurrency: CurrencyEnum
        fee: {
          __typename?: 'Fee'
          id: string
          amountCents: any
          amountCurrency: CurrencyEnum
          eventsCount?: any | null
          units: number
          feeType: FeeTypesEnum
          itemName: string
          charge?: {
            __typename?: 'Charge'
            id: string
            billableMetric: {
              __typename?: 'BillableMetric'
              id: string
              name: string
              aggregationType: AggregationTypeEnum
            }
          } | null
          subscription?: {
            __typename?: 'Subscription'
            id: string
            name?: string | null
            plan: { __typename?: 'Plan'; id: string; name: string }
          } | null
          group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
        }
      }>
    }> | null
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCents: any
      itemName: string
      units: number
      feeType: FeeTypesEnum
    }> | null
    invoiceSubscriptions?: Array<{
      __typename?: 'InvoiceSubscription'
      subscription: {
        __typename?: 'Subscription'
        id: string
        name?: string | null
        subscriptionAt?: any | null
        periodEndDate?: any | null
        plan: {
          __typename?: 'Plan'
          id: string
          name: string
          interval: PlanInterval
          amountCents: any
          amountCurrency: CurrencyEnum
        }
      }
      fees?: Array<{
        __typename?: 'Fee'
        id: string
        amountCents: any
        eventsCount?: any | null
        units: number
        feeType: FeeTypesEnum
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: {
            __typename?: 'BillableMetric'
            id: string
            name: string
            aggregationType: AggregationTypeEnum
          }
        } | null
        group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
      }> | null
    }> | null
  } | null
}

export type DownloadInvoiceMutationVariables = Exact<{
  input: DownloadInvoiceInput
}>

export type DownloadInvoiceMutation = {
  __typename?: 'Mutation'
  downloadInvoice?: { __typename?: 'Invoice'; id: string; fileUrl?: string | null } | null
}

export type RefreshInvoiceMutationVariables = Exact<{
  input: RefreshInvoiceInput
}>

export type RefreshInvoiceMutation = {
  __typename?: 'Mutation'
  refreshInvoice?: {
    __typename?: 'Invoice'
    id: string
    invoiceType: InvoiceTypeEnum
    number: string
    paymentStatus: InvoicePaymentStatusTypeEnum
    status: InvoiceStatusTypeEnum
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
    refundableAmountCents: any
    creditableAmountCents: any
    issuingDate: any
    subTotalVatExcludedAmountCents: any
    couponTotalAmountCents: any
    creditAmountCurrency: CurrencyEnum
    subTotalVatIncludedAmountCents: any
    vatAmountCents: any
    vatAmountCurrency: CurrencyEnum
    walletTransactionAmountCents: any
    creditNoteTotalAmountCents: any
    customer: {
      __typename?: 'Customer'
      id: string
      applicableTimezone: TimezoneEnum
      currency?: CurrencyEnum | null
      name?: string | null
      legalName?: string | null
      email?: string | null
      addressLine1?: string | null
      addressLine2?: string | null
      state?: string | null
      country?: CountryCode | null
      city?: string | null
      zipcode?: string | null
      deletedAt?: any | null
    }
    creditNotes?: Array<{
      __typename?: 'CreditNote'
      id: string
      creditAmountCurrency: CurrencyEnum
      number: string
      subTotalVatExcludedAmountCents: any
      subTotalVatExcludedAmountCurrency: CurrencyEnum
      totalAmountCents: any
      vatAmountCents: any
      vatAmountCurrency: CurrencyEnum
      items: Array<{
        __typename?: 'CreditNoteItem'
        amountCents: any
        amountCurrency: CurrencyEnum
        fee: {
          __typename?: 'Fee'
          id: string
          amountCents: any
          amountCurrency: CurrencyEnum
          eventsCount?: any | null
          units: number
          feeType: FeeTypesEnum
          itemName: string
          charge?: {
            __typename?: 'Charge'
            id: string
            billableMetric: {
              __typename?: 'BillableMetric'
              id: string
              name: string
              aggregationType: AggregationTypeEnum
            }
          } | null
          subscription?: {
            __typename?: 'Subscription'
            id: string
            name?: string | null
            plan: { __typename?: 'Plan'; id: string; name: string }
          } | null
          group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
        }
      }>
    }> | null
    fees?: Array<{
      __typename?: 'Fee'
      id: string
      amountCents: any
      itemName: string
      units: number
      feeType: FeeTypesEnum
    }> | null
    invoiceSubscriptions?: Array<{
      __typename?: 'InvoiceSubscription'
      subscription: {
        __typename?: 'Subscription'
        id: string
        name?: string | null
        subscriptionAt?: any | null
        periodEndDate?: any | null
        plan: {
          __typename?: 'Plan'
          id: string
          name: string
          interval: PlanInterval
          amountCents: any
          amountCurrency: CurrencyEnum
        }
      }
      fees?: Array<{
        __typename?: 'Fee'
        id: string
        amountCents: any
        eventsCount?: any | null
        units: number
        feeType: FeeTypesEnum
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: {
            __typename?: 'BillableMetric'
            id: string
            name: string
            aggregationType: AggregationTypeEnum
          }
        } | null
        group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
      }> | null
    }> | null
  } | null
}

export type SideNavInfosQueryVariables = Exact<{ [key: string]: never }>

export type SideNavInfosQuery = {
  __typename?: 'Query'
  currentVersion: { __typename?: 'CurrentVersion'; githubUrl: string; number: string }
}

export type AddOnsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type AddOnsQuery = {
  __typename?: 'Query'
  addOns: {
    __typename?: 'AddOnCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'AddOn'
      id: string
      name: string
      amountCurrency: CurrencyEnum
      amountCents: any
      customerCount: number
      createdAt: any
      appliedAddOnsCount: number
    }>
  }
}

export type BillableMetricsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type BillableMetricsQuery = {
  __typename?: 'Query'
  billableMetrics: {
    __typename?: 'BillableMetricCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'BillableMetric'
      id: string
      name: string
      code: string
      createdAt: any
      draftInvoicesCount: number
      activeSubscriptionsCount: number
    }>
  }
}

export type CouponsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type CouponsQuery = {
  __typename?: 'Query'
  coupons: {
    __typename?: 'CouponCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'Coupon'
      id: string
      name: string
      customerCount: number
      status: CouponStatusEnum
      amountCurrency?: CurrencyEnum | null
      amountCents?: any | null
      appliedCouponsCount: number
      expiration: CouponExpiration
      expirationAt?: any | null
      couponType: CouponTypeEnum
      percentageRate?: number | null
      frequency: CouponFrequency
      frequencyDuration?: number | null
    }>
  }
}

export type EditBillableMetricFragment = {
  __typename?: 'BillableMetricDetail'
  id: string
  name: string
  code: string
  description?: string | null
  group?: any | null
  aggregationType: AggregationTypeEnum
  fieldName?: string | null
  subscriptionsCount: number
}

export type CreateCreditNoteInvoiceFragment = {
  __typename?: 'Invoice'
  id: string
  amountCurrency: CurrencyEnum
  number: string
  paymentStatus: InvoicePaymentStatusTypeEnum
  creditableAmountCents: any
  refundableAmountCents: any
  subTotalVatIncludedAmountCents: any
  vatRate: number
}

export type EditPlanFragment = {
  __typename?: 'PlanDetails'
  id: string
  name: string
  code: string
  description?: string | null
  interval: PlanInterval
  payInAdvance: boolean
  amountCents: any
  amountCurrency: CurrencyEnum
  trialPeriod?: number | null
  subscriptionsCount: number
  billChargesMonthly?: boolean | null
  charges?: Array<{
    __typename?: 'Charge'
    id: string
    chargeModel: ChargeModelEnum
    billableMetric: {
      __typename?: 'BillableMetric'
      id: string
      code: string
      name: string
      flatGroups?: Array<{
        __typename?: 'Group'
        id: string
        key?: string | null
        value: string
      }> | null
    }
    properties?: {
      __typename?: 'Properties'
      amount?: string | null
      packageSize?: number | null
      freeUnits?: number | null
      fixedAmount?: string | null
      freeUnitsPerEvents?: number | null
      freeUnitsPerTotalAggregation?: string | null
      rate?: string | null
      graduatedRanges?: Array<{
        __typename?: 'GraduatedRange'
        flatAmount: string
        fromValue: number
        perUnitAmount: string
        toValue?: number | null
      }> | null
      volumeRanges?: Array<{
        __typename?: 'VolumeRange'
        flatAmount: string
        fromValue: number
        perUnitAmount: string
        toValue?: number | null
      }> | null
    } | null
    groupProperties?: Array<{
      __typename?: 'GroupProperties'
      groupId: string
      values: {
        __typename?: 'Properties'
        amount?: string | null
        packageSize?: number | null
        freeUnits?: number | null
        fixedAmount?: string | null
        freeUnitsPerEvents?: number | null
        freeUnitsPerTotalAggregation?: string | null
        rate?: string | null
        graduatedRanges?: Array<{
          __typename?: 'GraduatedRange'
          flatAmount: string
          fromValue: number
          perUnitAmount: string
          toValue?: number | null
        }> | null
        volumeRanges?: Array<{
          __typename?: 'VolumeRange'
          flatAmount: string
          fromValue: number
          perUnitAmount: string
          toValue?: number | null
        }> | null
      }
    }> | null
  }> | null
}

export type GetCreditNoteQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCreditNoteQuery = {
  __typename?: 'Query'
  creditNote?: {
    __typename?: 'CreditNote'
    id: string
    balanceAmountCents: any
    canBeVoided: boolean
    createdAt: any
    creditAmountCents: any
    creditAmountCurrency: CurrencyEnum
    creditStatus?: CreditNoteCreditStatusEnum | null
    number: string
    refundAmountCents: any
    refundedAt?: any | null
    refundStatus?: CreditNoteRefundStatusEnum | null
    subTotalVatExcludedAmountCents: any
    subTotalVatExcludedAmountCurrency: CurrencyEnum
    totalAmountCents: any
    totalAmountCurrency: CurrencyEnum
    vatAmountCents: any
    vatAmountCurrency: CurrencyEnum
    customer: {
      __typename?: 'Customer'
      id: string
      name?: string | null
      deletedAt?: any | null
      applicableTimezone: TimezoneEnum
    }
    invoice?: { __typename?: 'Invoice'; id: string; number: string } | null
    items: Array<{
      __typename?: 'CreditNoteItem'
      amountCents: any
      amountCurrency: CurrencyEnum
      fee: {
        __typename?: 'Fee'
        id: string
        amountCents: any
        amountCurrency: CurrencyEnum
        eventsCount?: any | null
        units: number
        feeType: FeeTypesEnum
        itemName: string
        charge?: {
          __typename?: 'Charge'
          id: string
          billableMetric: {
            __typename?: 'BillableMetric'
            id: string
            name: string
            aggregationType: AggregationTypeEnum
          }
        } | null
        subscription?: {
          __typename?: 'Subscription'
          id: string
          name?: string | null
          plan: { __typename?: 'Plan'; id: string; name: string }
        } | null
        group?: { __typename?: 'Group'; id: string; key?: string | null; value: string } | null
      }
    }>
  } | null
}

export type CustomerDetailsFragment = {
  __typename?: 'CustomerDetails'
  id: string
  name?: string | null
  externalId: string
  hasActiveWallet: boolean
  currency?: CurrencyEnum | null
  hasCreditNotes: boolean
  creditNotesCreditsAvailableCount: number
  creditNotesBalanceAmountCents: any
  applicableTimezone: TimezoneEnum
  legalName?: string | null
  legalNumber?: string | null
  phone?: string | null
  email?: string | null
  canEditAttributes: boolean
  addressLine1?: string | null
  addressLine2?: string | null
  state?: string | null
  country?: CountryCode | null
  city?: string | null
  zipcode?: string | null
  paymentProvider?: ProviderTypeEnum | null
  timezone?: TimezoneEnum | null
  subscriptions: Array<{
    __typename?: 'Subscription'
    id: string
    status?: StatusTypeEnum | null
    startedAt?: any | null
    nextPendingStartDate?: any | null
    name?: string | null
    nextName?: string | null
    externalId: string
    periodEndDate?: any | null
    subscriptionAt?: any | null
    plan: {
      __typename?: 'Plan'
      id: string
      amountCurrency: CurrencyEnum
      name: string
      code: string
    }
    nextPlan?: { __typename?: 'Plan'; id: string; name: string; code: string } | null
  }>
  appliedCoupons?: Array<{
    __typename?: 'AppliedCoupon'
    id: string
    amountCurrency?: CurrencyEnum | null
    amountCents?: any | null
    amountCentsRemaining?: any | null
    percentageRate?: number | null
    frequency: CouponFrequency
    frequencyDuration?: number | null
    frequencyDurationRemaining?: number | null
    coupon: { __typename?: 'Coupon'; id: string; name: string }
  }> | null
  appliedAddOns?: Array<{
    __typename?: 'AppliedAddOn'
    id: string
    amountCents: any
    amountCurrency: CurrencyEnum
    createdAt: any
    addOn: { __typename?: 'AddOn'; id: string; name: string }
  }> | null
  providerCustomer?: {
    __typename?: 'ProviderCustomer'
    id: string
    providerCustomerId?: string | null
    syncWithProvider?: boolean | null
  } | null
}

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCustomerQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'CustomerDetails'
    id: string
    name?: string | null
    externalId: string
    hasActiveWallet: boolean
    currency?: CurrencyEnum | null
    hasCreditNotes: boolean
    creditNotesCreditsAvailableCount: number
    creditNotesBalanceAmountCents: any
    applicableTimezone: TimezoneEnum
    legalName?: string | null
    legalNumber?: string | null
    phone?: string | null
    email?: string | null
    canEditAttributes: boolean
    addressLine1?: string | null
    addressLine2?: string | null
    state?: string | null
    country?: CountryCode | null
    city?: string | null
    zipcode?: string | null
    paymentProvider?: ProviderTypeEnum | null
    timezone?: TimezoneEnum | null
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      status?: StatusTypeEnum | null
      startedAt?: any | null
      nextPendingStartDate?: any | null
      name?: string | null
      nextName?: string | null
      externalId: string
      periodEndDate?: any | null
      subscriptionAt?: any | null
      plan: {
        __typename?: 'Plan'
        id: string
        amountCurrency: CurrencyEnum
        name: string
        code: string
      }
      nextPlan?: { __typename?: 'Plan'; id: string; name: string; code: string } | null
    }>
    appliedCoupons?: Array<{
      __typename?: 'AppliedCoupon'
      id: string
      amountCurrency?: CurrencyEnum | null
      amountCents?: any | null
      amountCentsRemaining?: any | null
      percentageRate?: number | null
      frequency: CouponFrequency
      frequencyDuration?: number | null
      frequencyDurationRemaining?: number | null
      coupon: { __typename?: 'Coupon'; id: string; name: string }
    }> | null
    appliedAddOns?: Array<{
      __typename?: 'AppliedAddOn'
      id: string
      amountCents: any
      amountCurrency: CurrencyEnum
      createdAt: any
      addOn: { __typename?: 'AddOn'; id: string; name: string }
    }> | null
    providerCustomer?: {
      __typename?: 'ProviderCustomer'
      id: string
      providerCustomerId?: string | null
      syncWithProvider?: boolean | null
    } | null
  } | null
}

export type GetCustomerDraftInvoicesQueryVariables = Exact<{
  customerId: Scalars['ID']
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<InvoiceStatusTypeEnum>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetCustomerDraftInvoicesQuery = {
  __typename?: 'Query'
  customerInvoices: {
    __typename?: 'InvoiceCollection'
    collection: Array<{
      __typename?: 'Invoice'
      id: string
      status: InvoiceStatusTypeEnum
      paymentStatus: InvoicePaymentStatusTypeEnum
      number: string
      issuingDate: any
      totalAmountCents: any
      totalAmountCurrency: CurrencyEnum
      customer: {
        __typename?: 'Customer'
        id: string
        applicableTimezone: TimezoneEnum
        name?: string | null
      }
    }>
    metadata: {
      __typename?: 'CollectionMetadata'
      currentPage: number
      totalCount: number
      totalPages: number
    }
  }
}

export type GetCustomerInfosForDraftInvoicesListQueryVariables = Exact<{
  customerId: Scalars['ID']
  status?: InputMaybe<InvoiceStatusTypeEnum>
}>

export type GetCustomerInfosForDraftInvoicesListQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'CustomerDetails'
    id: string
    name?: string | null
    applicableTimezone: TimezoneEnum
  } | null
  customerInvoices: {
    __typename?: 'InvoiceCollection'
    metadata: { __typename?: 'CollectionMetadata'; totalCount: number }
  }
}

export type CustomersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type CustomersQuery = {
  __typename?: 'Query'
  customers: {
    __typename?: 'CustomerCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'Customer'
      id: string
      name?: string | null
      externalId: string
      createdAt: any
      activeSubscriptionCount: number
      legalName?: string | null
      legalNumber?: string | null
      phone?: string | null
      email?: string | null
      addressLine1?: string | null
      addressLine2?: string | null
      state?: string | null
      country?: CountryCode | null
      currency?: CurrencyEnum | null
      canEditAttributes: boolean
      city?: string | null
      zipcode?: string | null
      paymentProvider?: ProviderTypeEnum | null
      timezone?: TimezoneEnum | null
      providerCustomer?: {
        __typename?: 'ProviderCustomer'
        id: string
        providerCustomerId?: string | null
        syncWithProvider?: boolean | null
      } | null
    }>
  }
}

export type GetinviteQueryVariables = Exact<{
  token: Scalars['String']
}>

export type GetinviteQuery = {
  __typename?: 'Query'
  invite?: {
    __typename?: 'Invite'
    id: string
    email: string
    organization: { __typename?: 'Organization'; id: string; name: string }
  } | null
}

export type AcceptInviteMutationVariables = Exact<{
  input: AcceptInviteInput
}>

export type AcceptInviteMutation = {
  __typename?: 'Mutation'
  acceptInvite?: {
    __typename?: 'RegisterUser'
    token: string
    user: {
      __typename?: 'User'
      id: string
      organizations?: Array<{
        __typename?: 'Organization'
        id: string
        timezone?: TimezoneEnum | null
      }> | null
    }
  } | null
}

export type GetInvoiceCreditNotesQueryVariables = Exact<{
  invoiceId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetInvoiceCreditNotesQuery = {
  __typename?: 'Query'
  invoiceCreditNotes?: {
    __typename?: 'CreditNoteCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'CreditNote'
      id: string
      canBeVoided: boolean
      createdAt: any
      creditStatus?: CreditNoteCreditStatusEnum | null
      number: string
      totalAmountCents: any
      totalAmountCurrency: CurrencyEnum
    }>
  } | null
  invoice?: {
    __typename?: 'Invoice'
    id: string
    refundableAmountCents: any
    creditableAmountCents: any
    status: InvoiceStatusTypeEnum
    customer: { __typename?: 'Customer'; id: string; applicableTimezone: TimezoneEnum }
  } | null
}

export type InvoiceDetailsForInvoiceOverviewFragment = {
  __typename?: 'Invoice'
  id: string
  status: InvoiceStatusTypeEnum
  issuingDate: any
  customer: { __typename?: 'Customer'; id: string; applicableTimezone: TimezoneEnum }
}

export type InvoicesListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<InvoiceStatusTypeEnum>
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatusTypeEnum> | InvoicePaymentStatusTypeEnum>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type InvoicesListQuery = {
  __typename?: 'Query'
  invoices: {
    __typename?: 'InvoiceCollection'
    metadata: {
      __typename?: 'CollectionMetadata'
      currentPage: number
      totalPages: number
      totalCount: number
    }
    collection: Array<{
      __typename?: 'Invoice'
      id: string
      status: InvoiceStatusTypeEnum
      paymentStatus: InvoicePaymentStatusTypeEnum
      number: string
      issuingDate: any
      totalAmountCents: any
      totalAmountCurrency: CurrencyEnum
      customer: {
        __typename?: 'Customer'
        id: string
        name?: string | null
        applicableTimezone: TimezoneEnum
      }
    }>
  }
}

export type RetryAllInvoicePaymentsMutationVariables = Exact<{
  input: RetryAllInvoicePaymentsInput
}>

export type RetryAllInvoicePaymentsMutation = {
  __typename?: 'Mutation'
  retryAllInvoicePayments?: {
    __typename?: 'InvoiceCollection'
    collection: Array<{ __typename?: 'Invoice'; id: string }>
  } | null
}

export type PlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type PlansQuery = {
  __typename?: 'Query'
  plans: {
    __typename?: 'PlanCollection'
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
    collection: Array<{
      __typename?: 'Plan'
      id: string
      name: string
      code: string
      chargeCount: number
      customerCount: number
      createdAt: any
      draftInvoicesCount: number
      activeSubscriptionsCount: number
    }>
  }
}

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput
}>

export type LoginUserMutation = {
  __typename?: 'Mutation'
  loginUser?: {
    __typename?: 'LoginUser'
    token: string
    user: {
      __typename?: 'User'
      id: string
      organizations?: Array<{
        __typename?: 'Organization'
        id: string
        timezone?: TimezoneEnum | null
      }> | null
    }
  } | null
}

export type SignupMutationVariables = Exact<{
  input: RegisterUserInput
}>

export type SignupMutation = {
  __typename?: 'Mutation'
  registerUser?: {
    __typename?: 'RegisterUser'
    token: string
    user: {
      __typename?: 'User'
      id: string
      organizations?: Array<{
        __typename?: 'Organization'
        id: string
        timezone?: TimezoneEnum | null
      }> | null
    }
  } | null
}

export type GetOrganizationApiKeyQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationApiKeyQuery = {
  __typename?: 'Query'
  organization?: { __typename?: 'Organization'; id: string; apiKey: string } | null
}

export type EventListFragment = {
  __typename?: 'Event'
  id: string
  code: string
  externalCustomerId: string
  transactionId?: string | null
  timestamp?: any | null
  receivedAt: any
  payload: any
  billableMetricName?: string | null
  matchBillableMetric: boolean
  matchCustomField: boolean
  apiClient?: string | null
  ipAddress?: string | null
  externalSubscriptionId: string
  customerTimezone: TimezoneEnum
}

export type EventsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type EventsQuery = {
  __typename?: 'Query'
  events?: {
    __typename?: 'EventCollection'
    collection: Array<{
      __typename?: 'Event'
      id: string
      code: string
      externalCustomerId: string
      transactionId?: string | null
      timestamp?: any | null
      receivedAt: any
      payload: any
      billableMetricName?: string | null
      matchBillableMetric: boolean
      matchCustomField: boolean
      apiClient?: string | null
      ipAddress?: string | null
      externalSubscriptionId: string
      customerTimezone: TimezoneEnum
    }>
    metadata: { __typename?: 'CollectionMetadata'; currentPage: number; totalPages: number }
  } | null
}

export type GetWehbookSettingQueryVariables = Exact<{ [key: string]: never }>

export type GetWehbookSettingQuery = {
  __typename?: 'Query'
  organization?: { __typename?: 'Organization'; id: string; webhookUrl?: string | null } | null
}

export type GocardlessIntegrationsSettingQueryVariables = Exact<{ [key: string]: never }>

export type GocardlessIntegrationsSettingQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    gocardlessPaymentProvider?: {
      __typename?: 'GocardlessProvider'
      id: string
      hasAccessToken: boolean
      webhookSecret?: string | null
    } | null
  } | null
}

export type AddGocardlessPaymentProviderMutationVariables = Exact<{
  input: AddGocardlessPaymentProviderInput
}>

export type AddGocardlessPaymentProviderMutation = {
  __typename?: 'Mutation'
  addGocardlessPaymentProvider?: {
    __typename?: 'GocardlessProvider'
    id: string
    hasAccessToken: boolean
    webhookSecret?: string | null
  } | null
}

export type IntegrationsSettingQueryVariables = Exact<{ [key: string]: never }>

export type IntegrationsSettingQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    stripePaymentProvider?: { __typename?: 'StripeProvider'; id: string } | null
    gocardlessPaymentProvider?: { __typename?: 'GocardlessProvider'; id: string } | null
  } | null
}

export type GetOrganizationSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationSettingsQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    billingConfiguration?: {
      __typename?: 'OrganizationBillingConfiguration'
      id: string
      vatRate: number
      invoiceGracePeriod: number
      invoiceFooter?: string | null
    } | null
  } | null
}

export type GetInvitesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetInvitesQuery = {
  __typename?: 'Query'
  invites: {
    __typename?: 'InviteCollection'
    metadata: {
      __typename?: 'CollectionMetadata'
      currentPage: number
      totalPages: number
      totalCount: number
    }
    collection: Array<{
      __typename?: 'Invite'
      id: string
      email: string
      token: string
      organization: { __typename?: 'Organization'; id: string; name: string }
    }>
  }
}

export type GetMembersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetMembersQuery = {
  __typename?: 'Query'
  memberships: {
    __typename?: 'MembershipCollection'
    metadata: {
      __typename?: 'CollectionMetadata'
      currentPage: number
      totalPages: number
      totalCount: number
    }
    collection: Array<{
      __typename?: 'Membership'
      id: string
      user: { __typename?: 'User'; id: string; email?: string | null }
    }>
  }
}

export type OrganizationInformationsFragment = {
  __typename?: 'Organization'
  id: string
  logoUrl?: string | null
  name: string
  legalName?: string | null
  legalNumber?: string | null
  email?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  zipcode?: string | null
  city?: string | null
  state?: string | null
  country?: CountryCode | null
  timezone?: TimezoneEnum | null
}

export type GetOrganizationInformationsQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationInformationsQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    logoUrl?: string | null
    name: string
    legalName?: string | null
    legalNumber?: string | null
    email?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    zipcode?: string | null
    city?: string | null
    state?: string | null
    country?: CountryCode | null
    timezone?: TimezoneEnum | null
  } | null
}

export type StripeIntegrationFragment = {
  __typename?: 'StripeProvider'
  id: string
  secretKey: string
  createCustomers: boolean
}

export type StripeIntegrationsSettingQueryVariables = Exact<{ [key: string]: never }>

export type StripeIntegrationsSettingQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    stripePaymentProvider?: {
      __typename?: 'StripeProvider'
      id: string
      secretKey: string
      createCustomers: boolean
    } | null
  } | null
}

export type UpdateStripeIntegrationMutationVariables = Exact<{
  input: AddStripePaymentProviderInput
}>

export type UpdateStripeIntegrationMutation = {
  __typename?: 'Mutation'
  addStripePaymentProvider?: {
    __typename?: 'StripeProvider'
    id: string
    secretKey: string
    createCustomers: boolean
  } | null
}

export const AddOnItemFragmentDoc = gql`
  fragment AddOnItem on AddOn {
    id
    name
    amountCurrency
    amountCents
    customerCount
    createdAt
    appliedAddOnsCount
  }
`
export const DeleteAddOnFragmentDoc = gql`
  fragment DeleteAddOn on AddOn {
    id
    name
  }
`
export const DeleteBillableMetricDialogFragmentDoc = gql`
  fragment DeleteBillableMetricDialog on BillableMetric {
    id
    name
    draftInvoicesCount
    activeSubscriptionsCount
  }
`
export const BillableMetricItemFragmentDoc = gql`
  fragment BillableMetricItem on BillableMetric {
    id
    name
    code
    createdAt
    ...DeleteBillableMetricDialog
  }
  ${DeleteBillableMetricDialogFragmentDoc}
`
export const CouponCaptionFragmentDoc = gql`
  fragment CouponCaption on Coupon {
    id
    amountCurrency
    amountCents
    couponType
    percentageRate
    frequency
    frequencyDuration
  }
`
export const CouponItemFragmentDoc = gql`
  fragment CouponItem on Coupon {
    id
    name
    customerCount
    status
    amountCurrency
    amountCents
    appliedCouponsCount
    expiration
    expirationAt
    couponType
    percentageRate
    frequency
    frequencyDuration
  }
`
export const DeleteCouponFragmentDoc = gql`
  fragment DeleteCoupon on Coupon {
    id
    name
  }
`
export const TerminateCouponFragmentDoc = gql`
  fragment TerminateCoupon on Coupon {
    id
    name
  }
`
export const CouponPlansForCustomerFragmentDoc = gql`
  fragment CouponPlansForCustomer on Plan {
    id
    name
  }
`
export const InvoiceForFinalizeInvoiceFragmentDoc = gql`
  fragment InvoiceForFinalizeInvoice on Invoice {
    id
    issuingDate
    customer {
      id
      applicableTimezone
    }
  }
`
export const InvoiceListItemFragmentDoc = gql`
  fragment InvoiceListItem on Invoice {
    id
    status
    paymentStatus
    number
    issuingDate
    totalAmountCents
    totalAmountCurrency
    customer {
      id
      name
      applicableTimezone
    }
    ...InvoiceForFinalizeInvoice
  }
  ${InvoiceForFinalizeInvoiceFragmentDoc}
`
export const InvoiceForInvoiceListFragmentDoc = gql`
  fragment InvoiceForInvoiceList on InvoiceCollection {
    collection {
      id
      customer {
        id
        applicableTimezone
      }
      ...InvoiceListItem
    }
    metadata {
      currentPage
      totalCount
      totalPages
    }
  }
  ${InvoiceListItemFragmentDoc}
`
export const AddCustomerDrawerFragmentDoc = gql`
  fragment AddCustomerDrawer on Customer {
    id
    name
    externalId
    legalName
    legalNumber
    phone
    email
    addressLine1
    addressLine2
    state
    country
    currency
    canEditAttributes
    city
    zipcode
    paymentProvider
    timezone
    providerCustomer {
      id
      providerCustomerId
      syncWithProvider
    }
  }
`
export const CustomerItemFragmentDoc = gql`
  fragment CustomerItem on Customer {
    id
    name
    externalId
    createdAt
    activeSubscriptionCount
    ...AddCustomerDrawer
  }
  ${AddCustomerDrawerFragmentDoc}
`
export const DeleteCustomerDialogFragmentDoc = gql`
  fragment DeleteCustomerDialog on Customer {
    id
    name
  }
`
export const DeleteCustomerGracePeriodFragmentDoc = gql`
  fragment DeleteCustomerGracePeriod on CustomerDetails {
    id
    name
  }
`
export const DeleteCustomerVatRateFragmentDoc = gql`
  fragment DeleteCustomerVatRate on CustomerDetails {
    id
    name
  }
`
export const EditCustomerInvoiceGracePeriodFragmentDoc = gql`
  fragment EditCustomerInvoiceGracePeriod on CustomerDetails {
    id
    invoiceGracePeriod
  }
`
export const EditCustomerVatRateFragmentDoc = gql`
  fragment EditCustomerVatRate on CustomerDetails {
    id
    name
    vatRate
  }
`
export const CreditNotesForListFragmentDoc = gql`
  fragment CreditNotesForList on CreditNoteCollection {
    metadata {
      currentPage
      totalPages
    }
    collection {
      id
      canBeVoided
      createdAt
      creditStatus
      number
      totalAmountCents
      totalAmountCurrency
    }
  }
`
export const CustomerUsageForUsageDetailsFragmentDoc = gql`
  fragment CustomerUsageForUsageDetails on CustomerUsage {
    fromDatetime
    toDatetime
    chargesUsage {
      billableMetric {
        name
      }
      groups {
        id
        amountCents
        key
        units
        value
      }
    }
  }
`
export const BillableMetricForPlanFragmentDoc = gql`
  fragment billableMetricForPlan on BillableMetric {
    id
    name
    code
    flatGroups {
      id
      key
      value
    }
  }
`
export const DeletePlanDialogFragmentDoc = gql`
  fragment DeletePlanDialog on Plan {
    id
    name
    draftInvoicesCount
    activeSubscriptionsCount
  }
`
export const PlanItemFragmentDoc = gql`
  fragment PlanItem on Plan {
    id
    name
    code
    chargeCount
    customerCount
    createdAt
    ...DeletePlanDialog
  }
  ${DeletePlanDialogFragmentDoc}
`
export const EditOrganizationInformationsDialogFragmentDoc = gql`
  fragment EditOrganizationInformationsDialog on Organization {
    id
    logoUrl
    name
    legalName
    legalNumber
    email
    addressLine1
    addressLine2
    zipcode
    city
    state
    country
  }
`
export const EditOrganizationInvoiceTemplateDialogFragmentDoc = gql`
  fragment EditOrganizationInvoiceTemplateDialog on Organization {
    billingConfiguration {
      id
      invoiceFooter
    }
  }
`
export const InviteItemFragmentDoc = gql`
  fragment InviteItem on Invite {
    id
    email
    token
    organization {
      id
      name
    }
  }
`
export const MembershipItemFragmentDoc = gql`
  fragment MembershipItem on Membership {
    id
    user {
      id
      email
    }
  }
`
export const WalletForTopupFragmentDoc = gql`
  fragment WalletForTopup on Wallet {
    id
    currency
    rateAmount
  }
`
export const WalletForUpdateFragmentDoc = gql`
  fragment WalletForUpdate on Wallet {
    id
    name
    expirationAt
  }
`
export const WalletAccordionFragmentDoc = gql`
  fragment WalletAccordion on Wallet {
    id
    balance
    consumedAmount
    consumedCredits
    createdAt
    creditsBalance
    currency
    expirationAt
    lastBalanceSyncAt
    lastConsumedCreditAt
    name
    rateAmount
    status
    terminatedAt
  }
`
export const WalletInfosForTransactionsFragmentDoc = gql`
  fragment WalletInfosForTransactions on Wallet {
    id
    currency
    status
  }
`
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    organizations {
      id
      timezone
    }
  }
`
export const AddSubscriptionPlanFragmentDoc = gql`
  fragment AddSubscriptionPlan on Plan {
    id
    name
    code
    interval
  }
`
export const InvoiceFeeFragmentDoc = gql`
  fragment InvoiceFee on Fee {
    id
    amountCurrency
    feeType
    vatRate
    creditableAmountCents
    charge {
      id
      billableMetric {
        id
        name
      }
    }
    group {
      key
      value
    }
  }
`
export const CreditNoteFormFragmentDoc = gql`
  fragment CreditNoteForm on Invoice {
    id
    paymentStatus
    creditableAmountCents
    refundableAmountCents
    vatRate
    amountCurrency
  }
`
export const CreateCreditNoteInvoiceFragmentDoc = gql`
  fragment CreateCreditNoteInvoice on Invoice {
    id
    amountCurrency
    number
    paymentStatus
    creditableAmountCents
    refundableAmountCents
    subTotalVatIncludedAmountCents
    ...CreditNoteForm
  }
  ${CreditNoteFormFragmentDoc}
`
export const InvoiceCreateCreditNoteFragmentDoc = gql`
  fragment InvoiceCreateCreditNote on Invoice {
    id
    refundableAmountCents
    creditableAmountCents
    invoiceType
    fees {
      id
      amountCurrency
      itemCode
      itemName
      creditableAmountCents
      vatRate
    }
    invoiceSubscriptions {
      subscription {
        id
        name
        plan {
          id
          name
        }
      }
      fees {
        ...InvoiceFee
      }
    }
    ...CreateCreditNoteInvoice
  }
  ${InvoiceFeeFragmentDoc}
  ${CreateCreditNoteInvoiceFragmentDoc}
`
export const EditAddOnFragmentDoc = gql`
  fragment EditAddOn on AddOnDetails {
    id
    name
    code
    description
    amountCents
    amountCurrency
    appliedAddOnsCount
  }
`
export const PlansForCouponsFragmentDoc = gql`
  fragment PlansForCoupons on Plan {
    id
    name
    code
  }
`
export const EditCouponFragmentDoc = gql`
  fragment EditCoupon on CouponDetails {
    id
    amountCents
    name
    amountCurrency
    code
    reusable
    expiration
    expirationAt
    couponType
    percentageRate
    frequency
    frequencyDuration
    appliedCouponsCount
    limitedPlans
    plans {
      ...PlansForCoupons
    }
  }
  ${PlansForCouponsFragmentDoc}
`
export const CurrentUserInfosFragmentDoc = gql`
  fragment CurrentUserInfos on User {
    id
    email
    premium
    email
    organizations {
      id
      name
      logoUrl
    }
  }
`
export const MainOrganizationInfosFragmentDoc = gql`
  fragment MainOrganizationInfos on Organization {
    id
    name
    logoUrl
    timezone
  }
`
export const InvoiceDetailsForInvoiceOverviewFragmentDoc = gql`
  fragment InvoiceDetailsForInvoiceOverview on Invoice {
    id
    status
    issuingDate
    customer {
      id
      applicableTimezone
    }
  }
`
export const InvoiceForCreditNotesTableFragmentDoc = gql`
  fragment InvoiceForCreditNotesTable on Invoice {
    id
    subTotalVatExcludedAmountCents
    customer {
      id
    }
    creditNotes {
      id
      creditAmountCurrency
      number
      subTotalVatExcludedAmountCents
      subTotalVatExcludedAmountCurrency
      totalAmountCents
      vatAmountCents
      vatAmountCurrency
      items {
        amountCents
        amountCurrency
        fee {
          id
          amountCents
          amountCurrency
          eventsCount
          units
          feeType
          itemName
          charge {
            id
            billableMetric {
              id
              name
              aggregationType
            }
          }
          subscription {
            id
            name
            plan {
              id
              name
            }
          }
          group {
            id
            key
            value
          }
        }
      }
    }
  }
`
export const InvoiceForDetailsTableFooterFragmentDoc = gql`
  fragment InvoiceForDetailsTableFooter on Invoice {
    couponTotalAmountCents
    creditNoteTotalAmountCents
    subTotalVatExcludedAmountCents
    subTotalVatIncludedAmountCents
    totalAmountCents
    totalAmountCurrency
    vatAmountCents
    walletTransactionAmountCents
  }
`
export const InvoiceForDetailsTableFragmentDoc = gql`
  fragment InvoiceForDetailsTable on Invoice {
    couponTotalAmountCents
    creditAmountCurrency
    invoiceType
    subTotalVatExcludedAmountCents
    subTotalVatIncludedAmountCents
    totalAmountCents
    totalAmountCurrency
    vatAmountCents
    vatAmountCurrency
    walletTransactionAmountCents
    ...InvoiceForDetailsTableFooter
    fees {
      id
      amountCents
      itemName
      units
      feeType
    }
    customer {
      currency
    }
    invoiceSubscriptions {
      subscription {
        id
        name
        subscriptionAt
        periodEndDate
        plan {
          id
          name
          interval
          amountCents
          amountCurrency
        }
      }
      fees {
        id
        amountCents
        eventsCount
        units
        feeType
        charge {
          id
          billableMetric {
            id
            name
            aggregationType
          }
        }
        group {
          id
          key
          value
        }
      }
    }
  }
  ${InvoiceForDetailsTableFooterFragmentDoc}
`
export const InvoiceForInvoiceInfosFragmentDoc = gql`
  fragment InvoiceForInvoiceInfos on Invoice {
    number
    issuingDate
    customer {
      id
      name
      legalName
      email
      addressLine1
      addressLine2
      state
      country
      city
      zipcode
      applicableTimezone
      deletedAt
    }
  }
`
export const AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc = gql`
  fragment AllInvoiceDetailsForCustomerInvoiceDetails on Invoice {
    invoiceType
    number
    paymentStatus
    status
    totalAmountCents
    totalAmountCurrency
    refundableAmountCents
    creditableAmountCents
    ...InvoiceDetailsForInvoiceOverview
    ...InvoiceForCreditNotesTable
    ...InvoiceForDetailsTable
    ...InvoiceForInvoiceInfos
    ...InvoiceForFinalizeInvoice
  }
  ${InvoiceDetailsForInvoiceOverviewFragmentDoc}
  ${InvoiceForCreditNotesTableFragmentDoc}
  ${InvoiceForDetailsTableFragmentDoc}
  ${InvoiceForInvoiceInfosFragmentDoc}
  ${InvoiceForFinalizeInvoiceFragmentDoc}
`
export const EditBillableMetricFragmentDoc = gql`
  fragment EditBillableMetric on BillableMetricDetail {
    id
    name
    code
    description
    group
    aggregationType
    fieldName
    subscriptionsCount
  }
`
export const GraduatedChargeFragmentDoc = gql`
  fragment GraduatedCharge on Charge {
    id
    properties {
      graduatedRanges {
        flatAmount
        fromValue
        perUnitAmount
        toValue
      }
    }
    groupProperties {
      groupId
      values {
        graduatedRanges {
          flatAmount
          fromValue
          perUnitAmount
          toValue
        }
      }
    }
  }
`
export const VolumeRangesFragmentDoc = gql`
  fragment VolumeRanges on Charge {
    properties {
      volumeRanges {
        flatAmount
        fromValue
        perUnitAmount
        toValue
      }
    }
    groupProperties {
      groupId
      values {
        volumeRanges {
          flatAmount
          fromValue
          perUnitAmount
          toValue
        }
      }
    }
  }
`
export const PackageChargeFragmentDoc = gql`
  fragment PackageCharge on Charge {
    id
    properties {
      amount
      packageSize
      freeUnits
    }
    groupProperties {
      groupId
      values {
        amount
        packageSize
        freeUnits
      }
    }
  }
`
export const PercentageChargeFragmentDoc = gql`
  fragment PercentageCharge on Charge {
    id
    properties {
      fixedAmount
      freeUnitsPerEvents
      freeUnitsPerTotalAggregation
      rate
    }
    groupProperties {
      groupId
      values {
        fixedAmount
        freeUnitsPerEvents
        freeUnitsPerTotalAggregation
        rate
      }
    }
  }
`
export const ChargeAccordionFragmentDoc = gql`
  fragment ChargeAccordion on Charge {
    id
    chargeModel
    properties {
      amount
    }
    groupProperties {
      groupId
      values {
        amount
      }
    }
    billableMetric {
      id
      name
      code
      flatGroups {
        id
        key
        value
      }
    }
    ...GraduatedCharge
    ...VolumeRanges
    ...PackageCharge
    ...PercentageCharge
  }
  ${GraduatedChargeFragmentDoc}
  ${VolumeRangesFragmentDoc}
  ${PackageChargeFragmentDoc}
  ${PercentageChargeFragmentDoc}
`
export const EditPlanFragmentDoc = gql`
  fragment EditPlan on PlanDetails {
    id
    name
    code
    description
    interval
    payInAdvance
    amountCents
    amountCurrency
    trialPeriod
    subscriptionsCount
    billChargesMonthly
    charges {
      id
      billableMetric {
        id
        code
      }
      ...ChargeAccordion
      chargeModel
    }
  }
  ${ChargeAccordionFragmentDoc}
`
export const SubscriptionLinePlanFragmentDoc = gql`
  fragment SubscriptionLinePlan on Plan {
    id
    name
    code
  }
`
export const SubscriptionItemFragmentDoc = gql`
  fragment SubscriptionItem on Subscription {
    id
    status
    startedAt
    nextPendingStartDate
    name
    nextName
    externalId
    periodEndDate
    subscriptionAt
    plan {
      ...SubscriptionLinePlan
    }
    nextPlan {
      ...SubscriptionLinePlan
    }
  }
  ${SubscriptionLinePlanFragmentDoc}
`
export const CustomerUsageSubscriptionFragmentDoc = gql`
  fragment CustomerUsageSubscription on Subscription {
    id
    name
    status
    plan {
      id
      name
      code
    }
  }
`
export const AppliedCouponCaptionFragmentDoc = gql`
  fragment AppliedCouponCaption on AppliedCoupon {
    id
    amountCurrency
    amountCents
    amountCentsRemaining
    percentageRate
    frequency
    frequencyDuration
    frequencyDurationRemaining
  }
`
export const CustomerCouponFragmentDoc = gql`
  fragment CustomerCoupon on AppliedCoupon {
    id
    ...AppliedCouponCaption
    coupon {
      id
      name
    }
  }
  ${AppliedCouponCaptionFragmentDoc}
`
export const CustomerAddOnsFragmentDoc = gql`
  fragment CustomerAddOns on AppliedAddOn {
    id
    amountCents
    amountCurrency
    createdAt
    addOn {
      id
      name
    }
  }
`
export const AddCustomerDrawerDetailFragmentDoc = gql`
  fragment AddCustomerDrawerDetail on CustomerDetails {
    id
    name
    externalId
    legalName
    legalNumber
    phone
    email
    currency
    canEditAttributes
    addressLine1
    addressLine2
    state
    country
    city
    zipcode
    applicableTimezone
    paymentProvider
    timezone
    providerCustomer {
      id
      providerCustomerId
      syncWithProvider
    }
  }
`
export const CustomerMainInfosFragmentDoc = gql`
  fragment CustomerMainInfos on CustomerDetails {
    id
    name
    externalId
    legalName
    legalNumber
    phone
    email
    currency
    addressLine1
    addressLine2
    state
    country
    city
    zipcode
    paymentProvider
    timezone
    providerCustomer {
      id
      providerCustomerId
    }
  }
`
export const CustomerDetailsFragmentDoc = gql`
  fragment CustomerDetails on CustomerDetails {
    id
    name
    externalId
    hasActiveWallet
    currency
    hasCreditNotes
    creditNotesCreditsAvailableCount
    creditNotesBalanceAmountCents
    applicableTimezone
    subscriptions(status: [active, pending]) {
      plan {
        id
        amountCurrency
      }
      ...SubscriptionItem
      ...CustomerUsageSubscription
    }
    appliedCoupons {
      ...CustomerCoupon
    }
    appliedAddOns {
      ...CustomerAddOns
    }
    ...AddCustomerDrawerDetail
    ...CustomerMainInfos
  }
  ${SubscriptionItemFragmentDoc}
  ${CustomerUsageSubscriptionFragmentDoc}
  ${CustomerCouponFragmentDoc}
  ${CustomerAddOnsFragmentDoc}
  ${AddCustomerDrawerDetailFragmentDoc}
  ${CustomerMainInfosFragmentDoc}
`
export const EventItemFragmentDoc = gql`
  fragment EventItem on Event {
    id
    code
    externalCustomerId
    timestamp
    matchBillableMetric
    matchCustomField
  }
`
export const EventListFragmentDoc = gql`
  fragment EventList on Event {
    id
    code
    externalCustomerId
    transactionId
    timestamp
    receivedAt
    payload
    billableMetricName
    matchBillableMetric
    matchCustomField
    apiClient
    ipAddress
    externalSubscriptionId
    customerTimezone
    ...EventItem
  }
  ${EventItemFragmentDoc}
`
export const OrganizationInformationsFragmentDoc = gql`
  fragment OrganizationInformations on Organization {
    id
    logoUrl
    name
    legalName
    legalNumber
    email
    addressLine1
    addressLine2
    zipcode
    city
    state
    country
    timezone
  }
`
export const StripeIntegrationFragmentDoc = gql`
  fragment StripeIntegration on StripeProvider {
    id
    secretKey
    createCustomers
  }
`
export const UserIdentifierDocument = gql`
  query UserIdentifier {
    me: currentUser {
      id
      email
      ...CurrentUserInfos
    }
    organization {
      ...MainOrganizationInfos
    }
  }
  ${CurrentUserInfosFragmentDoc}
  ${MainOrganizationInfosFragmentDoc}
`

/**
 * __useUserIdentifierQuery__
 *
 * To run a query within a React component, call `useUserIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentifierQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdentifierQuery(
  baseOptions?: Apollo.QueryHookOptions<UserIdentifierQuery, UserIdentifierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserIdentifierQuery, UserIdentifierQueryVariables>(
    UserIdentifierDocument,
    options
  )
}
export function useUserIdentifierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserIdentifierQuery, UserIdentifierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserIdentifierQuery, UserIdentifierQueryVariables>(
    UserIdentifierDocument,
    options
  )
}
export type UserIdentifierQueryHookResult = ReturnType<typeof useUserIdentifierQuery>
export type UserIdentifierLazyQueryHookResult = ReturnType<typeof useUserIdentifierLazyQuery>
export type UserIdentifierQueryResult = Apollo.QueryResult<
  UserIdentifierQuery,
  UserIdentifierQueryVariables
>
export const DeleteAddOnDocument = gql`
  mutation deleteAddOn($input: DestroyAddOnInput!) {
    destroyAddOn(input: $input) {
      id
    }
  }
`
export type DeleteAddOnMutationFn = Apollo.MutationFunction<
  DeleteAddOnMutation,
  DeleteAddOnMutationVariables
>

/**
 * __useDeleteAddOnMutation__
 *
 * To run a mutation, you first call `useDeleteAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddOnMutation, { data, loading, error }] = useDeleteAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAddOnMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAddOnMutation, DeleteAddOnMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAddOnMutation, DeleteAddOnMutationVariables>(
    DeleteAddOnDocument,
    options
  )
}
export type DeleteAddOnMutationHookResult = ReturnType<typeof useDeleteAddOnMutation>
export type DeleteAddOnMutationResult = Apollo.MutationResult<DeleteAddOnMutation>
export type DeleteAddOnMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddOnMutation,
  DeleteAddOnMutationVariables
>
export const DeleteBillableMetricDocument = gql`
  mutation deleteBillableMetric($input: DestroyBillableMetricInput!) {
    destroyBillableMetric(input: $input) {
      id
    }
  }
`
export type DeleteBillableMetricMutationFn = Apollo.MutationFunction<
  DeleteBillableMetricMutation,
  DeleteBillableMetricMutationVariables
>

/**
 * __useDeleteBillableMetricMutation__
 *
 * To run a mutation, you first call `useDeleteBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillableMetricMutation, { data, loading, error }] = useDeleteBillableMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBillableMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBillableMetricMutation,
    DeleteBillableMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBillableMetricMutation, DeleteBillableMetricMutationVariables>(
    DeleteBillableMetricDocument,
    options
  )
}
export type DeleteBillableMetricMutationHookResult = ReturnType<
  typeof useDeleteBillableMetricMutation
>
export type DeleteBillableMetricMutationResult = Apollo.MutationResult<DeleteBillableMetricMutation>
export type DeleteBillableMetricMutationOptions = Apollo.BaseMutationOptions<
  DeleteBillableMetricMutation,
  DeleteBillableMetricMutationVariables
>
export const GetPlansForCouponsDocument = gql`
  query getPlansForCoupons($page: Int, $limit: Int, $searchTerm: String) {
    plans(page: $page, limit: $limit, searchTerm: $searchTerm) {
      collection {
        ...PlansForCoupons
      }
    }
  }
  ${PlansForCouponsFragmentDoc}
`

/**
 * __useGetPlansForCouponsQuery__
 *
 * To run a query within a React component, call `useGetPlansForCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansForCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansForCouponsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetPlansForCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlansForCouponsQuery, GetPlansForCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPlansForCouponsQuery, GetPlansForCouponsQueryVariables>(
    GetPlansForCouponsDocument,
    options
  )
}
export function useGetPlansForCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlansForCouponsQuery,
    GetPlansForCouponsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPlansForCouponsQuery, GetPlansForCouponsQueryVariables>(
    GetPlansForCouponsDocument,
    options
  )
}
export type GetPlansForCouponsQueryHookResult = ReturnType<typeof useGetPlansForCouponsQuery>
export type GetPlansForCouponsLazyQueryHookResult = ReturnType<
  typeof useGetPlansForCouponsLazyQuery
>
export type GetPlansForCouponsQueryResult = Apollo.QueryResult<
  GetPlansForCouponsQuery,
  GetPlansForCouponsQueryVariables
>
export const DeleteCouponDocument = gql`
  mutation deleteCoupon($input: DestroyCouponInput!) {
    destroyCoupon(input: $input) {
      id
    }
  }
`
export type DeleteCouponMutationFn = Apollo.MutationFunction<
  DeleteCouponMutation,
  DeleteCouponMutationVariables
>

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(
    DeleteCouponDocument,
    options
  )
}
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<
  DeleteCouponMutation,
  DeleteCouponMutationVariables
>
export const TerminateCouponDocument = gql`
  mutation terminateCoupon($input: TerminateCouponInput!) {
    terminateCoupon(input: $input) {
      id
    }
  }
`
export type TerminateCouponMutationFn = Apollo.MutationFunction<
  TerminateCouponMutation,
  TerminateCouponMutationVariables
>

/**
 * __useTerminateCouponMutation__
 *
 * To run a mutation, you first call `useTerminateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCouponMutation, { data, loading, error }] = useTerminateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateCouponMutation,
    TerminateCouponMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TerminateCouponMutation, TerminateCouponMutationVariables>(
    TerminateCouponDocument,
    options
  )
}
export type TerminateCouponMutationHookResult = ReturnType<typeof useTerminateCouponMutation>
export type TerminateCouponMutationResult = Apollo.MutationResult<TerminateCouponMutation>
export type TerminateCouponMutationOptions = Apollo.BaseMutationOptions<
  TerminateCouponMutation,
  TerminateCouponMutationVariables
>
export const GetAddOnsForCustomerDocument = gql`
  query getAddOnsForCustomer($page: Int, $limit: Int, $searchTerm: String) {
    addOns(page: $page, limit: $limit, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        id
        name
        amountCurrency
        amountCents
      }
    }
  }
`

/**
 * __useGetAddOnsForCustomerQuery__
 *
 * To run a query within a React component, call `useGetAddOnsForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddOnsForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddOnsForCustomerQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetAddOnsForCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddOnsForCustomerQuery,
    GetAddOnsForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>(
    GetAddOnsForCustomerDocument,
    options
  )
}
export function useGetAddOnsForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddOnsForCustomerQuery,
    GetAddOnsForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>(
    GetAddOnsForCustomerDocument,
    options
  )
}
export type GetAddOnsForCustomerQueryHookResult = ReturnType<typeof useGetAddOnsForCustomerQuery>
export type GetAddOnsForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetAddOnsForCustomerLazyQuery
>
export type GetAddOnsForCustomerQueryResult = Apollo.QueryResult<
  GetAddOnsForCustomerQuery,
  GetAddOnsForCustomerQueryVariables
>
export const AddAddOnDocument = gql`
  mutation addAddOn($input: CreateAppliedAddOnInput!) {
    createAppliedAddOn(input: $input) {
      id
    }
  }
`
export type AddAddOnMutationFn = Apollo.MutationFunction<
  AddAddOnMutation,
  AddAddOnMutationVariables
>

/**
 * __useAddAddOnMutation__
 *
 * To run a mutation, you first call `useAddAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddOnMutation, { data, loading, error }] = useAddAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAddOnMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAddOnMutation, AddAddOnMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddAddOnMutation, AddAddOnMutationVariables>(AddAddOnDocument, options)
}
export type AddAddOnMutationHookResult = ReturnType<typeof useAddAddOnMutation>
export type AddAddOnMutationResult = Apollo.MutationResult<AddAddOnMutation>
export type AddAddOnMutationOptions = Apollo.BaseMutationOptions<
  AddAddOnMutation,
  AddAddOnMutationVariables
>
export const GetCouponForCustomerDocument = gql`
  query getCouponForCustomer(
    $page: Int
    $limit: Int
    $status: CouponStatusEnum
    $searchTerm: String
  ) {
    coupons(page: $page, limit: $limit, status: $status, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        id
        name
        amountCurrency
        amountCents
        couponType
        percentageRate
        frequency
        frequencyDuration
        plans {
          ...CouponPlansForCustomer
        }
        ...CouponCaption
      }
    }
  }
  ${CouponPlansForCustomerFragmentDoc}
  ${CouponCaptionFragmentDoc}
`

/**
 * __useGetCouponForCustomerQuery__
 *
 * To run a query within a React component, call `useGetCouponForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponForCustomerQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCouponForCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCouponForCustomerQuery,
    GetCouponForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>(
    GetCouponForCustomerDocument,
    options
  )
}
export function useGetCouponForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCouponForCustomerQuery,
    GetCouponForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>(
    GetCouponForCustomerDocument,
    options
  )
}
export type GetCouponForCustomerQueryHookResult = ReturnType<typeof useGetCouponForCustomerQuery>
export type GetCouponForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCouponForCustomerLazyQuery
>
export type GetCouponForCustomerQueryResult = Apollo.QueryResult<
  GetCouponForCustomerQuery,
  GetCouponForCustomerQueryVariables
>
export const AddCouponDocument = gql`
  mutation addCoupon($input: CreateAppliedCouponInput!) {
    createAppliedCoupon(input: $input) {
      id
    }
  }
`
export type AddCouponMutationFn = Apollo.MutationFunction<
  AddCouponMutation,
  AddCouponMutationVariables
>

/**
 * __useAddCouponMutation__
 *
 * To run a mutation, you first call `useAddCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCouponMutation, { data, loading, error }] = useAddCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCouponMutation, AddCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddCouponMutation, AddCouponMutationVariables>(
    AddCouponDocument,
    options
  )
}
export type AddCouponMutationHookResult = ReturnType<typeof useAddCouponMutation>
export type AddCouponMutationResult = Apollo.MutationResult<AddCouponMutation>
export type AddCouponMutationOptions = Apollo.BaseMutationOptions<
  AddCouponMutation,
  AddCouponMutationVariables
>
export const RemoveCouponDocument = gql`
  mutation removeCoupon($input: TerminateAppliedCouponInput!) {
    terminateAppliedCoupon(input: $input) {
      id
    }
  }
`
export type RemoveCouponMutationFn = Apollo.MutationFunction<
  RemoveCouponMutation,
  RemoveCouponMutationVariables
>

/**
 * __useRemoveCouponMutation__
 *
 * To run a mutation, you first call `useRemoveCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCouponMutation, { data, loading, error }] = useRemoveCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCouponMutation, RemoveCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveCouponMutation, RemoveCouponMutationVariables>(
    RemoveCouponDocument,
    options
  )
}
export type RemoveCouponMutationHookResult = ReturnType<typeof useRemoveCouponMutation>
export type RemoveCouponMutationResult = Apollo.MutationResult<RemoveCouponMutation>
export type RemoveCouponMutationOptions = Apollo.BaseMutationOptions<
  RemoveCouponMutation,
  RemoveCouponMutationVariables
>
export const GetCustomerCreditNotesDocument = gql`
  query getCustomerCreditNotes($customerId: ID!, $page: Int, $limit: Int, $searchTerm: String) {
    customerCreditNotes(
      customerId: $customerId
      page: $page
      limit: $limit
      searchTerm: $searchTerm
    ) {
      ...CreditNotesForList
    }
  }
  ${CreditNotesForListFragmentDoc}
`

/**
 * __useGetCustomerCreditNotesQuery__
 *
 * To run a query within a React component, call `useGetCustomerCreditNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCreditNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCreditNotesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCustomerCreditNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerCreditNotesQuery,
    GetCustomerCreditNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerCreditNotesQuery, GetCustomerCreditNotesQueryVariables>(
    GetCustomerCreditNotesDocument,
    options
  )
}
export function useGetCustomerCreditNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerCreditNotesQuery,
    GetCustomerCreditNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerCreditNotesQuery, GetCustomerCreditNotesQueryVariables>(
    GetCustomerCreditNotesDocument,
    options
  )
}
export type GetCustomerCreditNotesQueryHookResult = ReturnType<
  typeof useGetCustomerCreditNotesQuery
>
export type GetCustomerCreditNotesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerCreditNotesLazyQuery
>
export type GetCustomerCreditNotesQueryResult = Apollo.QueryResult<
  GetCustomerCreditNotesQuery,
  GetCustomerCreditNotesQueryVariables
>
export const GetCustomerInvoicesDocument = gql`
  query getCustomerInvoices(
    $customerId: ID!
    $limit: Int
    $page: Int
    $status: InvoiceStatusTypeEnum
    $searchTerm: String
  ) {
    customerInvoices(
      customerId: $customerId
      limit: $limit
      page: $page
      status: $status
      searchTerm: $searchTerm
    ) {
      ...InvoiceForInvoiceList
    }
  }
  ${InvoiceForInvoiceListFragmentDoc}
`

/**
 * __useGetCustomerInvoicesQuery__
 *
 * To run a query within a React component, call `useGetCustomerInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCustomerInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>(
    GetCustomerInvoicesDocument,
    options
  )
}
export function useGetCustomerInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerInvoicesQuery,
    GetCustomerInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>(
    GetCustomerInvoicesDocument,
    options
  )
}
export type GetCustomerInvoicesQueryHookResult = ReturnType<typeof useGetCustomerInvoicesQuery>
export type GetCustomerInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerInvoicesLazyQuery
>
export type GetCustomerInvoicesQueryResult = Apollo.QueryResult<
  GetCustomerInvoicesQuery,
  GetCustomerInvoicesQueryVariables
>
export const GetCustomerSettingsDocument = gql`
  query getCustomerSettings($id: ID!) {
    customer(id: $id) {
      id
      vatRate
      invoiceGracePeriod
      ...EditCustomerVatRate
      ...EditCustomerInvoiceGracePeriod
      ...DeleteCustomerVatRate
      ...DeleteCustomerGracePeriod
    }
    organization {
      id
      billingConfiguration {
        id
        vatRate
        invoiceGracePeriod
      }
    }
  }
  ${EditCustomerVatRateFragmentDoc}
  ${EditCustomerInvoiceGracePeriodFragmentDoc}
  ${DeleteCustomerVatRateFragmentDoc}
  ${DeleteCustomerGracePeriodFragmentDoc}
`

/**
 * __useGetCustomerSettingsQuery__
 *
 * To run a query within a React component, call `useGetCustomerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerSettingsQuery, GetCustomerSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerSettingsQuery, GetCustomerSettingsQueryVariables>(
    GetCustomerSettingsDocument,
    options
  )
}
export function useGetCustomerSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerSettingsQuery,
    GetCustomerSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerSettingsQuery, GetCustomerSettingsQueryVariables>(
    GetCustomerSettingsDocument,
    options
  )
}
export type GetCustomerSettingsQueryHookResult = ReturnType<typeof useGetCustomerSettingsQuery>
export type GetCustomerSettingsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerSettingsLazyQuery
>
export type GetCustomerSettingsQueryResult = Apollo.QueryResult<
  GetCustomerSettingsQuery,
  GetCustomerSettingsQueryVariables
>
export const DeleteCustomerDocument = gql`
  mutation deleteCustomer($input: DestroyCustomerInput!) {
    destroyCustomer(input: $input) {
      id
    }
  }
`
export type DeleteCustomerMutationFn = Apollo.MutationFunction<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(
    DeleteCustomerDocument,
    options
  )
}
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>
export const DeleteCustomerGracePeriodDocument = gql`
  mutation deleteCustomerGracePeriod($input: UpdateCustomerInvoiceGracePeriodInput!) {
    updateCustomerInvoiceGracePeriod(input: $input) {
      id
      invoiceGracePeriod
    }
  }
`
export type DeleteCustomerGracePeriodMutationFn = Apollo.MutationFunction<
  DeleteCustomerGracePeriodMutation,
  DeleteCustomerGracePeriodMutationVariables
>

/**
 * __useDeleteCustomerGracePeriodMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerGracePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerGracePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerGracePeriodMutation, { data, loading, error }] = useDeleteCustomerGracePeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerGracePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomerGracePeriodMutation,
    DeleteCustomerGracePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCustomerGracePeriodMutation,
    DeleteCustomerGracePeriodMutationVariables
  >(DeleteCustomerGracePeriodDocument, options)
}
export type DeleteCustomerGracePeriodMutationHookResult = ReturnType<
  typeof useDeleteCustomerGracePeriodMutation
>
export type DeleteCustomerGracePeriodMutationResult =
  Apollo.MutationResult<DeleteCustomerGracePeriodMutation>
export type DeleteCustomerGracePeriodMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerGracePeriodMutation,
  DeleteCustomerGracePeriodMutationVariables
>
export const DeleteCustomerVatRateDocument = gql`
  mutation deleteCustomerVatRate($input: UpdateCustomerVatRateInput!) {
    updateCustomerVatRate(input: $input) {
      id
      vatRate
    }
  }
`
export type DeleteCustomerVatRateMutationFn = Apollo.MutationFunction<
  DeleteCustomerVatRateMutation,
  DeleteCustomerVatRateMutationVariables
>

/**
 * __useDeleteCustomerVatRateMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerVatRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerVatRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerVatRateMutation, { data, loading, error }] = useDeleteCustomerVatRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerVatRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomerVatRateMutation,
    DeleteCustomerVatRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCustomerVatRateMutation, DeleteCustomerVatRateMutationVariables>(
    DeleteCustomerVatRateDocument,
    options
  )
}
export type DeleteCustomerVatRateMutationHookResult = ReturnType<
  typeof useDeleteCustomerVatRateMutation
>
export type DeleteCustomerVatRateMutationResult =
  Apollo.MutationResult<DeleteCustomerVatRateMutation>
export type DeleteCustomerVatRateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerVatRateMutation,
  DeleteCustomerVatRateMutationVariables
>
export const UpdateCustomerInvoiceGracePeriodDocument = gql`
  mutation updateCustomerInvoiceGracePeriod($input: UpdateCustomerInvoiceGracePeriodInput!) {
    updateCustomerInvoiceGracePeriod(input: $input) {
      id
      ...EditCustomerInvoiceGracePeriod
    }
  }
  ${EditCustomerInvoiceGracePeriodFragmentDoc}
`
export type UpdateCustomerInvoiceGracePeriodMutationFn = Apollo.MutationFunction<
  UpdateCustomerInvoiceGracePeriodMutation,
  UpdateCustomerInvoiceGracePeriodMutationVariables
>

/**
 * __useUpdateCustomerInvoiceGracePeriodMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerInvoiceGracePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerInvoiceGracePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerInvoiceGracePeriodMutation, { data, loading, error }] = useUpdateCustomerInvoiceGracePeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerInvoiceGracePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerInvoiceGracePeriodMutation,
    UpdateCustomerInvoiceGracePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCustomerInvoiceGracePeriodMutation,
    UpdateCustomerInvoiceGracePeriodMutationVariables
  >(UpdateCustomerInvoiceGracePeriodDocument, options)
}
export type UpdateCustomerInvoiceGracePeriodMutationHookResult = ReturnType<
  typeof useUpdateCustomerInvoiceGracePeriodMutation
>
export type UpdateCustomerInvoiceGracePeriodMutationResult =
  Apollo.MutationResult<UpdateCustomerInvoiceGracePeriodMutation>
export type UpdateCustomerInvoiceGracePeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerInvoiceGracePeriodMutation,
  UpdateCustomerInvoiceGracePeriodMutationVariables
>
export const UpdateCustomerVatRateDocument = gql`
  mutation updateCustomerVatRate($input: UpdateCustomerVatRateInput!) {
    updateCustomerVatRate(input: $input) {
      id
      ...EditCustomerVatRate
    }
  }
  ${EditCustomerVatRateFragmentDoc}
`
export type UpdateCustomerVatRateMutationFn = Apollo.MutationFunction<
  UpdateCustomerVatRateMutation,
  UpdateCustomerVatRateMutationVariables
>

/**
 * __useUpdateCustomerVatRateMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerVatRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerVatRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerVatRateMutation, { data, loading, error }] = useUpdateCustomerVatRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerVatRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerVatRateMutation,
    UpdateCustomerVatRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCustomerVatRateMutation, UpdateCustomerVatRateMutationVariables>(
    UpdateCustomerVatRateDocument,
    options
  )
}
export type UpdateCustomerVatRateMutationHookResult = ReturnType<
  typeof useUpdateCustomerVatRateMutation
>
export type UpdateCustomerVatRateMutationResult =
  Apollo.MutationResult<UpdateCustomerVatRateMutation>
export type UpdateCustomerVatRateMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerVatRateMutation,
  UpdateCustomerVatRateMutationVariables
>
export const DownloadCreditNoteDocument = gql`
  mutation downloadCreditNote($input: DownloadCreditNoteInput!) {
    downloadCreditNote(input: $input) {
      id
      fileUrl
    }
  }
`
export type DownloadCreditNoteMutationFn = Apollo.MutationFunction<
  DownloadCreditNoteMutation,
  DownloadCreditNoteMutationVariables
>

/**
 * __useDownloadCreditNoteMutation__
 *
 * To run a mutation, you first call `useDownloadCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadCreditNoteMutation, { data, loading, error }] = useDownloadCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadCreditNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadCreditNoteMutation,
    DownloadCreditNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DownloadCreditNoteMutation, DownloadCreditNoteMutationVariables>(
    DownloadCreditNoteDocument,
    options
  )
}
export type DownloadCreditNoteMutationHookResult = ReturnType<typeof useDownloadCreditNoteMutation>
export type DownloadCreditNoteMutationResult = Apollo.MutationResult<DownloadCreditNoteMutation>
export type DownloadCreditNoteMutationOptions = Apollo.BaseMutationOptions<
  DownloadCreditNoteMutation,
  DownloadCreditNoteMutationVariables
>
export const VoidCreditNoteDocument = gql`
  mutation voidCreditNote($input: VoidCreditNoteInput!) {
    voidCreditNote(input: $input) {
      id
    }
  }
`
export type VoidCreditNoteMutationFn = Apollo.MutationFunction<
  VoidCreditNoteMutation,
  VoidCreditNoteMutationVariables
>

/**
 * __useVoidCreditNoteMutation__
 *
 * To run a mutation, you first call `useVoidCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidCreditNoteMutation, { data, loading, error }] = useVoidCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidCreditNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<VoidCreditNoteMutation, VoidCreditNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VoidCreditNoteMutation, VoidCreditNoteMutationVariables>(
    VoidCreditNoteDocument,
    options
  )
}
export type VoidCreditNoteMutationHookResult = ReturnType<typeof useVoidCreditNoteMutation>
export type VoidCreditNoteMutationResult = Apollo.MutationResult<VoidCreditNoteMutation>
export type VoidCreditNoteMutationOptions = Apollo.BaseMutationOptions<
  VoidCreditNoteMutation,
  VoidCreditNoteMutationVariables
>
export const UpdateCustomerSubscriptionDocument = gql`
  mutation updateCustomerSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      id
      name
      status
      startedAt
      subscriptionAt
    }
  }
`
export type UpdateCustomerSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateCustomerSubscriptionMutation,
  UpdateCustomerSubscriptionMutationVariables
>

/**
 * __useUpdateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerSubscriptionMutation, { data, loading, error }] = useUpdateCustomerSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerSubscriptionMutation,
    UpdateCustomerSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCustomerSubscriptionMutation,
    UpdateCustomerSubscriptionMutationVariables
  >(UpdateCustomerSubscriptionDocument, options)
}
export type UpdateCustomerSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateCustomerSubscriptionMutation
>
export type UpdateCustomerSubscriptionMutationResult =
  Apollo.MutationResult<UpdateCustomerSubscriptionMutation>
export type UpdateCustomerSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerSubscriptionMutation,
  UpdateCustomerSubscriptionMutationVariables
>
export const TerminateCustomerSubscriptionDocument = gql`
  mutation terminateCustomerSubscription($input: TerminateSubscriptionInput!) {
    terminateSubscription(input: $input) {
      id
    }
  }
`
export type TerminateCustomerSubscriptionMutationFn = Apollo.MutationFunction<
  TerminateCustomerSubscriptionMutation,
  TerminateCustomerSubscriptionMutationVariables
>

/**
 * __useTerminateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useTerminateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustomerSubscriptionMutation, { data, loading, error }] = useTerminateCustomerSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCustomerSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateCustomerSubscriptionMutation,
    TerminateCustomerSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TerminateCustomerSubscriptionMutation,
    TerminateCustomerSubscriptionMutationVariables
  >(TerminateCustomerSubscriptionDocument, options)
}
export type TerminateCustomerSubscriptionMutationHookResult = ReturnType<
  typeof useTerminateCustomerSubscriptionMutation
>
export type TerminateCustomerSubscriptionMutationResult =
  Apollo.MutationResult<TerminateCustomerSubscriptionMutation>
export type TerminateCustomerSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  TerminateCustomerSubscriptionMutation,
  TerminateCustomerSubscriptionMutationVariables
>
export const CustomerUsageDocument = gql`
  query customerUsage($customerId: ID!, $subscriptionId: ID!) {
    customerUsage(customerId: $customerId, subscriptionId: $subscriptionId) {
      amountCents
      amountCurrency
      fromDatetime
      toDatetime
      chargesUsage {
        units
        amountCents
        billableMetric {
          id
          code
          name
        }
      }
      ...CustomerUsageForUsageDetails
    }
  }
  ${CustomerUsageForUsageDetailsFragmentDoc}
`

/**
 * __useCustomerUsageQuery__
 *
 * To run a query within a React component, call `useCustomerUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUsageQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCustomerUsageQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerUsageQuery, CustomerUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerUsageQuery, CustomerUsageQueryVariables>(
    CustomerUsageDocument,
    options
  )
}
export function useCustomerUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerUsageQuery, CustomerUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerUsageQuery, CustomerUsageQueryVariables>(
    CustomerUsageDocument,
    options
  )
}
export type CustomerUsageQueryHookResult = ReturnType<typeof useCustomerUsageQuery>
export type CustomerUsageLazyQueryHookResult = ReturnType<typeof useCustomerUsageLazyQuery>
export type CustomerUsageQueryResult = Apollo.QueryResult<
  CustomerUsageQuery,
  CustomerUsageQueryVariables
>
export const DeleteWebhookDocument = gql`
  mutation deleteWebhook($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      webhookUrl
    }
  }
`
export type DeleteWebhookMutationFn = Apollo.MutationFunction<
  DeleteWebhookMutation,
  DeleteWebhookMutationVariables
>

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(
    DeleteWebhookDocument,
    options
  )
}
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>
export type DeleteWebhookMutationResult = Apollo.MutationResult<DeleteWebhookMutation>
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebhookMutation,
  DeleteWebhookMutationVariables
>
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      webhookUrl
    }
  }
`
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options
  )
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>
export const FinalizeInvoiceDocument = gql`
  mutation finalizeInvoice($input: FinalizeInvoiceInput!) {
    finalizeInvoice(input: $input) {
      id
      ...AllInvoiceDetailsForCustomerInvoiceDetails
    }
  }
  ${AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc}
`
export type FinalizeInvoiceMutationFn = Apollo.MutationFunction<
  FinalizeInvoiceMutation,
  FinalizeInvoiceMutationVariables
>

/**
 * __useFinalizeInvoiceMutation__
 *
 * To run a mutation, you first call `useFinalizeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeInvoiceMutation, { data, loading, error }] = useFinalizeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeInvoiceMutation,
    FinalizeInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>(
    FinalizeInvoiceDocument,
    options
  )
}
export type FinalizeInvoiceMutationHookResult = ReturnType<typeof useFinalizeInvoiceMutation>
export type FinalizeInvoiceMutationResult = Apollo.MutationResult<FinalizeInvoiceMutation>
export type FinalizeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  FinalizeInvoiceMutation,
  FinalizeInvoiceMutationVariables
>
export const DownloadInvoiceItemDocument = gql`
  mutation downloadInvoiceItem($input: DownloadInvoiceInput!) {
    downloadInvoice(input: $input) {
      id
      fileUrl
    }
  }
`
export type DownloadInvoiceItemMutationFn = Apollo.MutationFunction<
  DownloadInvoiceItemMutation,
  DownloadInvoiceItemMutationVariables
>

/**
 * __useDownloadInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDownloadInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoiceItemMutation, { data, loading, error }] = useDownloadInvoiceItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadInvoiceItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadInvoiceItemMutation,
    DownloadInvoiceItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DownloadInvoiceItemMutation, DownloadInvoiceItemMutationVariables>(
    DownloadInvoiceItemDocument,
    options
  )
}
export type DownloadInvoiceItemMutationHookResult = ReturnType<
  typeof useDownloadInvoiceItemMutation
>
export type DownloadInvoiceItemMutationResult = Apollo.MutationResult<DownloadInvoiceItemMutation>
export type DownloadInvoiceItemMutationOptions = Apollo.BaseMutationOptions<
  DownloadInvoiceItemMutation,
  DownloadInvoiceItemMutationVariables
>
export const RetryInvoicePaymentDocument = gql`
  mutation retryInvoicePayment($input: RetryInvoicePaymentInput!) {
    retryInvoicePayment(input: $input) {
      id
      ...InvoiceListItem
    }
  }
  ${InvoiceListItemFragmentDoc}
`
export type RetryInvoicePaymentMutationFn = Apollo.MutationFunction<
  RetryInvoicePaymentMutation,
  RetryInvoicePaymentMutationVariables
>

/**
 * __useRetryInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useRetryInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryInvoicePaymentMutation, { data, loading, error }] = useRetryInvoicePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryInvoicePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetryInvoicePaymentMutation,
    RetryInvoicePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RetryInvoicePaymentMutation, RetryInvoicePaymentMutationVariables>(
    RetryInvoicePaymentDocument,
    options
  )
}
export type RetryInvoicePaymentMutationHookResult = ReturnType<
  typeof useRetryInvoicePaymentMutation
>
export type RetryInvoicePaymentMutationResult = Apollo.MutationResult<RetryInvoicePaymentMutation>
export type RetryInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<
  RetryInvoicePaymentMutation,
  RetryInvoicePaymentMutationVariables
>
export const GetbillableMetricsDocument = gql`
  query getbillableMetrics($page: Int, $limit: Int, $searchTerm: String) {
    billableMetrics(page: $page, limit: $limit, searchTerm: $searchTerm) {
      collection {
        ...billableMetricForPlan
      }
    }
  }
  ${BillableMetricForPlanFragmentDoc}
`

/**
 * __useGetbillableMetricsQuery__
 *
 * To run a query within a React component, call `useGetbillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetbillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetbillableMetricsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetbillableMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>(
    GetbillableMetricsDocument,
    options
  )
}
export function useGetbillableMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetbillableMetricsQuery,
    GetbillableMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>(
    GetbillableMetricsDocument,
    options
  )
}
export type GetbillableMetricsQueryHookResult = ReturnType<typeof useGetbillableMetricsQuery>
export type GetbillableMetricsLazyQueryHookResult = ReturnType<
  typeof useGetbillableMetricsLazyQuery
>
export type GetbillableMetricsQueryResult = Apollo.QueryResult<
  GetbillableMetricsQuery,
  GetbillableMetricsQueryVariables
>
export const DeletePlanDocument = gql`
  mutation deletePlan($input: DestroyPlanInput!) {
    destroyPlan(input: $input) {
      id
    }
  }
`
export type DeletePlanMutationFn = Apollo.MutationFunction<
  DeletePlanMutation,
  DeletePlanMutationVariables
>

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(
    DeletePlanDocument,
    options
  )
}
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanMutation,
  DeletePlanMutationVariables
>
export const UpdateOrganizationGracePeriodDocument = gql`
  mutation updateOrganizationGracePeriod($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      billingConfiguration {
        id
        invoiceGracePeriod
      }
    }
  }
`
export type UpdateOrganizationGracePeriodMutationFn = Apollo.MutationFunction<
  UpdateOrganizationGracePeriodMutation,
  UpdateOrganizationGracePeriodMutationVariables
>

/**
 * __useUpdateOrganizationGracePeriodMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationGracePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationGracePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationGracePeriodMutation, { data, loading, error }] = useUpdateOrganizationGracePeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationGracePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationGracePeriodMutation,
    UpdateOrganizationGracePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOrganizationGracePeriodMutation,
    UpdateOrganizationGracePeriodMutationVariables
  >(UpdateOrganizationGracePeriodDocument, options)
}
export type UpdateOrganizationGracePeriodMutationHookResult = ReturnType<
  typeof useUpdateOrganizationGracePeriodMutation
>
export type UpdateOrganizationGracePeriodMutationResult =
  Apollo.MutationResult<UpdateOrganizationGracePeriodMutation>
export type UpdateOrganizationGracePeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationGracePeriodMutation,
  UpdateOrganizationGracePeriodMutationVariables
>
export const UpdateOrganizationInformationsDocument = gql`
  mutation updateOrganizationInformations($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ...OrganizationInformations
      ...EditOrganizationInformationsDialog
    }
  }
  ${OrganizationInformationsFragmentDoc}
  ${EditOrganizationInformationsDialogFragmentDoc}
`
export type UpdateOrganizationInformationsMutationFn = Apollo.MutationFunction<
  UpdateOrganizationInformationsMutation,
  UpdateOrganizationInformationsMutationVariables
>

/**
 * __useUpdateOrganizationInformationsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationInformationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationInformationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationInformationsMutation, { data, loading, error }] = useUpdateOrganizationInformationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationInformationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationInformationsMutation,
    UpdateOrganizationInformationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOrganizationInformationsMutation,
    UpdateOrganizationInformationsMutationVariables
  >(UpdateOrganizationInformationsDocument, options)
}
export type UpdateOrganizationInformationsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationInformationsMutation
>
export type UpdateOrganizationInformationsMutationResult =
  Apollo.MutationResult<UpdateOrganizationInformationsMutation>
export type UpdateOrganizationInformationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationInformationsMutation,
  UpdateOrganizationInformationsMutationVariables
>
export const UpdateOrganizationInvoiceTemplateDocument = gql`
  mutation updateOrganizationInvoiceTemplate($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      ...EditOrganizationInvoiceTemplateDialog
    }
  }
  ${EditOrganizationInvoiceTemplateDialogFragmentDoc}
`
export type UpdateOrganizationInvoiceTemplateMutationFn = Apollo.MutationFunction<
  UpdateOrganizationInvoiceTemplateMutation,
  UpdateOrganizationInvoiceTemplateMutationVariables
>

/**
 * __useUpdateOrganizationInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationInvoiceTemplateMutation, { data, loading, error }] = useUpdateOrganizationInvoiceTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationInvoiceTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationInvoiceTemplateMutation,
    UpdateOrganizationInvoiceTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOrganizationInvoiceTemplateMutation,
    UpdateOrganizationInvoiceTemplateMutationVariables
  >(UpdateOrganizationInvoiceTemplateDocument, options)
}
export type UpdateOrganizationInvoiceTemplateMutationHookResult = ReturnType<
  typeof useUpdateOrganizationInvoiceTemplateMutation
>
export type UpdateOrganizationInvoiceTemplateMutationResult =
  Apollo.MutationResult<UpdateOrganizationInvoiceTemplateMutation>
export type UpdateOrganizationInvoiceTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationInvoiceTemplateMutation,
  UpdateOrganizationInvoiceTemplateMutationVariables
>
export const UpdateOrganizationTimezoneDocument = gql`
  mutation updateOrganizationTimezone($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      timezone
    }
  }
`
export type UpdateOrganizationTimezoneMutationFn = Apollo.MutationFunction<
  UpdateOrganizationTimezoneMutation,
  UpdateOrganizationTimezoneMutationVariables
>

/**
 * __useUpdateOrganizationTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationTimezoneMutation, { data, loading, error }] = useUpdateOrganizationTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationTimezoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationTimezoneMutation,
    UpdateOrganizationTimezoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOrganizationTimezoneMutation,
    UpdateOrganizationTimezoneMutationVariables
  >(UpdateOrganizationTimezoneDocument, options)
}
export type UpdateOrganizationTimezoneMutationHookResult = ReturnType<
  typeof useUpdateOrganizationTimezoneMutation
>
export type UpdateOrganizationTimezoneMutationResult =
  Apollo.MutationResult<UpdateOrganizationTimezoneMutation>
export type UpdateOrganizationTimezoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationTimezoneMutation,
  UpdateOrganizationTimezoneMutationVariables
>
export const UpdateVatRateOrganizationDocument = gql`
  mutation updateVatRateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      billingConfiguration {
        id
        vatRate
      }
    }
  }
`
export type UpdateVatRateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateVatRateOrganizationMutation,
  UpdateVatRateOrganizationMutationVariables
>

/**
 * __useUpdateVatRateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateVatRateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVatRateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVatRateOrganizationMutation, { data, loading, error }] = useUpdateVatRateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVatRateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVatRateOrganizationMutation,
    UpdateVatRateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVatRateOrganizationMutation,
    UpdateVatRateOrganizationMutationVariables
  >(UpdateVatRateOrganizationDocument, options)
}
export type UpdateVatRateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateVatRateOrganizationMutation
>
export type UpdateVatRateOrganizationMutationResult =
  Apollo.MutationResult<UpdateVatRateOrganizationMutation>
export type UpdateVatRateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateVatRateOrganizationMutation,
  UpdateVatRateOrganizationMutationVariables
>
export const AddStripeApiKeyDocument = gql`
  mutation addStripeApiKey($input: AddStripePaymentProviderInput!) {
    addStripePaymentProvider(input: $input) {
      id
      ...StripeIntegration
    }
  }
  ${StripeIntegrationFragmentDoc}
`
export type AddStripeApiKeyMutationFn = Apollo.MutationFunction<
  AddStripeApiKeyMutation,
  AddStripeApiKeyMutationVariables
>

/**
 * __useAddStripeApiKeyMutation__
 *
 * To run a mutation, you first call `useAddStripeApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStripeApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStripeApiKeyMutation, { data, loading, error }] = useAddStripeApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStripeApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStripeApiKeyMutation,
    AddStripeApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddStripeApiKeyMutation, AddStripeApiKeyMutationVariables>(
    AddStripeApiKeyDocument,
    options
  )
}
export type AddStripeApiKeyMutationHookResult = ReturnType<typeof useAddStripeApiKeyMutation>
export type AddStripeApiKeyMutationResult = Apollo.MutationResult<AddStripeApiKeyMutation>
export type AddStripeApiKeyMutationOptions = Apollo.BaseMutationOptions<
  AddStripeApiKeyMutation,
  AddStripeApiKeyMutationVariables
>
export const DeleteStripeDocument = gql`
  mutation deleteStripe($input: DestroyPaymentProviderInput!) {
    destroyPaymentProvider(input: $input) {
      id
    }
  }
`
export type DeleteStripeMutationFn = Apollo.MutationFunction<
  DeleteStripeMutation,
  DeleteStripeMutationVariables
>

/**
 * __useDeleteStripeMutation__
 *
 * To run a mutation, you first call `useDeleteStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStripeMutation, { data, loading, error }] = useDeleteStripeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStripeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteStripeMutation, DeleteStripeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteStripeMutation, DeleteStripeMutationVariables>(
    DeleteStripeDocument,
    options
  )
}
export type DeleteStripeMutationHookResult = ReturnType<typeof useDeleteStripeMutation>
export type DeleteStripeMutationResult = Apollo.MutationResult<DeleteStripeMutation>
export type DeleteStripeMutationOptions = Apollo.BaseMutationOptions<
  DeleteStripeMutation,
  DeleteStripeMutationVariables
>
export const CreateInviteDocument = gql`
  mutation createInvite($input: CreateInviteInput!) {
    createInvite(input: $input) {
      id
      token
    }
  }
`
export type CreateInviteMutationFn = Apollo.MutationFunction<
  CreateInviteMutation,
  CreateInviteMutationVariables
>

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(
    CreateInviteDocument,
    options
  )
}
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<
  CreateInviteMutation,
  CreateInviteMutationVariables
>
export const RevokeInviteDocument = gql`
  mutation revokeInvite($input: RevokeInviteInput!) {
    revokeInvite(input: $input) {
      id
    }
  }
`
export type RevokeInviteMutationFn = Apollo.MutationFunction<
  RevokeInviteMutation,
  RevokeInviteMutationVariables
>

/**
 * __useRevokeInviteMutation__
 *
 * To run a mutation, you first call `useRevokeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInviteMutation, { data, loading, error }] = useRevokeInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<RevokeInviteMutation, RevokeInviteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevokeInviteMutation, RevokeInviteMutationVariables>(
    RevokeInviteDocument,
    options
  )
}
export type RevokeInviteMutationHookResult = ReturnType<typeof useRevokeInviteMutation>
export type RevokeInviteMutationResult = Apollo.MutationResult<RevokeInviteMutation>
export type RevokeInviteMutationOptions = Apollo.BaseMutationOptions<
  RevokeInviteMutation,
  RevokeInviteMutationVariables
>
export const RevokeMembershipDocument = gql`
  mutation revokeMembership($input: RevokeMembershipInput!) {
    revokeMembership(input: $input) {
      id
    }
  }
`
export type RevokeMembershipMutationFn = Apollo.MutationFunction<
  RevokeMembershipMutation,
  RevokeMembershipMutationVariables
>

/**
 * __useRevokeMembershipMutation__
 *
 * To run a mutation, you first call `useRevokeMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeMembershipMutation, { data, loading, error }] = useRevokeMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeMembershipMutation,
    RevokeMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevokeMembershipMutation, RevokeMembershipMutationVariables>(
    RevokeMembershipDocument,
    options
  )
}
export type RevokeMembershipMutationHookResult = ReturnType<typeof useRevokeMembershipMutation>
export type RevokeMembershipMutationResult = Apollo.MutationResult<RevokeMembershipMutation>
export type RevokeMembershipMutationOptions = Apollo.BaseMutationOptions<
  RevokeMembershipMutation,
  RevokeMembershipMutationVariables
>
export const CreateCustomerWalletDocument = gql`
  mutation createCustomerWallet($input: CreateCustomerWalletInput!) {
    createCustomerWallet(input: $input) {
      id
    }
  }
`
export type CreateCustomerWalletMutationFn = Apollo.MutationFunction<
  CreateCustomerWalletMutation,
  CreateCustomerWalletMutationVariables
>

/**
 * __useCreateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWalletMutation, { data, loading, error }] = useCreateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerWalletMutation,
    CreateCustomerWalletMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>(
    CreateCustomerWalletDocument,
    options
  )
}
export type CreateCustomerWalletMutationHookResult = ReturnType<
  typeof useCreateCustomerWalletMutation
>
export type CreateCustomerWalletMutationResult = Apollo.MutationResult<CreateCustomerWalletMutation>
export type CreateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerWalletMutation,
  CreateCustomerWalletMutationVariables
>
export const GetCustomerWalletListDocument = gql`
  query getCustomerWalletList($customerId: ID!, $page: Int, $limit: Int) {
    wallets(customerId: $customerId, page: $page, limit: $limit) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        ...WalletForTopup
        ...WalletForUpdate
        ...WalletAccordion
        ...WalletInfosForTransactions
      }
    }
  }
  ${WalletForTopupFragmentDoc}
  ${WalletForUpdateFragmentDoc}
  ${WalletAccordionFragmentDoc}
  ${WalletInfosForTransactionsFragmentDoc}
`

/**
 * __useGetCustomerWalletListQuery__
 *
 * To run a query within a React component, call `useGetCustomerWalletListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerWalletListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerWalletListQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCustomerWalletListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerWalletListQuery,
    GetCustomerWalletListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>(
    GetCustomerWalletListDocument,
    options
  )
}
export function useGetCustomerWalletListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerWalletListQuery,
    GetCustomerWalletListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>(
    GetCustomerWalletListDocument,
    options
  )
}
export type GetCustomerWalletListQueryHookResult = ReturnType<typeof useGetCustomerWalletListQuery>
export type GetCustomerWalletListLazyQueryHookResult = ReturnType<
  typeof useGetCustomerWalletListLazyQuery
>
export type GetCustomerWalletListQueryResult = Apollo.QueryResult<
  GetCustomerWalletListQuery,
  GetCustomerWalletListQueryVariables
>
export const TerminateCustomerWalletDocument = gql`
  mutation terminateCustomerWallet($input: TerminateCustomerWalletInput!) {
    terminateCustomerWallet(input: $input) {
      id
      status
      ...WalletAccordion
    }
  }
  ${WalletAccordionFragmentDoc}
`
export type TerminateCustomerWalletMutationFn = Apollo.MutationFunction<
  TerminateCustomerWalletMutation,
  TerminateCustomerWalletMutationVariables
>

/**
 * __useTerminateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useTerminateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustomerWalletMutation, { data, loading, error }] = useTerminateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCustomerWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateCustomerWalletMutation,
    TerminateCustomerWalletMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TerminateCustomerWalletMutation,
    TerminateCustomerWalletMutationVariables
  >(TerminateCustomerWalletDocument, options)
}
export type TerminateCustomerWalletMutationHookResult = ReturnType<
  typeof useTerminateCustomerWalletMutation
>
export type TerminateCustomerWalletMutationResult =
  Apollo.MutationResult<TerminateCustomerWalletMutation>
export type TerminateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<
  TerminateCustomerWalletMutation,
  TerminateCustomerWalletMutationVariables
>
export const CreateCustomerWalletTransactionDocument = gql`
  mutation createCustomerWalletTransaction($input: CreateCustomerWalletTransactionInput!) {
    createCustomerWalletTransaction(input: $input) {
      collection {
        id
      }
    }
  }
`
export type CreateCustomerWalletTransactionMutationFn = Apollo.MutationFunction<
  CreateCustomerWalletTransactionMutation,
  CreateCustomerWalletTransactionMutationVariables
>

/**
 * __useCreateCustomerWalletTransactionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWalletTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWalletTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWalletTransactionMutation, { data, loading, error }] = useCreateCustomerWalletTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerWalletTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerWalletTransactionMutation,
    CreateCustomerWalletTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCustomerWalletTransactionMutation,
    CreateCustomerWalletTransactionMutationVariables
  >(CreateCustomerWalletTransactionDocument, options)
}
export type CreateCustomerWalletTransactionMutationHookResult = ReturnType<
  typeof useCreateCustomerWalletTransactionMutation
>
export type CreateCustomerWalletTransactionMutationResult =
  Apollo.MutationResult<CreateCustomerWalletTransactionMutation>
export type CreateCustomerWalletTransactionMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerWalletTransactionMutation,
  CreateCustomerWalletTransactionMutationVariables
>
export const UpdateCustomerWalletDocument = gql`
  mutation updateCustomerWallet($input: UpdateCustomerWalletInput!) {
    updateCustomerWallet(input: $input) {
      ...WalletForUpdate
    }
  }
  ${WalletForUpdateFragmentDoc}
`
export type UpdateCustomerWalletMutationFn = Apollo.MutationFunction<
  UpdateCustomerWalletMutation,
  UpdateCustomerWalletMutationVariables
>

/**
 * __useUpdateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerWalletMutation, { data, loading, error }] = useUpdateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerWalletMutation,
    UpdateCustomerWalletMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCustomerWalletMutation, UpdateCustomerWalletMutationVariables>(
    UpdateCustomerWalletDocument,
    options
  )
}
export type UpdateCustomerWalletMutationHookResult = ReturnType<
  typeof useUpdateCustomerWalletMutation
>
export type UpdateCustomerWalletMutationResult = Apollo.MutationResult<UpdateCustomerWalletMutation>
export type UpdateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerWalletMutation,
  UpdateCustomerWalletMutationVariables
>
export const GetWalletTransactionsDocument = gql`
  query getWalletTransactions($walletId: ID!, $page: Int, $limit: Int) {
    walletTransactions(walletId: $walletId, page: $page, limit: $limit) {
      metadata {
        currentPage
        totalPages
        totalCount
      }
      collection {
        id
        status
        transactionType
        amount
        creditAmount
        settledAt
        createdAt
      }
    }
  }
`

/**
 * __useGetWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useGetWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletTransactionsQuery({
 *   variables: {
 *      walletId: // value for 'walletId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWalletTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletTransactionsQuery,
    GetWalletTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(
    GetWalletTransactionsDocument,
    options
  )
}
export function useGetWalletTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletTransactionsQuery,
    GetWalletTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(
    GetWalletTransactionsDocument,
    options
  )
}
export type GetWalletTransactionsQueryHookResult = ReturnType<typeof useGetWalletTransactionsQuery>
export type GetWalletTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetWalletTransactionsLazyQuery
>
export type GetWalletTransactionsQueryResult = Apollo.QueryResult<
  GetWalletTransactionsQuery,
  GetWalletTransactionsQueryVariables
>
export const GetPlansDocument = gql`
  query getPlans($page: Int, $limit: Int, $searchTerm: String) {
    plans(page: $page, limit: $limit, searchTerm: $searchTerm) {
      collection {
        ...AddSubscriptionPlan
      }
    }
  }
  ${AddSubscriptionPlanFragmentDoc}
`

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options)
}
export function useGetPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options)
}
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>
export const CreateSubscriptionDocument = gql`
  mutation createSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      ...SubscriptionItem
    }
  }
  ${SubscriptionItemFragmentDoc}
`
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(
    CreateSubscriptionDocument,
    options
  )
}
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>
export const GetSinglePlanDocument = gql`
  query getSinglePlan($id: ID!) {
    plan(id: $id) {
      ...EditPlan
    }
  }
  ${EditPlanFragmentDoc}
`

/**
 * __useGetSinglePlanQuery__
 *
 * To run a query within a React component, call `useGetSinglePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSinglePlanQuery(
  baseOptions: Apollo.QueryHookOptions<GetSinglePlanQuery, GetSinglePlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSinglePlanQuery, GetSinglePlanQueryVariables>(
    GetSinglePlanDocument,
    options
  )
}
export function useGetSinglePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePlanQuery, GetSinglePlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSinglePlanQuery, GetSinglePlanQueryVariables>(
    GetSinglePlanDocument,
    options
  )
}
export type GetSinglePlanQueryHookResult = ReturnType<typeof useGetSinglePlanQuery>
export type GetSinglePlanLazyQueryHookResult = ReturnType<typeof useGetSinglePlanLazyQuery>
export type GetSinglePlanQueryResult = Apollo.QueryResult<
  GetSinglePlanQuery,
  GetSinglePlanQueryVariables
>
export const CreatePlanDocument = gql`
  mutation createPlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      id
    }
  }
`
export type CreatePlanMutationFn = Apollo.MutationFunction<
  CreatePlanMutation,
  CreatePlanMutationVariables
>

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(
    CreatePlanDocument,
    options
  )
}
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanMutation,
  CreatePlanMutationVariables
>
export const UpdatePlanDocument = gql`
  mutation updatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      ...PlanItem
      ...DeletePlanDialog
    }
  }
  ${PlanItemFragmentDoc}
  ${DeletePlanDialogFragmentDoc}
`
export type UpdatePlanMutationFn = Apollo.MutationFunction<
  UpdatePlanMutation,
  UpdatePlanMutationVariables
>

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(
    UpdatePlanDocument,
    options
  )
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanMutation,
  UpdatePlanMutationVariables
>
export const GetInvoiceCreateCreditNoteDocument = gql`
  query getInvoiceCreateCreditNote($id: ID!) {
    invoice(id: $id) {
      ...InvoiceCreateCreditNote
    }
  }
  ${InvoiceCreateCreditNoteFragmentDoc}
`

/**
 * __useGetInvoiceCreateCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetInvoiceCreateCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceCreateCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceCreateCreditNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceCreateCreditNoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceCreateCreditNoteQuery,
    GetInvoiceCreateCreditNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInvoiceCreateCreditNoteQuery, GetInvoiceCreateCreditNoteQueryVariables>(
    GetInvoiceCreateCreditNoteDocument,
    options
  )
}
export function useGetInvoiceCreateCreditNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceCreateCreditNoteQuery,
    GetInvoiceCreateCreditNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvoiceCreateCreditNoteQuery,
    GetInvoiceCreateCreditNoteQueryVariables
  >(GetInvoiceCreateCreditNoteDocument, options)
}
export type GetInvoiceCreateCreditNoteQueryHookResult = ReturnType<
  typeof useGetInvoiceCreateCreditNoteQuery
>
export type GetInvoiceCreateCreditNoteLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceCreateCreditNoteLazyQuery
>
export type GetInvoiceCreateCreditNoteQueryResult = Apollo.QueryResult<
  GetInvoiceCreateCreditNoteQuery,
  GetInvoiceCreateCreditNoteQueryVariables
>
export const CreateCreditNoteDocument = gql`
  mutation createCreditNote($input: CreateCreditNoteInput!) {
    createCreditNote(input: $input) {
      id
    }
  }
`
export type CreateCreditNoteMutationFn = Apollo.MutationFunction<
  CreateCreditNoteMutation,
  CreateCreditNoteMutationVariables
>

/**
 * __useCreateCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditNoteMutation, { data, loading, error }] = useCreateCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCreditNoteMutation,
    CreateCreditNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCreditNoteMutation, CreateCreditNoteMutationVariables>(
    CreateCreditNoteDocument,
    options
  )
}
export type CreateCreditNoteMutationHookResult = ReturnType<typeof useCreateCreditNoteMutation>
export type CreateCreditNoteMutationResult = Apollo.MutationResult<CreateCreditNoteMutation>
export type CreateCreditNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateCreditNoteMutation,
  CreateCreditNoteMutationVariables
>
export const GetSingleAddOnDocument = gql`
  query getSingleAddOn($id: ID!) {
    addOn(id: $id) {
      ...EditAddOn
    }
  }
  ${EditAddOnFragmentDoc}
`

/**
 * __useGetSingleAddOnQuery__
 *
 * To run a query within a React component, call `useGetSingleAddOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleAddOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleAddOnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleAddOnQuery(
  baseOptions: Apollo.QueryHookOptions<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>(
    GetSingleAddOnDocument,
    options
  )
}
export function useGetSingleAddOnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>(
    GetSingleAddOnDocument,
    options
  )
}
export type GetSingleAddOnQueryHookResult = ReturnType<typeof useGetSingleAddOnQuery>
export type GetSingleAddOnLazyQueryHookResult = ReturnType<typeof useGetSingleAddOnLazyQuery>
export type GetSingleAddOnQueryResult = Apollo.QueryResult<
  GetSingleAddOnQuery,
  GetSingleAddOnQueryVariables
>
export const CreateAddOnDocument = gql`
  mutation createAddOn($input: CreateAddOnInput!) {
    createAddOn(input: $input) {
      id
    }
  }
`
export type CreateAddOnMutationFn = Apollo.MutationFunction<
  CreateAddOnMutation,
  CreateAddOnMutationVariables
>

/**
 * __useCreateAddOnMutation__
 *
 * To run a mutation, you first call `useCreateAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddOnMutation, { data, loading, error }] = useCreateAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddOnMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAddOnMutation, CreateAddOnMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAddOnMutation, CreateAddOnMutationVariables>(
    CreateAddOnDocument,
    options
  )
}
export type CreateAddOnMutationHookResult = ReturnType<typeof useCreateAddOnMutation>
export type CreateAddOnMutationResult = Apollo.MutationResult<CreateAddOnMutation>
export type CreateAddOnMutationOptions = Apollo.BaseMutationOptions<
  CreateAddOnMutation,
  CreateAddOnMutationVariables
>
export const UpdateAddOnDocument = gql`
  mutation updateAddOn($input: UpdateAddOnInput!) {
    updateAddOn(input: $input) {
      ...AddOnItem
    }
  }
  ${AddOnItemFragmentDoc}
`
export type UpdateAddOnMutationFn = Apollo.MutationFunction<
  UpdateAddOnMutation,
  UpdateAddOnMutationVariables
>

/**
 * __useUpdateAddOnMutation__
 *
 * To run a mutation, you first call `useUpdateAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddOnMutation, { data, loading, error }] = useUpdateAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddOnMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAddOnMutation, UpdateAddOnMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAddOnMutation, UpdateAddOnMutationVariables>(
    UpdateAddOnDocument,
    options
  )
}
export type UpdateAddOnMutationHookResult = ReturnType<typeof useUpdateAddOnMutation>
export type UpdateAddOnMutationResult = Apollo.MutationResult<UpdateAddOnMutation>
export type UpdateAddOnMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddOnMutation,
  UpdateAddOnMutationVariables
>
export const GetSingleBillableMetricDocument = gql`
  query getSingleBillableMetric($id: ID!) {
    billableMetric(id: $id) {
      ...EditBillableMetric
    }
  }
  ${EditBillableMetricFragmentDoc}
`

/**
 * __useGetSingleBillableMetricQuery__
 *
 * To run a query within a React component, call `useGetSingleBillableMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBillableMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBillableMetricQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBillableMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleBillableMetricQuery,
    GetSingleBillableMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>(
    GetSingleBillableMetricDocument,
    options
  )
}
export function useGetSingleBillableMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleBillableMetricQuery,
    GetSingleBillableMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>(
    GetSingleBillableMetricDocument,
    options
  )
}
export type GetSingleBillableMetricQueryHookResult = ReturnType<
  typeof useGetSingleBillableMetricQuery
>
export type GetSingleBillableMetricLazyQueryHookResult = ReturnType<
  typeof useGetSingleBillableMetricLazyQuery
>
export type GetSingleBillableMetricQueryResult = Apollo.QueryResult<
  GetSingleBillableMetricQuery,
  GetSingleBillableMetricQueryVariables
>
export const CreateBillableMetricDocument = gql`
  mutation createBillableMetric($input: CreateBillableMetricInput!) {
    createBillableMetric(input: $input) {
      id
      group
    }
  }
`
export type CreateBillableMetricMutationFn = Apollo.MutationFunction<
  CreateBillableMetricMutation,
  CreateBillableMetricMutationVariables
>

/**
 * __useCreateBillableMetricMutation__
 *
 * To run a mutation, you first call `useCreateBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillableMetricMutation, { data, loading, error }] = useCreateBillableMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBillableMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBillableMetricMutation,
    CreateBillableMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBillableMetricMutation, CreateBillableMetricMutationVariables>(
    CreateBillableMetricDocument,
    options
  )
}
export type CreateBillableMetricMutationHookResult = ReturnType<
  typeof useCreateBillableMetricMutation
>
export type CreateBillableMetricMutationResult = Apollo.MutationResult<CreateBillableMetricMutation>
export type CreateBillableMetricMutationOptions = Apollo.BaseMutationOptions<
  CreateBillableMetricMutation,
  CreateBillableMetricMutationVariables
>
export const UpdateBillableMetricDocument = gql`
  mutation updateBillableMetric($input: UpdateBillableMetricInput!) {
    updateBillableMetric(input: $input) {
      ...BillableMetricItem
      ...DeleteBillableMetricDialog
    }
  }
  ${BillableMetricItemFragmentDoc}
  ${DeleteBillableMetricDialogFragmentDoc}
`
export type UpdateBillableMetricMutationFn = Apollo.MutationFunction<
  UpdateBillableMetricMutation,
  UpdateBillableMetricMutationVariables
>

/**
 * __useUpdateBillableMetricMutation__
 *
 * To run a mutation, you first call `useUpdateBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillableMetricMutation, { data, loading, error }] = useUpdateBillableMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillableMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillableMetricMutation,
    UpdateBillableMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBillableMetricMutation, UpdateBillableMetricMutationVariables>(
    UpdateBillableMetricDocument,
    options
  )
}
export type UpdateBillableMetricMutationHookResult = ReturnType<
  typeof useUpdateBillableMetricMutation
>
export type UpdateBillableMetricMutationResult = Apollo.MutationResult<UpdateBillableMetricMutation>
export type UpdateBillableMetricMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillableMetricMutation,
  UpdateBillableMetricMutationVariables
>
export const GetSingleCouponDocument = gql`
  query getSingleCoupon($id: ID!) {
    coupon(id: $id) {
      ...EditCoupon
    }
  }
  ${EditCouponFragmentDoc}
`

/**
 * __useGetSingleCouponQuery__
 *
 * To run a query within a React component, call `useGetSingleCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleCouponQuery(
  baseOptions: Apollo.QueryHookOptions<GetSingleCouponQuery, GetSingleCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSingleCouponQuery, GetSingleCouponQueryVariables>(
    GetSingleCouponDocument,
    options
  )
}
export function useGetSingleCouponLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleCouponQuery, GetSingleCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSingleCouponQuery, GetSingleCouponQueryVariables>(
    GetSingleCouponDocument,
    options
  )
}
export type GetSingleCouponQueryHookResult = ReturnType<typeof useGetSingleCouponQuery>
export type GetSingleCouponLazyQueryHookResult = ReturnType<typeof useGetSingleCouponLazyQuery>
export type GetSingleCouponQueryResult = Apollo.QueryResult<
  GetSingleCouponQuery,
  GetSingleCouponQueryVariables
>
export const CreateCouponDocument = gql`
  mutation createCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      id
    }
  }
`
export type CreateCouponMutationFn = Apollo.MutationFunction<
  CreateCouponMutation,
  CreateCouponMutationVariables
>

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(
    CreateCouponDocument,
    options
  )
}
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<
  CreateCouponMutation,
  CreateCouponMutationVariables
>
export const UpdateCouponDocument = gql`
  mutation updateCoupon($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      ...CouponItem
    }
  }
  ${CouponItemFragmentDoc}
`
export type UpdateCouponMutationFn = Apollo.MutationFunction<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(
    UpdateCouponDocument,
    options
  )
}
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>
export const CreateCustomerDocument = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ...AddCustomerDrawer
      ...CustomerItem
    }
  }
  ${AddCustomerDrawerFragmentDoc}
  ${CustomerItemFragmentDoc}
`
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(
    CreateCustomerDocument,
    options
  )
}
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ...AddCustomerDrawer
      ...CustomerItem
    }
  }
  ${AddCustomerDrawerFragmentDoc}
  ${CustomerItemFragmentDoc}
`
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options
  )
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>
export const GetCurrentUserInfosDocument = gql`
  query getCurrentUserInfos {
    currentUser {
      ...CurrentUserInfos
    }
  }
  ${CurrentUserInfosFragmentDoc}
`

/**
 * __useGetCurrentUserInfosQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserInfosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserInfosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentUserInfosQuery, GetCurrentUserInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserInfosQuery, GetCurrentUserInfosQueryVariables>(
    GetCurrentUserInfosDocument,
    options
  )
}
export function useGetCurrentUserInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserInfosQuery,
    GetCurrentUserInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserInfosQuery, GetCurrentUserInfosQueryVariables>(
    GetCurrentUserInfosDocument,
    options
  )
}
export type GetCurrentUserInfosQueryHookResult = ReturnType<typeof useGetCurrentUserInfosQuery>
export type GetCurrentUserInfosLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserInfosLazyQuery
>
export type GetCurrentUserInfosQueryResult = Apollo.QueryResult<
  GetCurrentUserInfosQuery,
  GetCurrentUserInfosQueryVariables
>
export const GetOrganizationInfosDocument = gql`
  query getOrganizationInfos {
    organization {
      ...MainOrganizationInfos
    }
  }
  ${MainOrganizationInfosFragmentDoc}
`

/**
 * __useGetOrganizationInfosQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInfosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationInfosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationInfosQuery,
    GetOrganizationInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrganizationInfosQuery, GetOrganizationInfosQueryVariables>(
    GetOrganizationInfosDocument,
    options
  )
}
export function useGetOrganizationInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationInfosQuery,
    GetOrganizationInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrganizationInfosQuery, GetOrganizationInfosQueryVariables>(
    GetOrganizationInfosDocument,
    options
  )
}
export type GetOrganizationInfosQueryHookResult = ReturnType<typeof useGetOrganizationInfosQuery>
export type GetOrganizationInfosLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationInfosLazyQuery
>
export type GetOrganizationInfosQueryResult = Apollo.QueryResult<
  GetOrganizationInfosQuery,
  GetOrganizationInfosQueryVariables
>
export const GetInvoiceDetailsDocument = gql`
  query getInvoiceDetails($id: ID!) {
    invoice(id: $id) {
      id
      ...AllInvoiceDetailsForCustomerInvoiceDetails
    }
  }
  ${AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc}
`

/**
 * __useGetInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>(
    GetInvoiceDetailsDocument,
    options
  )
}
export function useGetInvoiceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>(
    GetInvoiceDetailsDocument,
    options
  )
}
export type GetInvoiceDetailsQueryHookResult = ReturnType<typeof useGetInvoiceDetailsQuery>
export type GetInvoiceDetailsLazyQueryHookResult = ReturnType<typeof useGetInvoiceDetailsLazyQuery>
export type GetInvoiceDetailsQueryResult = Apollo.QueryResult<
  GetInvoiceDetailsQuery,
  GetInvoiceDetailsQueryVariables
>
export const DownloadInvoiceDocument = gql`
  mutation downloadInvoice($input: DownloadInvoiceInput!) {
    downloadInvoice(input: $input) {
      id
      fileUrl
    }
  }
`
export type DownloadInvoiceMutationFn = Apollo.MutationFunction<
  DownloadInvoiceMutation,
  DownloadInvoiceMutationVariables
>

/**
 * __useDownloadInvoiceMutation__
 *
 * To run a mutation, you first call `useDownloadInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoiceMutation, { data, loading, error }] = useDownloadInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadInvoiceMutation,
    DownloadInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DownloadInvoiceMutation, DownloadInvoiceMutationVariables>(
    DownloadInvoiceDocument,
    options
  )
}
export type DownloadInvoiceMutationHookResult = ReturnType<typeof useDownloadInvoiceMutation>
export type DownloadInvoiceMutationResult = Apollo.MutationResult<DownloadInvoiceMutation>
export type DownloadInvoiceMutationOptions = Apollo.BaseMutationOptions<
  DownloadInvoiceMutation,
  DownloadInvoiceMutationVariables
>
export const RefreshInvoiceDocument = gql`
  mutation refreshInvoice($input: RefreshInvoiceInput!) {
    refreshInvoice(input: $input) {
      id
      ...AllInvoiceDetailsForCustomerInvoiceDetails
    }
  }
  ${AllInvoiceDetailsForCustomerInvoiceDetailsFragmentDoc}
`
export type RefreshInvoiceMutationFn = Apollo.MutationFunction<
  RefreshInvoiceMutation,
  RefreshInvoiceMutationVariables
>

/**
 * __useRefreshInvoiceMutation__
 *
 * To run a mutation, you first call `useRefreshInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshInvoiceMutation, { data, loading, error }] = useRefreshInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshInvoiceMutation, RefreshInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefreshInvoiceMutation, RefreshInvoiceMutationVariables>(
    RefreshInvoiceDocument,
    options
  )
}
export type RefreshInvoiceMutationHookResult = ReturnType<typeof useRefreshInvoiceMutation>
export type RefreshInvoiceMutationResult = Apollo.MutationResult<RefreshInvoiceMutation>
export type RefreshInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RefreshInvoiceMutation,
  RefreshInvoiceMutationVariables
>
export const SideNavInfosDocument = gql`
  query SideNavInfos {
    currentVersion {
      githubUrl
      number
    }
  }
`

/**
 * __useSideNavInfosQuery__
 *
 * To run a query within a React component, call `useSideNavInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideNavInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideNavInfosQuery({
 *   variables: {
 *   },
 * });
 */
export function useSideNavInfosQuery(
  baseOptions?: Apollo.QueryHookOptions<SideNavInfosQuery, SideNavInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SideNavInfosQuery, SideNavInfosQueryVariables>(
    SideNavInfosDocument,
    options
  )
}
export function useSideNavInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SideNavInfosQuery, SideNavInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SideNavInfosQuery, SideNavInfosQueryVariables>(
    SideNavInfosDocument,
    options
  )
}
export type SideNavInfosQueryHookResult = ReturnType<typeof useSideNavInfosQuery>
export type SideNavInfosLazyQueryHookResult = ReturnType<typeof useSideNavInfosLazyQuery>
export type SideNavInfosQueryResult = Apollo.QueryResult<
  SideNavInfosQuery,
  SideNavInfosQueryVariables
>
export const AddOnsDocument = gql`
  query addOns($page: Int, $limit: Int, $searchTerm: String) {
    addOns(page: $page, limit: $limit, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        id
        ...AddOnItem
      }
    }
  }
  ${AddOnItemFragmentDoc}
`

/**
 * __useAddOnsQuery__
 *
 * To run a query within a React component, call `useAddOnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOnsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useAddOnsQuery(
  baseOptions?: Apollo.QueryHookOptions<AddOnsQuery, AddOnsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AddOnsQuery, AddOnsQueryVariables>(AddOnsDocument, options)
}
export function useAddOnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddOnsQuery, AddOnsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AddOnsQuery, AddOnsQueryVariables>(AddOnsDocument, options)
}
export type AddOnsQueryHookResult = ReturnType<typeof useAddOnsQuery>
export type AddOnsLazyQueryHookResult = ReturnType<typeof useAddOnsLazyQuery>
export type AddOnsQueryResult = Apollo.QueryResult<AddOnsQuery, AddOnsQueryVariables>
export const BillableMetricsDocument = gql`
  query billableMetrics($page: Int, $limit: Int, $searchTerm: String) {
    billableMetrics(page: $page, limit: $limit, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        ...BillableMetricItem
      }
    }
  }
  ${BillableMetricItemFragmentDoc}
`

/**
 * __useBillableMetricsQuery__
 *
 * To run a query within a React component, call `useBillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useBillableMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(
    BillableMetricsDocument,
    options
  )
}
export function useBillableMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(
    BillableMetricsDocument,
    options
  )
}
export type BillableMetricsQueryHookResult = ReturnType<typeof useBillableMetricsQuery>
export type BillableMetricsLazyQueryHookResult = ReturnType<typeof useBillableMetricsLazyQuery>
export type BillableMetricsQueryResult = Apollo.QueryResult<
  BillableMetricsQuery,
  BillableMetricsQueryVariables
>
export const CouponsDocument = gql`
  query coupons($page: Int, $limit: Int, $searchTerm: String) {
    coupons(page: $page, limit: $limit, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        ...CouponItem
        ...CouponCaption
      }
    }
  }
  ${CouponItemFragmentDoc}
  ${CouponCaptionFragmentDoc}
`

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options)
}
export function useCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options)
}
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>
export const GetCreditNoteDocument = gql`
  query getCreditNote($id: ID!) {
    creditNote(id: $id) {
      id
      balanceAmountCents
      canBeVoided
      createdAt
      creditAmountCents
      creditAmountCurrency
      creditStatus
      number
      refundAmountCents
      refundedAt
      refundStatus
      subTotalVatExcludedAmountCents
      subTotalVatExcludedAmountCurrency
      totalAmountCents
      totalAmountCurrency
      vatAmountCents
      vatAmountCurrency
      customer {
        id
        name
        deletedAt
        applicableTimezone
      }
      invoice {
        id
        number
      }
      items {
        amountCents
        amountCurrency
        fee {
          id
          amountCents
          amountCurrency
          eventsCount
          units
          feeType
          itemName
          charge {
            id
            billableMetric {
              id
              name
              aggregationType
            }
          }
          subscription {
            id
            name
            plan {
              id
              name
            }
          }
          group {
            id
            key
            value
          }
        }
      }
    }
  }
`

/**
 * __useGetCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCreditNoteQuery(
  baseOptions: Apollo.QueryHookOptions<GetCreditNoteQuery, GetCreditNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCreditNoteQuery, GetCreditNoteQueryVariables>(
    GetCreditNoteDocument,
    options
  )
}
export function useGetCreditNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCreditNoteQuery, GetCreditNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCreditNoteQuery, GetCreditNoteQueryVariables>(
    GetCreditNoteDocument,
    options
  )
}
export type GetCreditNoteQueryHookResult = ReturnType<typeof useGetCreditNoteQuery>
export type GetCreditNoteLazyQueryHookResult = ReturnType<typeof useGetCreditNoteLazyQuery>
export type GetCreditNoteQueryResult = Apollo.QueryResult<
  GetCreditNoteQuery,
  GetCreditNoteQueryVariables
>
export const GetCustomerDocument = gql`
  query getCustomer($id: ID!) {
    customer(id: $id) {
      ...CustomerDetails
    }
  }
  ${CustomerDetailsFragmentDoc}
`

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options)
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  )
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>
export const GetCustomerDraftInvoicesDocument = gql`
  query getCustomerDraftInvoices(
    $customerId: ID!
    $limit: Int
    $page: Int
    $status: InvoiceStatusTypeEnum
    $searchTerm: String
  ) {
    customerInvoices(
      customerId: $customerId
      limit: $limit
      page: $page
      status: $status
      searchTerm: $searchTerm
    ) {
      ...InvoiceForInvoiceList
    }
  }
  ${InvoiceForInvoiceListFragmentDoc}
`

/**
 * __useGetCustomerDraftInvoicesQuery__
 *
 * To run a query within a React component, call `useGetCustomerDraftInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDraftInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDraftInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCustomerDraftInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerDraftInvoicesQuery,
    GetCustomerDraftInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>(
    GetCustomerDraftInvoicesDocument,
    options
  )
}
export function useGetCustomerDraftInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerDraftInvoicesQuery,
    GetCustomerDraftInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerDraftInvoicesQuery, GetCustomerDraftInvoicesQueryVariables>(
    GetCustomerDraftInvoicesDocument,
    options
  )
}
export type GetCustomerDraftInvoicesQueryHookResult = ReturnType<
  typeof useGetCustomerDraftInvoicesQuery
>
export type GetCustomerDraftInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerDraftInvoicesLazyQuery
>
export type GetCustomerDraftInvoicesQueryResult = Apollo.QueryResult<
  GetCustomerDraftInvoicesQuery,
  GetCustomerDraftInvoicesQueryVariables
>
export const GetCustomerInfosForDraftInvoicesListDocument = gql`
  query getCustomerInfosForDraftInvoicesList($customerId: ID!, $status: InvoiceStatusTypeEnum) {
    customer(id: $customerId) {
      id
      name
      applicableTimezone
    }
    customerInvoices(customerId: $customerId, status: $status) {
      metadata {
        totalCount
      }
    }
  }
`

/**
 * __useGetCustomerInfosForDraftInvoicesListQuery__
 *
 * To run a query within a React component, call `useGetCustomerInfosForDraftInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInfosForDraftInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInfosForDraftInvoicesListQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCustomerInfosForDraftInvoicesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerInfosForDraftInvoicesListQuery,
    GetCustomerInfosForDraftInvoicesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCustomerInfosForDraftInvoicesListQuery,
    GetCustomerInfosForDraftInvoicesListQueryVariables
  >(GetCustomerInfosForDraftInvoicesListDocument, options)
}
export function useGetCustomerInfosForDraftInvoicesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerInfosForDraftInvoicesListQuery,
    GetCustomerInfosForDraftInvoicesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomerInfosForDraftInvoicesListQuery,
    GetCustomerInfosForDraftInvoicesListQueryVariables
  >(GetCustomerInfosForDraftInvoicesListDocument, options)
}
export type GetCustomerInfosForDraftInvoicesListQueryHookResult = ReturnType<
  typeof useGetCustomerInfosForDraftInvoicesListQuery
>
export type GetCustomerInfosForDraftInvoicesListLazyQueryHookResult = ReturnType<
  typeof useGetCustomerInfosForDraftInvoicesListLazyQuery
>
export type GetCustomerInfosForDraftInvoicesListQueryResult = Apollo.QueryResult<
  GetCustomerInfosForDraftInvoicesListQuery,
  GetCustomerInfosForDraftInvoicesListQueryVariables
>
export const CustomersDocument = gql`
  query customers($page: Int, $limit: Int, $searchTerm: String) {
    customers(page: $page, limit: $limit, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        ...CustomerItem
      }
    }
  }
  ${CustomerItemFragmentDoc}
`

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options)
}
export function useCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options)
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>
export const GetinviteDocument = gql`
  query getinvite($token: String!) {
    invite(token: $token) {
      id
      email
      organization {
        id
        name
      }
    }
  }
`

/**
 * __useGetinviteQuery__
 *
 * To run a query within a React component, call `useGetinviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetinviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetinviteQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetinviteQuery(
  baseOptions: Apollo.QueryHookOptions<GetinviteQuery, GetinviteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetinviteQuery, GetinviteQueryVariables>(GetinviteDocument, options)
}
export function useGetinviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetinviteQuery, GetinviteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetinviteQuery, GetinviteQueryVariables>(GetinviteDocument, options)
}
export type GetinviteQueryHookResult = ReturnType<typeof useGetinviteQuery>
export type GetinviteLazyQueryHookResult = ReturnType<typeof useGetinviteLazyQuery>
export type GetinviteQueryResult = Apollo.QueryResult<GetinviteQuery, GetinviteQueryVariables>
export const AcceptInviteDocument = gql`
  mutation acceptInvite($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      token
      user {
        id
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`
export type AcceptInviteMutationFn = Apollo.MutationFunction<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(
    AcceptInviteDocument,
    options
  )
}
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>
export const GetInvoiceCreditNotesDocument = gql`
  query getInvoiceCreditNotes($invoiceId: ID!, $page: Int, $limit: Int) {
    invoiceCreditNotes(invoiceId: $invoiceId, page: $page, limit: $limit) {
      ...CreditNotesForList
    }
    invoice(id: $invoiceId) {
      id
      refundableAmountCents
      creditableAmountCents
      status
      customer {
        id
        applicableTimezone
      }
    }
  }
  ${CreditNotesForListFragmentDoc}
`

/**
 * __useGetInvoiceCreditNotesQuery__
 *
 * To run a query within a React component, call `useGetInvoiceCreditNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceCreditNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceCreditNotesQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetInvoiceCreditNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceCreditNotesQuery,
    GetInvoiceCreditNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInvoiceCreditNotesQuery, GetInvoiceCreditNotesQueryVariables>(
    GetInvoiceCreditNotesDocument,
    options
  )
}
export function useGetInvoiceCreditNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceCreditNotesQuery,
    GetInvoiceCreditNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInvoiceCreditNotesQuery, GetInvoiceCreditNotesQueryVariables>(
    GetInvoiceCreditNotesDocument,
    options
  )
}
export type GetInvoiceCreditNotesQueryHookResult = ReturnType<typeof useGetInvoiceCreditNotesQuery>
export type GetInvoiceCreditNotesLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceCreditNotesLazyQuery
>
export type GetInvoiceCreditNotesQueryResult = Apollo.QueryResult<
  GetInvoiceCreditNotesQuery,
  GetInvoiceCreditNotesQueryVariables
>
export const InvoicesListDocument = gql`
  query invoicesList(
    $limit: Int
    $page: Int
    $status: InvoiceStatusTypeEnum
    $paymentStatus: [InvoicePaymentStatusTypeEnum!]
    $searchTerm: String
  ) {
    invoices(
      limit: $limit
      page: $page
      status: $status
      paymentStatus: $paymentStatus
      searchTerm: $searchTerm
    ) {
      metadata {
        currentPage
        totalPages
        totalCount
      }
      collection {
        id
        ...InvoiceListItem
      }
    }
  }
  ${InvoiceListItemFragmentDoc}
`

/**
 * __useInvoicesListQuery__
 *
 * To run a query within a React component, call `useInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      paymentStatus: // value for 'paymentStatus'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useInvoicesListQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoicesListQuery, InvoicesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvoicesListQuery, InvoicesListQueryVariables>(
    InvoicesListDocument,
    options
  )
}
export function useInvoicesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoicesListQuery, InvoicesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvoicesListQuery, InvoicesListQueryVariables>(
    InvoicesListDocument,
    options
  )
}
export type InvoicesListQueryHookResult = ReturnType<typeof useInvoicesListQuery>
export type InvoicesListLazyQueryHookResult = ReturnType<typeof useInvoicesListLazyQuery>
export type InvoicesListQueryResult = Apollo.QueryResult<
  InvoicesListQuery,
  InvoicesListQueryVariables
>
export const RetryAllInvoicePaymentsDocument = gql`
  mutation retryAllInvoicePayments($input: RetryAllInvoicePaymentsInput!) {
    retryAllInvoicePayments(input: $input) {
      collection {
        id
      }
    }
  }
`
export type RetryAllInvoicePaymentsMutationFn = Apollo.MutationFunction<
  RetryAllInvoicePaymentsMutation,
  RetryAllInvoicePaymentsMutationVariables
>

/**
 * __useRetryAllInvoicePaymentsMutation__
 *
 * To run a mutation, you first call `useRetryAllInvoicePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllInvoicePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllInvoicePaymentsMutation, { data, loading, error }] = useRetryAllInvoicePaymentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllInvoicePaymentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetryAllInvoicePaymentsMutation,
    RetryAllInvoicePaymentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RetryAllInvoicePaymentsMutation,
    RetryAllInvoicePaymentsMutationVariables
  >(RetryAllInvoicePaymentsDocument, options)
}
export type RetryAllInvoicePaymentsMutationHookResult = ReturnType<
  typeof useRetryAllInvoicePaymentsMutation
>
export type RetryAllInvoicePaymentsMutationResult =
  Apollo.MutationResult<RetryAllInvoicePaymentsMutation>
export type RetryAllInvoicePaymentsMutationOptions = Apollo.BaseMutationOptions<
  RetryAllInvoicePaymentsMutation,
  RetryAllInvoicePaymentsMutationVariables
>
export const PlansDocument = gql`
  query plans($page: Int, $limit: Int, $searchTerm: String) {
    plans(page: $page, limit: $limit, searchTerm: $searchTerm) {
      metadata {
        currentPage
        totalPages
      }
      collection {
        ...PlanItem
      }
    }
  }
  ${PlanItemFragmentDoc}
`

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function usePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options)
}
export function usePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options)
}
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>
export const LoginUserDocument = gql`
  mutation loginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      user {
        id
        ...CurrentUser
      }
      token
    }
  }
`
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options
  )
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>
export const SignupDocument = gql`
  mutation signup($input: RegisterUserInput!) {
    registerUser(input: $input) {
      token
      user {
        id
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options)
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>
export const GetOrganizationApiKeyDocument = gql`
  query getOrganizationApiKey {
    organization {
      id
      apiKey
    }
  }
`

/**
 * __useGetOrganizationApiKeyQuery__
 *
 * To run a query within a React component, call `useGetOrganizationApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationApiKeyQuery,
    GetOrganizationApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrganizationApiKeyQuery, GetOrganizationApiKeyQueryVariables>(
    GetOrganizationApiKeyDocument,
    options
  )
}
export function useGetOrganizationApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationApiKeyQuery,
    GetOrganizationApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrganizationApiKeyQuery, GetOrganizationApiKeyQueryVariables>(
    GetOrganizationApiKeyDocument,
    options
  )
}
export type GetOrganizationApiKeyQueryHookResult = ReturnType<typeof useGetOrganizationApiKeyQuery>
export type GetOrganizationApiKeyLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationApiKeyLazyQuery
>
export type GetOrganizationApiKeyQueryResult = Apollo.QueryResult<
  GetOrganizationApiKeyQuery,
  GetOrganizationApiKeyQueryVariables
>
export const EventsDocument = gql`
  query events($page: Int, $limit: Int) {
    events(page: $page, limit: $limit) {
      collection {
        ...EventList
      }
      metadata {
        currentPage
        totalPages
      }
    }
  }
  ${EventListFragmentDoc}
`

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options)
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options)
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>
export const GetWehbookSettingDocument = gql`
  query getWehbookSetting {
    organization {
      id
      webhookUrl
    }
  }
`

/**
 * __useGetWehbookSettingQuery__
 *
 * To run a query within a React component, call `useGetWehbookSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWehbookSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWehbookSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWehbookSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWehbookSettingQuery, GetWehbookSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWehbookSettingQuery, GetWehbookSettingQueryVariables>(
    GetWehbookSettingDocument,
    options
  )
}
export function useGetWehbookSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWehbookSettingQuery, GetWehbookSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWehbookSettingQuery, GetWehbookSettingQueryVariables>(
    GetWehbookSettingDocument,
    options
  )
}
export type GetWehbookSettingQueryHookResult = ReturnType<typeof useGetWehbookSettingQuery>
export type GetWehbookSettingLazyQueryHookResult = ReturnType<typeof useGetWehbookSettingLazyQuery>
export type GetWehbookSettingQueryResult = Apollo.QueryResult<
  GetWehbookSettingQuery,
  GetWehbookSettingQueryVariables
>
export const GocardlessIntegrationsSettingDocument = gql`
  query gocardlessIntegrationsSetting {
    organization {
      id
      gocardlessPaymentProvider {
        id
        hasAccessToken
        webhookSecret
      }
    }
  }
`

/**
 * __useGocardlessIntegrationsSettingQuery__
 *
 * To run a query within a React component, call `useGocardlessIntegrationsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGocardlessIntegrationsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGocardlessIntegrationsSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGocardlessIntegrationsSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GocardlessIntegrationsSettingQuery,
    GocardlessIntegrationsSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GocardlessIntegrationsSettingQuery,
    GocardlessIntegrationsSettingQueryVariables
  >(GocardlessIntegrationsSettingDocument, options)
}
export function useGocardlessIntegrationsSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GocardlessIntegrationsSettingQuery,
    GocardlessIntegrationsSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GocardlessIntegrationsSettingQuery,
    GocardlessIntegrationsSettingQueryVariables
  >(GocardlessIntegrationsSettingDocument, options)
}
export type GocardlessIntegrationsSettingQueryHookResult = ReturnType<
  typeof useGocardlessIntegrationsSettingQuery
>
export type GocardlessIntegrationsSettingLazyQueryHookResult = ReturnType<
  typeof useGocardlessIntegrationsSettingLazyQuery
>
export type GocardlessIntegrationsSettingQueryResult = Apollo.QueryResult<
  GocardlessIntegrationsSettingQuery,
  GocardlessIntegrationsSettingQueryVariables
>
export const AddGocardlessPaymentProviderDocument = gql`
  mutation addGocardlessPaymentProvider($input: AddGocardlessPaymentProviderInput!) {
    addGocardlessPaymentProvider(input: $input) {
      id
      hasAccessToken
      webhookSecret
    }
  }
`
export type AddGocardlessPaymentProviderMutationFn = Apollo.MutationFunction<
  AddGocardlessPaymentProviderMutation,
  AddGocardlessPaymentProviderMutationVariables
>

/**
 * __useAddGocardlessPaymentProviderMutation__
 *
 * To run a mutation, you first call `useAddGocardlessPaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGocardlessPaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGocardlessPaymentProviderMutation, { data, loading, error }] = useAddGocardlessPaymentProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGocardlessPaymentProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGocardlessPaymentProviderMutation,
    AddGocardlessPaymentProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddGocardlessPaymentProviderMutation,
    AddGocardlessPaymentProviderMutationVariables
  >(AddGocardlessPaymentProviderDocument, options)
}
export type AddGocardlessPaymentProviderMutationHookResult = ReturnType<
  typeof useAddGocardlessPaymentProviderMutation
>
export type AddGocardlessPaymentProviderMutationResult =
  Apollo.MutationResult<AddGocardlessPaymentProviderMutation>
export type AddGocardlessPaymentProviderMutationOptions = Apollo.BaseMutationOptions<
  AddGocardlessPaymentProviderMutation,
  AddGocardlessPaymentProviderMutationVariables
>
export const IntegrationsSettingDocument = gql`
  query integrationsSetting {
    organization {
      id
      stripePaymentProvider {
        id
      }
      gocardlessPaymentProvider {
        id
      }
    }
  }
`

/**
 * __useIntegrationsSettingQuery__
 *
 * To run a query within a React component, call `useIntegrationsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>(
    IntegrationsSettingDocument,
    options
  )
}
export function useIntegrationsSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationsSettingQuery,
    IntegrationsSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>(
    IntegrationsSettingDocument,
    options
  )
}
export type IntegrationsSettingQueryHookResult = ReturnType<typeof useIntegrationsSettingQuery>
export type IntegrationsSettingLazyQueryHookResult = ReturnType<
  typeof useIntegrationsSettingLazyQuery
>
export type IntegrationsSettingQueryResult = Apollo.QueryResult<
  IntegrationsSettingQuery,
  IntegrationsSettingQueryVariables
>
export const GetOrganizationSettingsDocument = gql`
  query getOrganizationSettings {
    organization {
      id
      billingConfiguration {
        id
        vatRate
        invoiceGracePeriod
        invoiceFooter
      }
      ...EditOrganizationInvoiceTemplateDialog
    }
  }
  ${EditOrganizationInvoiceTemplateDialogFragmentDoc}
`

/**
 * __useGetOrganizationSettingsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationSettingsQuery,
    GetOrganizationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>(
    GetOrganizationSettingsDocument,
    options
  )
}
export function useGetOrganizationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationSettingsQuery,
    GetOrganizationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrganizationSettingsQuery, GetOrganizationSettingsQueryVariables>(
    GetOrganizationSettingsDocument,
    options
  )
}
export type GetOrganizationSettingsQueryHookResult = ReturnType<
  typeof useGetOrganizationSettingsQuery
>
export type GetOrganizationSettingsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationSettingsLazyQuery
>
export type GetOrganizationSettingsQueryResult = Apollo.QueryResult<
  GetOrganizationSettingsQuery,
  GetOrganizationSettingsQueryVariables
>
export const GetInvitesDocument = gql`
  query getInvites($page: Int, $limit: Int) {
    invites(page: $page, limit: $limit) {
      metadata {
        currentPage
        totalPages
        totalCount
      }
      collection {
        ...InviteItem
      }
    }
  }
  ${InviteItemFragmentDoc}
`

/**
 * __useGetInvitesQuery__
 *
 * To run a query within a React component, call `useGetInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvitesQuery, GetInvitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInvitesQuery, GetInvitesQueryVariables>(GetInvitesDocument, options)
}
export function useGetInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvitesQuery, GetInvitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInvitesQuery, GetInvitesQueryVariables>(GetInvitesDocument, options)
}
export type GetInvitesQueryHookResult = ReturnType<typeof useGetInvitesQuery>
export type GetInvitesLazyQueryHookResult = ReturnType<typeof useGetInvitesLazyQuery>
export type GetInvitesQueryResult = Apollo.QueryResult<GetInvitesQuery, GetInvitesQueryVariables>
export const GetMembersDocument = gql`
  query getMembers($page: Int, $limit: Int) {
    memberships(page: $page, limit: $limit) {
      metadata {
        currentPage
        totalPages
        totalCount
      }
      collection {
        ...MembershipItem
      }
    }
  }
  ${MembershipItemFragmentDoc}
`

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options)
}
export function useGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options)
}
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>
export const GetOrganizationInformationsDocument = gql`
  query getOrganizationInformations {
    organization {
      id
      ...OrganizationInformations
      ...EditOrganizationInformationsDialog
    }
  }
  ${OrganizationInformationsFragmentDoc}
  ${EditOrganizationInformationsDialogFragmentDoc}
`

/**
 * __useGetOrganizationInformationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInformationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationInformationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationInformationsQuery,
    GetOrganizationInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOrganizationInformationsQuery,
    GetOrganizationInformationsQueryVariables
  >(GetOrganizationInformationsDocument, options)
}
export function useGetOrganizationInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationInformationsQuery,
    GetOrganizationInformationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOrganizationInformationsQuery,
    GetOrganizationInformationsQueryVariables
  >(GetOrganizationInformationsDocument, options)
}
export type GetOrganizationInformationsQueryHookResult = ReturnType<
  typeof useGetOrganizationInformationsQuery
>
export type GetOrganizationInformationsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationInformationsLazyQuery
>
export type GetOrganizationInformationsQueryResult = Apollo.QueryResult<
  GetOrganizationInformationsQuery,
  GetOrganizationInformationsQueryVariables
>
export const StripeIntegrationsSettingDocument = gql`
  query stripeIntegrationsSetting {
    organization {
      id
      stripePaymentProvider {
        ...StripeIntegration
      }
    }
  }
  ${StripeIntegrationFragmentDoc}
`

/**
 * __useStripeIntegrationsSettingQuery__
 *
 * To run a query within a React component, call `useStripeIntegrationsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeIntegrationsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeIntegrationsSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeIntegrationsSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StripeIntegrationsSettingQuery,
    StripeIntegrationsSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StripeIntegrationsSettingQuery, StripeIntegrationsSettingQueryVariables>(
    StripeIntegrationsSettingDocument,
    options
  )
}
export function useStripeIntegrationsSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StripeIntegrationsSettingQuery,
    StripeIntegrationsSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StripeIntegrationsSettingQuery,
    StripeIntegrationsSettingQueryVariables
  >(StripeIntegrationsSettingDocument, options)
}
export type StripeIntegrationsSettingQueryHookResult = ReturnType<
  typeof useStripeIntegrationsSettingQuery
>
export type StripeIntegrationsSettingLazyQueryHookResult = ReturnType<
  typeof useStripeIntegrationsSettingLazyQuery
>
export type StripeIntegrationsSettingQueryResult = Apollo.QueryResult<
  StripeIntegrationsSettingQuery,
  StripeIntegrationsSettingQueryVariables
>
export const UpdateStripeIntegrationDocument = gql`
  mutation updateStripeIntegration($input: AddStripePaymentProviderInput!) {
    addStripePaymentProvider(input: $input) {
      ...StripeIntegration
    }
  }
  ${StripeIntegrationFragmentDoc}
`
export type UpdateStripeIntegrationMutationFn = Apollo.MutationFunction<
  UpdateStripeIntegrationMutation,
  UpdateStripeIntegrationMutationVariables
>

/**
 * __useUpdateStripeIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateStripeIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeIntegrationMutation, { data, loading, error }] = useUpdateStripeIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStripeIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStripeIntegrationMutation,
    UpdateStripeIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateStripeIntegrationMutation,
    UpdateStripeIntegrationMutationVariables
  >(UpdateStripeIntegrationDocument, options)
}
export type UpdateStripeIntegrationMutationHookResult = ReturnType<
  typeof useUpdateStripeIntegrationMutation
>
export type UpdateStripeIntegrationMutationResult =
  Apollo.MutationResult<UpdateStripeIntegrationMutation>
export type UpdateStripeIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateStripeIntegrationMutation,
  UpdateStripeIntegrationMutationVariables
>
