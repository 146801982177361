import { lazy } from 'react'

import { CustomRouteObject } from './types'

// ----------- Layouts -----------
const Settings = lazy(() => import(/* webpackChunkName: 'settings' */ '~/layouts/Settings'))

// ----------- Pages -----------
// const OrganizationInformations = lazy(
//   () =>
//     import(
//       /* webpackChunkName: 'organization-informations' */ '~/pages/settings/OrganizationInformations'
//     )
// )
// const InvoiceSettings = lazy(
//   () => import(/* webpackChunkName: 'invoice-settings' */ '~/pages/settings/InvoiceSettings')
// )
const Members = lazy(() => import(/* webpackChunkName: 'members' */ '~/pages/settings/Members'))
const Integrations = lazy(
  () => import(/* webpackChunkName: 'integrations' */ '~/pages/settings/Integrations')
)
const StripeIntegration = lazy(
  () => import(/* webpackChunkName: 'stripe-integration' */ '~/pages/settings/StripeIntegration')
)
const GocardlessIntegration = lazy(
  () =>
    import(
      /* webpackChunkName: 'gocardless-integration' */ '~/pages/settings/GocardlessIntegration'
    )
)
const MemberDetails = lazy(
  () => import(/* webpackChunkName: 'member-details' */ '~/pages/settings/MemberDetails')
)

// ----------- Routes -----------
export const SETTINGS_ROUTE = '/settings'
export const INVOICE_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/invoice`
export const VAT_RATE_ROUTE = `${SETTINGS_ROUTE}/tax-rate` // TODO - to maintain old route for now
// export const ORGANIZATION_INFORMATIONS_ROUTE = `${SETTINGS_ROUTE}/organization-informations`
export const INTEGRATIONS_ROUTE = `${SETTINGS_ROUTE}/integrations`
export const STRIPE_INTEGRATION_ROUTE = `${SETTINGS_ROUTE}/integrations/stripe`
export const GOCARDLESS_INTEGRATION_ROUTE = `${SETTINGS_ROUTE}/integrations/gocardless`
export const MEMBERS_ROUTE = `${SETTINGS_ROUTE}/members`
export const MEMBER_DETAILS_ROUTE = `${SETTINGS_ROUTE}/member/:userId`

export const settingRoutes: CustomRouteObject[] = [
  {
    private: true,
    element: <Settings />,
    children: [
      // {
      //   path: [ ORGANIZATION_INFORMATIONS_ROUTE],
      //   private: true,
      //   element: <OrganizationInformations />,
      // },
      // {
      //   path: [INVOICE_SETTINGS_ROUTE, VAT_RATE_ROUTE],
      //   private: true,
      //   element: <InvoiceSettings />,
      //   guards: [['read', 'Invoice & tax']],
      // },
      {
        path: [SETTINGS_ROUTE, INTEGRATIONS_ROUTE],
        private: true,
        element: <Integrations />,
        guards: [['read', 'Integrations']],
      },
      {
        path: [SETTINGS_ROUTE, MEMBERS_ROUTE],
        private: true,
        element: <Members />,
        guards: [['read', 'Member']],
      },
    ],
  },
  {
    path: [MEMBER_DETAILS_ROUTE],
    private: true,
    element: <MemberDetails />,
    guards: [['read', 'Member']],
  },
  {
    path: STRIPE_INTEGRATION_ROUTE,
    private: true,
    element: <StripeIntegration />,
    guards: [['read', 'Stripe']],
  },
  {
    path: GOCARDLESS_INTEGRATION_ROUTE,
    private: true,
    element: <GocardlessIntegration />,
    guards: [['read', 'GoCardless']],
  },
]
