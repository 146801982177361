import { useReactiveVar } from '@apollo/client'

import {
  AUTH_REFRESH_TOKEN_LS_KEY,
  AUTH_TOKEN_LS_KEY,
  authRefreshTokenVar,
  authTokenVar,
  getItemFromLS,
  getItemFromSS,
} from '~/core/apolloClient'

type useIsAuthenticatedReturn = () => {
  isAuthenticated: boolean
  token?: string
  refreshToken?: string
}

export const useIsAuthenticated: useIsAuthenticatedReturn = () => {
  const token = getItemFromLS(AUTH_REFRESH_TOKEN_LS_KEY)
  const refreshToken = getItemFromSS(AUTH_TOKEN_LS_KEY)

  return {
    isAuthenticated: !!token || !!refreshToken,
    token,
    refreshToken,
  }
}
