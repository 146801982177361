import { ApolloClient, NormalizedCacheObject, ApolloLink, gql, fromPromise } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { createUploadLink } from 'apollo-upload-client'
import { LagoApiError } from '~/generated/graphql'
import { cache } from './cache'
import {
  AUTH_TOKEN_LS_KEY,
  addToast,
  envGlobalVar,
  AUTH_REFRESH_TOKEN_LS_KEY,
} from './reactiveVars'
import {
  logOut,
  getItemFromLS,
  setItemFromLS,
  omitDeep,
  getItemFromSS,
  setItemFromSS,
} from './cacheUtils'
import { LagoGQLError } from './errorUtils'
import { typeDefs, resolvers } from './graphqlResolvers'
import _ from 'lodash'
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist'
import localForage from 'localforage'
import { useTranslation } from 'react-i18next'

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: JWT!) {
    refreshToken(token: $token) {
      accessToken
      user {
        email
        firstName
        lastName
      }
    }
  }
`
window.addEventListener('storage', () => {
  logOut(globalApolloClient)
})

export let globalApolloClient: ApolloClient<NormalizedCacheObject>

const TIMEOUT = 60000 // 1 minutes timeout
const timeoutLink = new ApolloLinkTimeout(TIMEOUT)
const { apiUrl, appVersion } = envGlobalVar()

export const getNewToken = () => {
  return globalApolloClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      token: getItemFromLS(AUTH_REFRESH_TOKEN_LS_KEY),
    },
  })
}

export const initializeApolloClient = async () => {
  if (globalApolloClient) return globalApolloClient

  const initialLink = new ApolloLink((operation, forward) => {
    const { headers } = operation.getContext()
    let token = getItemFromLS(AUTH_REFRESH_TOKEN_LS_KEY) || getItemFromSS(AUTH_TOKEN_LS_KEY)

    if (operation.variables && !operation.variables.file) {
      // eslint-disable-next-line
      operation.variables = omitDeep(operation.variables, '__typename')
    }
    operation.setContext({
      headers: {
        ...headers,
        ...(!token ? {} : { authorization: `Bearer ${token}` }),
      },
    })

    return forward(operation)
  })
  const links = [
    initialLink.concat(timeoutLink),
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      const { silentError = false, silentErrorCodes = [] } = operation.getContext()

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, locations, path, extensions } = graphQLErrors[0]

        !silentError &&
          !silentErrorCodes.includes(extensions?.code) &&
          message !== LagoApiError.Unauthorized &&
          message !== LagoApiError.TokenExpired &&
          message !== LagoApiError.InviteNotFound &&
          message !== LagoApiError.ExistingCode &&
          message !== LagoApiError.DoNotDeletePlan &&
          message !== LagoApiError.InvoiceStatusUpdateFailed &&
          addToast({
            severity: 'danger',
            translateKey: message,
          })
        // eslint-disable-next-line no-console
        console.warn(
          `[GraphQL error]: Message: ${message}, Path: ${path}, Location: ${JSON.stringify(
            locations
          )}`
        )

        for (let err of graphQLErrors) {
          const expiredError = _.get(err, 'extensions.response.error')
          const statusError = _.get(err, 'extensions.response.statusCode')

          switch (expiredError === LagoApiError.ExpiredJwtToken) {
            case true:
              return fromPromise(
                getNewToken()
                  .then((accessToken) => {
                    // console.log('accessToken', accessToken)
                    accessToken.errors?.map(() => {
                      if (statusError === 401) {
                        logOut(globalApolloClient)
                      }
                    })
                    return accessToken
                  })
                  .catch((error) => {
                    return error
                  })
              ).flatMap((accessToken) => {
                const { headers } = operation.getContext()
                const tokenRefresh = _.get(accessToken, 'data.refreshToken.accessToken')

                operation.setContext({
                  headers: {
                    ...headers,
                    ...(!tokenRefresh
                      ? {}
                      : {
                          authorization: `Bearer ${tokenRefresh}`,
                        }),
                  },
                })
                setItemFromSS(AUTH_TOKEN_LS_KEY, tokenRefresh)
                return forward(operation)
              })
            default:
              break
          }
        }
      }
      if (!graphQLErrors && networkError) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { t } = useTranslation()

        addToast({
          severity: 'danger',
          translateKey: `${t('body.notification_error')}`,
        })
        // eslint-disable-next-line no-console
        console.warn(`[Network error]: ${JSON.stringify(networkError)}`)
      }
    }),

    // afterwareLink.concat(
    createUploadLink({
      uri: `${apiUrl}/graphql`,
    }) as unknown as ApolloLink,
    // ),
  ]

  await persistCache({
    cache,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    storage: new LocalForageWrapper(localForage),
    key: `apollo-cache-persist-lago-${appVersion}`,
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from(links),
    name: 'sale-app',
    version: appVersion,
    typeDefs,
    resolvers,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })

  globalApolloClient = client

  return client
}
