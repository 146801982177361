import { lazy } from 'react'

import { CustomRouteObject } from './types'

// ----------- Pages -----------
// Lists
const PlansList = lazy(() => import(/* webpackChunkName: 'plans-list' */ '~/pages/PlansList'))
const CouponsList = lazy(() => import(/* webpackChunkName: 'coupons-list' */ '~/pages/CouponsList'))
const AddOnsList = lazy(() => import(/* webpackChunkName: 'add-ons-list' */ '~/pages/AddOnsList'))
const InvoicesList = lazy(
  () => import(/* webpackChunkName: 'invoices-list' */ '~/pages/InvoicesList')
)

// Creation
const CreatePlan = lazy(() => import(/* webpackChunkName: 'create-plan' */ '~/pages/CreatePlan'))
const CreateCoupon = lazy(
  () => import(/* webpackChunkName: 'create-coupon' */ '~/pages/CreateCoupon')
)
const CreateAddOn = lazy(
  () => import(/* webpackChunkName: 'create-coupon' */ '~/pages/CreateAddOn')
)

// ----------- Routes -----------
// Lists
// export const BILLABLE_METRICS_ROUTE = '/billable-metrics'
export const PLANS_ROUTE = '/plans'
export const COUPONS_ROUTE = '/coupons'
export const ADD_ONS_ROUTE = '/add-ons'
export const INVOICES_ROUTE = '/invoices'
export const INVOICES_TAB_ROUTE = '/invoices/:tab'

// Creation
export const CREATE_BILLABLE_METRIC_ROUTE = '/create/billable-metrics'
export const UPDATE_BILLABLE_METRIC_ROUTE = '/update/billable-metric/:id'

export const CREATE_PLAN_ROUTE = '/create/plans'
export const UPDATE_PLAN_ROUTE = '/update/plan/:id'

export const CREATE_COUPON_ROUTE = '/create/coupons'
export const UPDATE_COUPON_ROUTE = '/update/coupons/:id'

export const CREATE_ADD_ON_ROUTE = '/create/add-on'
export const UPDATE_ADD_ON_ROUTE = '/update/add-on/:id'

export const objectListRoutes: CustomRouteObject[] = [
  {
    path: [PLANS_ROUTE],
    private: true,
    element: <PlansList />,
    guards: [['read', 'Plans']],
  },
  {
    path: ADD_ONS_ROUTE,
    private: true,
    element: <AddOnsList />,
    guards: [['read', 'Add-Ons']],
  },
  {
    path: [INVOICES_ROUTE, INVOICES_TAB_ROUTE],
    private: true,
    element: <InvoicesList />,
    guards: [['read', 'Invoices']],
  },
]

export const objectCreationRoutes: CustomRouteObject[] = [
  {
    path: [CREATE_ADD_ON_ROUTE, UPDATE_ADD_ON_ROUTE],
    private: true,
    element: <CreateAddOn />,
    guards: [CREATE_ADD_ON_ROUTE ? ['read', 'Add an add-on'] : ['read', 'Edit an add-on']],
  },
  // {
  //   path: [CREATE_COUPON_ROUTE, UPDATE_COUPON_ROUTE],
  //   private: true,
  //   element: <CreateCoupon />,
  //   guards: [
  //     ['read','Plans'],
  //   ]
  // },
  {
    path: [CREATE_PLAN_ROUTE, UPDATE_PLAN_ROUTE],
    private: true,
    element: <CreatePlan />,
    guards: [CREATE_PLAN_ROUTE ? ['read', 'Add an plan'] : ['read', 'Edit an plan']],
  },
]
