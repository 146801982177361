import { lazy } from 'react'
import { CustomRouteObject } from './types'

// ----------- Pages -----------
const Dashboard = lazy(() => import(/* webpackChunkName: 'dashboard' */ '~/pages/Dashboard'))

// ----------- Routes -----------
export const HOME_ROUTE = '/'

export const dashboardRoutes: CustomRouteObject[] = [
  {
    path: [HOME_ROUTE],
    private: true,
    element: <Dashboard />,
    guards: [['read', 'Dashboard']],
  },
]
